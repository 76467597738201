/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react';
import {
  PapSelectType,
  HPVSelectType,
  PickDropSelectType,
  CurPage,
  SmearSelectType,
  GenderType,
  HiddenDayType,
} from '@consts/enums';
// import earth from '@images/earth.svg';
import { Steps, Select, message } from 'antd';

import './CollectionBootle.css';
import { dateStr2Number, handleLocalStorage } from '@utils/index';
import website from '@configs/website.json';
// import zhTW from 'antd/es/date-picker/locale/zh_TW';
import 'dayjs/locale/zh-tw';
import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

// import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from './components/FiledInput';

// import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from './components/FiledInput';

import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from './components/FiledInput';

import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from './components/CnFiledInput';
// , CnFiledTitle

import MessageText, { MessageTextStatus } from './components/MessageText';

import CollectionBootleButton, { ButtonStatus } from './components/CollectionBootleButton';
import CollectionDatePicker from './components/CollectionDatePicker';
import BaseTextArea from './components/BaseTextArea';
import DeliveryAlert from './components/DeliveryAlert';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import {
  // hasValueMultiple,
  // changeMultipleArr,
  // removeNotDefaultValue,
  // removeHpvNotDefaultValue,
  formatCollectionBootleDate,
  getAnswerArr,
  isLastName,
  isEmail,
  isValidHKID,
  isValidCNID,
  switchLanguage,
  getSpecifyTimeWeekDay,
  isPublicHoliday,
} from '@utils/index';

// import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import classNames from 'classnames';
import ResultAlert from '@utils/globalComponents/ResultAlert';
import { useMemoizedFn } from 'ahooks';

// import feeTick from '@images/feeTick.svg';
import modalError from '@images/modalError.svg';
import modalTick from '@images/modalTick.svg';
import blueSigns from '@images/blueSigns.svg';
import { post, get } from '@utils/request';
import { useLocalNavigate } from 'src/router/routerTools';
import { getAllValue } from '@utils/excel';
import greenEarth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
import { getDefaultCollectionBootleDate, isLowVersionChangeDatePicker } from '@utils/index';
import { COMMIT_TIME_POSITION, DISAGREE_FIRST_POSITION } from '@consts/consts';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { useSearchParams } from 'react-router-dom';
import enLocales from '@locales/en.json';
import TextInput, { TextInputStatus } from '../ExportDoctorExcel/components/TextInput';
// TODO: 后续可能需要移除
import useVConsole from 'src/hooks/tools/useVConsole';
import Payment from './components/Payment/Payment';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import { newVersion } from '@utils/domains';
import { renderDropOffList } from './renderFuncs/renderDropOffList';
import i18n from '@utils/i18n';
import HpvStudyConfirm from './components/HpvStudyConfirm';
import RenderVaccinastionSelect from './renderFuncs/renderVaccinastionSelect';
import RenderUpgrade from './renderFuncs/renderUpgrade';
import { getSampleExpireDate } from '@utils/sampleStatus';

interface DisabledTime {
  disabledHours?: () => number[];
  disabledMinutes?: (selectedHour: number) => number[];
  disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
}

const CollectionBootle: React.FC = () => {
  const isHpvStudy = (handleLocalStorage.get(website.collection_bootle_id) || '').startsWith('0113');

  useVConsole();
  const [t] = useTranslation();
  const defaultFontStyle = useGetFontStyle();
  const isZhHKLanguage = useIsZhHKLanguage();
  const d4 = useSearchParams()[0].get('d4');
  const d5 = useSearchParams()[0].get('d5');
  const d6 = useSearchParams()[0].get('d6');
  const navigate = useLocalNavigate();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const isLowVersion = isLowVersionChangeDatePicker();
  const isHpv12 = (handleLocalStorage.get(website.collection_bootle_id) || '').startsWith('0114');
  // 强制dob使用input框
  const isForceNoUseDataPicker = true;

  const [isClickSendOtp, setIsClickSendOtp] = useState(false);

  const [charitableDate, setCharitableDate] = useState<any>();
  const [isOnPayment, setIsOnPayment] = useState(false);
  const [isPickUpNeedPayment, _setIsPickUpNeedPayment] = useState(
    sessionStorage.getItem('isPickUpNeedPayment') === '1' ? true : false,
  );

  const setIsPickUpNeedPayment = useMemoizedFn((val: boolean) => {
    sessionStorage.setItem('isPickUpNeedPayment', val ? '1' : '0');
    _setIsPickUpNeedPayment(val);
  });

  const [ngoCode, setNgoCode] = useState<any>();
  const [isCharitableOrganization, setIsCharitableOrganization] = useState<Boolean>(false);
  const [passwordHelpText, setPasswordHelpText] = useState('');
  const [passwordStatus, setPasswordStatus] = useState<TextInputStatus | undefined>();

  const [gender, setGender] = useState<keyof typeof GenderType | null>(null);
  const [papSelectStatus, setPapSelectStatus] = useState<keyof typeof PapSelectType | null>(null);

  const [smearSelectStatus, setSmearSelectStatus] = useState<keyof typeof SmearSelectType | null>(null);

  const [HPVSelectStatus, setHPVSelectStatus] = useState<keyof typeof HPVSelectType | null>(null);

  const [pickDropSelectStatus, setPickDropSelectStatus] = useState<keyof typeof PickDropSelectType | null>(null);
  const [curPage, setCurPage] = useState<keyof typeof CurPage | null>(
    CurPage[CurPage.PersonalInfo] as keyof typeof CurPage,
  );

  const [firstButtonLoading, setFirstButtonLoading] = useState(false);
  const [identityNoClearNumber, setIdentityNoClearNumber] = useState(0);
  const [idtPlaceholder, setIdtPlaceholder] = useState('');
  const [LastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  // const [phone, setPhone] = useState('');
  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();
  const [isClickFirstNext, setIsClickFirstNext] = useState(false);
  const [isFirstSubmitCheck, setIsFirstSubmitCheck] = useState(false);
  const [isSecondSubmitCheck, setIsSecondSubmitCheck] = useState(false);
  const [isThirdSubmitCheck, setIsThirdSubmitCheck] = useState(false);
  const [isAgreeHpvNotSure, setIsAgreeHpvNotSure] = useState(false);

  const [email, setEmail] = useState('');

  const [dob, setDob] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobYear, setDobYear] = useState((new Date().getFullYear() - 30).toString());

  const [dobCheck, setDobCheck] = useState<boolean | string>(false);
  const [isShowDobCheckError, setIsShowDobCheckError] = useState<boolean>(false);
  // const [dobDate, setDobDate] = useState<Dayjs | null | undefined>(null);

  // const [idt, setIdt] = useState('');
  const [idt, setIdt] = useState<any>({
    option: [],
    value: [],
  });
  const [idtTextArea, setIdtTextArea] = useState('');

  const [whereLearn, setWhereLearn] = useState<any>({
    option: [],
    value: [],
  });
  const [whereLearnTextArea, setWhereLearnTextArea] = useState('');

  const [whyPurchase, setWhyPurchase] = useState<any>({
    option: [],
    value: [],
  });
  const [whyPurchaseTextArea, setWhyPurchaseTextArea] = useState('');

  const [identityNo, setIdentityNo] = useState('');
  const [pickUpDate, setPickUpDate] = useState('');
  const [pickUpDay, setPickUpDay] = useState('');
  const [pickUpMonth, setPickUpMonth] = useState('');
  const [pickUpYear, setPickUpYear] = useState('');
  const [forceShowSampleCollectionDate, setForceShowSampleCollectionDate] = useState(true);
  const [forceShowSampleCollectionTime, setForceShowSampleCollectionTime] = useState(true);
  const [limit24Hour, setLimit24Hour] = useState<{
    limitDate: string;
    limitTime: string;
  } | null>(null);

  const [dropOffDate, setDropOffDate] = useState('');
  const [dropOffDay, setDropOffDay] = useState('');
  const [dropOffMonth, setDropOffMonth] = useState('');
  const [dropOffYear, setDropOffYear] = useState('');
  const [sampleCollectionDate, setSampleCollectionDate] = useState('');
  const [sampleCollectionDay, setSampleCollectionDay] = useState('');
  const [sampleCollectionMonth, setSampleCollectionMonth] = useState('');
  const [sampleCollectionYear, setSampleCollectionYear] = useState('');
  const [sampleCollectionTime, setSampleCollectionTime] = useState('');
  const [lastPap, setLastPap] = useState('');
  const [papResult, setPapResult] = useState<any>({
    option: [],
    value: [],
  });
  const [papResultTextArea, setPapResultTextArea] = useState('');
  // const [specifyOthersTextArea, setSpecifyOthersTextArea] = useState('');
  const [hpvResult, setHpvResult] = useState<any>({
    option: [],
    value: [],
  });
  const [hpvResultTextArea, setHpvResultTextArea] = useState('');
  const [fullName, setFullName] = useState('');

  const [addressTextArea, setAddressTextArea] = useState('');

  const [pickTime, setPickTime] = useState('');

  const [dropLocation, setDropLocation] = useState('');
  const [dropLocationCn, setDropLocationCn] = useState('');

  const [disabledTime, setDisabledTime] = useState<DisabledTime | undefined>(undefined);

  // TODO 不用管setAllValue
  const [allValue, setAllValue] = useState<any>({});

  // 根据HPVSelectStatus，选择传递给BE的值
  const [formValue, setFormValue] = useState<any>({
    // 为什么要购买这个产品
    [DISAGREE_FIRST_POSITION - 6]: '',
    // 在哪里了解到Indicate检测
    [DISAGREE_FIRST_POSITION - 5]: '',
    // ngo
    [DISAGREE_FIRST_POSITION - 3]: '',
    [DISAGREE_FIRST_POSITION - 1]: '',
    [DISAGREE_FIRST_POSITION]: d4 === '1' ? 'disagreeAbove' : 'agreeAbove',
    [DISAGREE_FIRST_POSITION + 1]: d5 === '1' ? 'disagreeAbove' : 'agreeAbove',
    // TODO: 移除d6的选项
    [DISAGREE_FIRST_POSITION + 2]: d6 === '1' ? 'disagreeAbove' : 'agreeAbove',
    0: "'" + handleLocalStorage.get(website.collection_bootle_id) || '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    13: '',
    14: '',
    15: '',
    16: '',
    17: '',
    18: '',
    19: isCharitableOrganization
      ? PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]]
      : '',
    20: '',
    21: '',
    22: '',
    23: '',
    24: '',
    25: '',
    [COMMIT_TIME_POSITION]: '',
  });

  const [firstNextHelpText, setFirstNextHelpText] = useState('');
  const [firstNextstatus, setFirstNextStatus] = useState<MessageTextStatus | undefined>();

  const [secondNextHelpText, setSecondNextHelpText] = useState('');
  const [secondNextstatus, setSecondNextStatus] = useState<MessageTextStatus | undefined>();

  const [isOtpSubmitCheck, setIsOtpSubmitCheck] = useState(false);
  const [isPassOtp, setIsPassOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpButtonStatus, setOtpButtonStatus] = useState<ButtonStatus>('default');
  console.log(otpButtonStatus, 'otpButtonStatus');
  const [txt, setTxt] = useState<string>('');
  const [doubleCheckEmail, setDoubleCheckEmail] = useState('');
  const [undersignedBoxValue, setUndersignedBoxValue] = useState(false);
  const [middleSignedBoxValue, setMiddleSignedBoxValue] = useState(false);
  const [readBoxValue, setReadBoxValue] = useState(false);

  const [timeOptionValue, setTimeOptionValue] = useState<{ label: string; value: string }[] | undefined>(undefined);

  const [dayOptionValue, setDayOptionValue] = useState<{ label: string; value: string }[] | undefined>(undefined);
  const [monthOptionValue, setMonthOptionValue] = useState<{ label: string; value: string }[] | undefined>(undefined);
  const [yearOptionValue, setYearOptionValue] = useState<{ label: string; value: string }[] | undefined>(undefined);
  const [forceDayClear, setForceDayClear] = useState(0);

  const [pApartmentAddress, setPApartmentAddress] = useState('');
  const [pAddress, setPAddress] = useState('');
  const [pAddressCountry, setPAddressCountry] = useState('');
  const [pAddressRegion, setPAddressRegion] = useState('');
  const [pAddressDistrict, setPAddressDistrict] = useState<string | undefined>(undefined);
  const [pAddressDistrictOption, setPAddressDistrictOption] = useState<{ label: string; value: string }[] | undefined>(
    undefined,
  );

  const pAddressCountryOption = [
    {
      label: t('collectionBootle.question20CountrySelect1'),
      value: enLocales.collectionBootle.question20CountrySelect1,
    },
  ];

  const pAddressRegionOption = [
    {
      label: t('collectionBootle.question20RegionSelect1'),
      value: enLocales.collectionBootle.question20RegionSelect1,
    },
    {
      label: t('collectionBootle.question20RegionSelect2'),
      value: enLocales.collectionBootle.question20RegionSelect2,
    },
    {
      label: t('collectionBootle.question20RegionSelect3'),
      value: enLocales.collectionBootle.question20RegionSelect3,
    },
  ];

  // TODO: 这个需要调整
  const timerId = website.can_resend_after;
  const { count, startCount: sendCode } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);

  console.log(undersignedBoxValue, readBoxValue);
  console.log(disabledTime);

  const isEmptyValue = (value: any) => {
    if (!value || (value && value.length === 0) || value === '') {
      return true;
    } else {
      return false;
    }
  };
  const getMonthOptionValue = () => {
    const options = [];
    for (let month = 1; month <= 12; month++) {
      const monthString = month.toString().padStart(2, '0');
      options.push({
        label: monthString,
        value: monthString,
      });
    }
    setMonthOptionValue(options);
  };
  const getYearOptionValue = () => {
    const options = [];
    for (let year = 1900; year <= dayjs().year(); year++) {
      options.push({
        label: year.toString(),
        value: year.toString(),
      });
    }
    setYearOptionValue(options);
  };

  const onPAddressDistrictChange = (val: string) => {
    setPAddressDistrict(val);
  };

  const onPApartmentAddressChange = (val: string) => {
    const newValue = val;
    setPApartmentAddress(newValue);
  };

  const onPAddressChange = (val: string) => {
    const newValue = val;
    setPAddress(newValue);
  };

  const handleCheckPAddress: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.AddressEmptyMessage') as string);
      } else if (val) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.AddressInvalidMessage') as string);
      }
    },
  );

  const onPAddressCountryChange = (val: string) => {
    setPAddressCountry(val);
  };

  const onPAddressRegionChange = (val: string) => {
    setPAddressRegion(val);
    // District需要清空
    setPAddressDistrict(undefined);
  };

  const clearLocation = () => {
    const emptyValue = '';
    setDropLocation(emptyValue);
    setFormValue({
      ...formValue,
      22: emptyValue,
    });
  };

  const hiddenLocationTime = (DODate: string, hiddenArr: number[]) => {
    if (!DODate) {
      return false;
    }

    // TODO: 这两个函数应该要调整一下
    if (isPublicHoliday(dayjs(DODate)) && hiddenArr.indexOf(HiddenDayType.PublicHoliday) !== -1) {
      return true;
    }

    if (hiddenArr.indexOf(getSpecifyTimeWeekDay(dayjs(DODate))) !== -1) {
      return true;
    }
    if (hiddenArr.indexOf(dateStr2Number(DODate)) !== -1) {
      return true;
    }

    return false;
  };

  const getTimeOptionValue = () => {
    const options = [];

    // 生成选项
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        options.push({
          label: time,
          value: time,
        });
      }
    }

    setTimeOptionValue(options);
  };

  const onUndersignedBoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setUndersignedBoxValue(e.target.checked);
  });
  const onMiddleSignedBoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setMiddleSignedBoxValue(e.target.checked);
  });
  const onReadBoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setReadBoxValue(e.target.checked);
  });

  const onDoubleCheckEmailChange = (val: string) => {
    setDoubleCheckEmail(val);
  };

  const handleCheckDoubleCheckEmail: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        // 允许为空
        setStatus(undefined);
        setHelpText('');
        // setStatus('error');
        // setHelpText(t('collectionBootle.DoubleCheckEmailEmptyError') as string);
      } else if (val && val === email) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.DoubleCheckEmailInvalidError') as string);
      }
    },
  );

  const onOtpChange = (val: string) => {
    const newValue = val;
    setOtp(newValue);
  };

  const apiSendCode = () => {
    post('/user/send-otp', {
      type: 'phone',
      phone: phoneNumber,
    })
      .then(() => {
        sendCode();
        setIsClickSendOtp(true);
        // alert('TODO: Alan\n' + '发送成功');
      })
      .catch((e) => {
        message.error(e.response.data.message);

        // alert('TODO: Alan\n' + e.response.data.message);
      });
  };

  const checkFirstJump = () => {
    const isJumpFirstClick = (isJumpPassOtp: boolean) => {
      let isConformToFormatIdentityNo = false;
      if ([enLocales.collectionBootle.question7HK].includes(idt?.option)) {
        isConformToFormatIdentityNo = isValidHKID(identityNo);
      } else if ([enLocales.collectionBootle.question7ID].includes(idt?.option)) {
        isConformToFormatIdentityNo = isValidCNID(identityNo);
      } else {
        isConformToFormatIdentityNo = identityNo && identityNo.length > 0 ? true : false;
      }
      // idt?.value必须有值，选择了other，但是不填type，也不允许通过
      if (
        !(
          isLastName(LastName) &&
          isLastName(firstName) &&
          isPhoneNumberValid &&
          // isEmail(email) &&
          gender &&
          dob &&
          idt?.value &&
          idt?.value.length > 0 &&
          isConformToFormatIdentityNo &&
          isJumpPassOtp &&
          email === doubleCheckEmail
        )
      ) {
        setFirstNextHelpText(t('collectionBootle.clickNextErrorMessage') as string);
        setFirstNextStatus('error');
        setIsClickFirstNext(false);
        return;
        // 弹出信息，请填写全部内容
      }
      setFirstNextStatus(undefined);
      // 如果成功调整，则把计数设置为0
      setIdentityNoClearNumber(0);
      setCurPage(CurPage[isHpvStudy ? CurPage.Delivery : CurPage.MedicalHistory] as keyof typeof CurPage);
      // 只有点击了，才能进行跳转，跳转之后，需要把isFirstSubmitCheck设置为false
      localStorage.removeItem(`timer:${website.can_resend_after}`);
      setIsFirstSubmitCheck(false);
      setIsClickFirstNext(false);
    };

    // 校验前先设置button的loading
    setFirstButtonLoading(true);
    post('/user/otp-code-validation', {
      phone: phoneNumber,
      code: otp,
    })
      .then(() => {
        setIsPassOtp(true);
        // 当/user/auth的异步返回值时，才进行跳转的验证
        isJumpFirstClick(true);
        setPasswordStatus('error');
        setPasswordHelpText('' as string);
        setFirstButtonLoading(false);
      })
      .catch((e) => {
        setPasswordStatus('error');
        setPasswordHelpText(e.response.data.message);
        setIsPassOtp(false);
        console.log(e, 'e');
        setFirstButtonLoading(false);
        // alert('TODO: Alan\n' + e.response.data.message);
      });
  };

  // const apiAuth = (isPhoneOtpSubmitCheck: boolean | undefined, setStatus: any, setHelpText: any) => {
  //   if (!isPhoneOtpSubmitCheck) {
  //     return;
  //   }

  //   post('/user/auth', {
  //     phone: phoneNumber,
  //     code: otp,
  //   })
  //     .then(() => {
  //       setStatus('success');
  //       setHelpText('');
  //       // 每次点击的时候，才进行验证
  //       setIsOtpSubmitCheck(false);
  //       setIsPassOtp(true);
  //       // alert('TODO: Alan\n' + '验证成功');
  //     })
  //     .catch((e) => {
  //       setStatus('error');
  //       setHelpText(e.response.data.message as string);
  //       // 每次点击的时候，才进行验证
  //       setIsOtpSubmitCheck(false);
  //       setIsPassOtp(false);
  //       // alert('TODO: Alan\n' + e.response.data.message);
  //     });
  // };

  const handleCheckOtp: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck, isPhoneOtpSubmitCheck) => {
      if (val === '' && !isPhoneOtpSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isPhoneOtpSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.OTPEmptyError') as string);
      } else {
        setStatus(undefined);
        setHelpText('');
        // apiAuth(isPhoneOtpSubmitCheck, setStatus, setHelpText);
        // setStatus(undefined);
        // setHelpText('');
      }
    },
  );

  const forceFirstSubmitShowError = useMemoizedFn((value: any) => {
    return isEmptyValue(value) && isFirstSubmitCheck;
  });

  const forceSecondSubmitShowError = (value: any) => {
    return isEmptyValue(value) && isSecondSubmitCheck;
  };

  const forceThirdSubmitShowError = (value: any) => {
    return isEmptyValue(value) && isThirdSubmitCheck;
  };

  const handleCheckFirstName: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.FirstNameEmptyMessage') as string);
      } else if (isLastName(val)) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.FirstNameInvalidMessage') as string);
      }
    },
  );

  const handleCheckLastName: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.LastNameEmptyMessage') as string);
      } else if (isLastName(val)) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.LastNameInvalidMessage') as string);
      }
    },
  );

  const handleCheckFullName: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.FullNameEmptyMessage') as string);
      } else if (
        (val &&
          [
            `${LastName.toUpperCase().trim()} ${firstName.toUpperCase().trim()}`,
            `${LastName.toUpperCase().trim()}${firstName.toUpperCase().trim()}`,
          ].includes(val.toUpperCase().trim())) ||
        (val &&
          [
            `${firstName.toUpperCase().trim()} ${LastName.toUpperCase().trim()}`,
            `${firstName.toUpperCase().trim()}${LastName.toUpperCase().trim()}`,
          ].includes(val.toUpperCase().trim()))
      ) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.FullNameInvalidMessage') as string);
      }
    },
  );

  const handleCheckIdentityNo: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      let isConformToFormatIdentityNo = false;
      // TODO 这里存在问题=>需要再看一下

      if ([enLocales.collectionBootle.question7HK].includes(idt?.option)) {
        isConformToFormatIdentityNo = isValidHKID(val);
      } else if ([enLocales.collectionBootle.question7ID].includes(idt?.option)) {
        isConformToFormatIdentityNo = isValidCNID(val);
      } else {
        isConformToFormatIdentityNo = val && val.length > 0 ? true : false;
      }

      if (!idt?.option && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.NoSelectMessage') as string);
      } else if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.NoEmptyMessage') as string);
      } else if (isConformToFormatIdentityNo) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.NoInvalidMessage') as string);
      }
    },
  );

  const handleCheckEmail: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        // email可以为空
        setStatus(undefined);
        setHelpText('');
        // setStatus('error');
        // setHelpText(t('collectionBootle.EmailEmptyMessage') as string);
      } else if (isEmail(val)) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.EmailInvalidMessage') as string);
      }
    },
  );

  // const handleCheckDobDay: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
  //   (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
  //     if (val === '' && !isFiledInputSubmitCheck) {
  //       setStatus(undefined);
  //       setHelpText('');
  //     } else if (val === '' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(t('collectionBootle.DateNotBeEmpty') as string);
  //     } else if (isFiledInputSubmitCheck !== 'success' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(isFiledInputSubmitCheck as string);
  //     } else if (isFiledInputSubmitCheck === 'success') {
  //       setStatus('success');
  //       setHelpText('');
  //     } else {
  //       setStatus(undefined);
  //       setHelpText('');
  //     }
  //   },
  // );

  // const handleCheckDobMonth: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
  //   (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
  //     if (val === '' && !isFiledInputSubmitCheck) {
  //       setStatus(undefined);
  //       setHelpText('');
  //     } else if (val === '' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(t('collectionBootle.MonthCanNotBeEmpty') as string);
  //     } else if (isFiledInputSubmitCheck !== 'success' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(isFiledInputSubmitCheck as string);
  //     } else if (isFiledInputSubmitCheck === 'success') {
  //       setStatus('success');
  //       setHelpText('');
  //     } else {
  //       setStatus(undefined);
  //       setHelpText('');
  //     }
  //   },
  // );

  // const handleCheckDobYear: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
  //   (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
  //     if (val === '' && !isFiledInputSubmitCheck) {
  //       setStatus(undefined);
  //       setHelpText('');
  //     } else if (val === '' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(t('collectionBootle.YearCanNotBeEmpty') as string);
  //     } else if (isFiledInputSubmitCheck !== 'success' && isFiledInputSubmitCheck) {
  //       setStatus('error');
  //       setHelpText(isFiledInputSubmitCheck as string);
  //     } else if (isFiledInputSubmitCheck === 'success') {
  //       setStatus('success');
  //       setHelpText('');
  //     } else {
  //       setStatus(undefined);
  //       setHelpText('');
  //     }
  //   },
  // );

  const handleCheckDropOffDay: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.DateNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckDropOffMonth: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.MonthCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckDropOffYear: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.YearCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckSampleCollectionDay: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.DateNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckSampleCollectionMonth: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.MonthCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckSampleCollectionYear: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.YearCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckPickUpDay: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.DateNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckPickUpMonth: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.MonthCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckPickUpYear: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.YearCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const onFirstNameChange = (val: string) => {
    const newValue = val;
    setFirstName(newValue);
    setFormValue({
      ...formValue,
      2: newValue,
    });
  };

  const onBlurFirstName = () => {
    setFirstName((pre: any) => pre.toUpperCase());
    setFormValue((pre: any) => {
      return {
        ...pre,
        2: pre[2].toUpperCase(),
      };
    });
  };

  const onLastNameChange = (val: string) => {
    const newValue = val;
    // const newValue = val.toUpperCase();
    setLastName(newValue);
    setFormValue({
      ...formValue,
      1: newValue,
    });
  };

  const onBlurLastName = () => {
    setLastName((pre: any) => pre.toUpperCase());
    setFormValue((pre: any) => {
      return {
        ...pre,
        1: pre[1].toUpperCase(),
      };
    });
  };

  // const onPhoneChange = (val: string) => {
  //   setPhone(val);
  //   setFormValue({
  //     ...formValue,
  //     3: val,
  //   });
  // };

  const onEmailChange = (val: string) => {
    setEmail(val);
    setFormValue({
      ...formValue,
      4: val,
    });
  };

  // TODO 抽取出公共的

  const onDobChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
    // setDobDate(date);
    setDob(dateString);
    setFormValue({
      ...formValue,
      6: dateString,
    });
  };

  const isValidDate = (dateString: string) => {
    const format = 'DD/MM/YYYY';

    let parts = dateString.split('/');
    let transformedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
    let isDateValid = !isNaN(new Date(transformedDate).getTime());

    // 二月份的bug，需要解决一下
    if (Number(parts[1]) !== Number(new Date(transformedDate).getMonth()) + 1) {
      isDateValid = false;
    }

    const isValid = dayjs(dateString, format).isValid() && isDateValid;

    return isValid;
  };

  const forceAddZero = (value: string) => {
    let val = value;
    if (val.length === 1) {
      if (val.charAt(0) === '0') {
        // 如果两个零，就清空
        val = '';
      } else {
        val = '0' + val;
      }
    } else if (
      (val.length === 2 && val.charAt(0) === '0' && val.charAt(1) === '0') ||
      (val.length === 1 && val.charAt(0) === '0')
    ) {
      // 如果两个零，就清空
      val = '';
    } else if (val.length > 2) {
      if (val.charAt(0) === '0') {
        val = val.slice(1, 3);
      } else {
        val = val.slice(0, 2);
      }
    } else {
      val = val;
    }

    return val;
  };

  const onDobDayChange = (val: string) => {
    let newDobDay = forceAddZero(val);

    setDobDay(newDobDay);

    let newDob;

    const refreshValue = `${newDobDay}/${dobMonth}/${dobYear}`;

    const isHaveAllValue =
      newDobDay && newDobDay.length > 0 && dobMonth && dobMonth.length > 0 && dobYear && dobYear.length > 0;
    const isCorrectFormat = isHaveAllValue && isValidDate(refreshValue);

    if (isCorrectFormat) {
      newDob = refreshValue;
      setDobCheck('success');

      const dobTmp = dayjs(refreshValue, 'DD/MM/YYYY');
      const dob18 = dobTmp.add(18, 'year');

      // 今天是否在18岁之前
      const isBefore18 = dob18.isBefore(dayjs());

      if (!isBefore18) {
        newDob = '';
        setDobCheck(t('collectionBootle.AgeError') as string);
      }
    } else {
      newDob = '';
      setDobCheck(t('collectionBootle.FormatError') as string);
      if (isHaveAllValue) {
        setForceDayClear((pre) => pre + 1);
      }
    }

    setDob(newDob);
    setFormValue({
      ...formValue,
      6: newDob,
    });
  };

  const onDobMonthChange = (val: string) => {
    let newDobMonth = forceAddZero(val);
    setDobMonth(newDobMonth);

    let newDob;
    const refreshValue = `${dobDay}/${newDobMonth}/${dobYear}`;

    const isHaveAllValue =
      dobDay && dobDay.length > 0 && newDobMonth && newDobMonth.length > 0 && dobYear && dobYear.length > 0;
    const isCorrectFormat = isHaveAllValue && isValidDate(refreshValue);

    if (isCorrectFormat) {
      newDob = refreshValue;
      setDobCheck('success');

      const dobTmp = dayjs(refreshValue, 'DD/MM/YYYY');
      const dob18 = dobTmp.add(18, 'year');

      // 今天是否在18岁之前
      const isBefore18 = dob18.isBefore(dayjs());

      if (!isBefore18) {
        newDob = '';
        setDobCheck(t('collectionBootle.AgeError') as string);
      }
    } else {
      newDob = '';
      setDobCheck(t('collectionBootle.FormatError') as string);
      if (isHaveAllValue) {
        setDobDay('');
      }
    }

    setDob(newDob);
    setFormValue({
      ...formValue,
      6: newDob,
    });
  };

  const onDobYearChange = (val: string) => {
    let newDobYear;

    if (val.length > 4) {
      newDobYear = val.slice(0, 4);
    } else {
      newDobYear = val;
    }

    setDobYear(newDobYear);

    let newDob;

    const refreshValue = `${dobDay}/${dobMonth}/${newDobYear}`;

    const isHaveAllValue =
      dobDay && dobDay.length > 0 && dobMonth && dobMonth.length > 0 && newDobYear && newDobYear.length > 0;
    const isCorrectFormat = isHaveAllValue && isValidDate(refreshValue);

    if (isCorrectFormat) {
      newDob = refreshValue;
      setDobCheck('success');
    } else {
      newDob = '';
      setDobCheck(t('collectionBootle.FormatError') as string);
      if (isHaveAllValue) {
        setDobDay('');
      }
    }
    if (dobDay && dobMonth && newDobYear) {
      const dobTmp = dayjs(refreshValue, 'DD/MM/YYYY');
      const dob18 = dobTmp.add(18, 'year');

      // 今天是否在18岁之前
      const isBefore18 = dob18.isBefore(dayjs());

      if (!isBefore18) {
        newDob = '';
        setDobCheck(t('collectionBootle.AgeError') as string);
      }
    }
    setDob(newDob);
    setFormValue({
      ...formValue,
      6: newDob,
    });
  };

  const onDropOffDayChange = (val: string) => {
    let newDropOffDay;

    if (val.length > 2) {
      newDropOffDay = val.slice(0, 2);
    } else {
      newDropOffDay = val;
    }

    setDropOffDay(newDropOffDay);

    let newDropOff;
    if (
      newDropOffDay &&
      newDropOffDay.length > 0 &&
      dropOffMonth &&
      dropOffMonth.length > 0 &&
      dropOffYear &&
      dropOffYear.length > 0
    ) {
      newDropOff = `${newDropOffDay}/${dropOffMonth}/${dropOffYear}`;
    } else {
      newDropOff = '';
    }

    setDropOffDate(newDropOff);
    setFormValue({
      ...formValue,
      [DISAGREE_FIRST_POSITION - 1]: newDropOff,
    });
    clearLocation();
  };

  const onDropOffMonthChange = (val: string) => {
    let newDropOffMonth;

    if (val.length > 2) {
      newDropOffMonth = val.slice(0, 2);
    } else {
      newDropOffMonth = val;
    }

    setDropOffMonth(newDropOffMonth);

    let newDropOff;
    if (
      dropOffDay &&
      dropOffDay.length > 0 &&
      newDropOffMonth &&
      newDropOffMonth.length > 0 &&
      dropOffYear &&
      dropOffYear.length > 0
    ) {
      newDropOff = `${dropOffDay}/${newDropOffMonth}/${dropOffYear}`;
    } else {
      newDropOff = '';
    }

    setDropOffDate(newDropOff);
    setFormValue({
      ...formValue,
      [DISAGREE_FIRST_POSITION - 1]: newDropOff,
    });
    clearLocation();
  };

  const onDropOffYearChange = (val: string) => {
    let newDropOffYear;

    if (val.length > 4) {
      newDropOffYear = val.slice(0, 4);
    } else {
      newDropOffYear = val;
    }

    setDropOffYear(newDropOffYear);

    let newDropOff;
    if (
      dropOffDay &&
      dropOffDay.length > 0 &&
      dropOffMonth &&
      dropOffMonth.length > 0 &&
      newDropOffYear &&
      newDropOffYear.length > 0
    ) {
      newDropOff = `${dropOffDay}/${dropOffMonth}/${newDropOffYear}`;
    } else {
      newDropOff = '';
    }

    setDropOffDate(newDropOff);
    setFormValue({
      ...formValue,
      [DISAGREE_FIRST_POSITION - 1]: newDropOff,
    });
    clearLocation();
  };

  const onSampleCollectionDayChange = (val: string) => {
    let newSampleCollectionDay;

    if (val.length > 2) {
      newSampleCollectionDay = val.slice(0, 2);
    } else {
      newSampleCollectionDay = val;
    }

    setSampleCollectionDay(newSampleCollectionDay);

    let newSampleCollection;
    if (
      newSampleCollectionDay &&
      newSampleCollectionDay.length > 0 &&
      sampleCollectionMonth &&
      sampleCollectionMonth.length > 0 &&
      sampleCollectionYear &&
      sampleCollectionYear.length > 0
    ) {
      newSampleCollection = `${newSampleCollectionDay}/${sampleCollectionMonth}/${sampleCollectionYear}`;
    } else {
      newSampleCollection = '';
    }
    setSampleCollectionDate(newSampleCollection);
    setFormValue({
      ...formValue,
      24: newSampleCollection,
    });
  };

  const onSampleCollectionMonthChange = (val: string) => {
    let newSampleCollectionMonth;

    if (val.length > 2) {
      newSampleCollectionMonth = val.slice(0, 2);
    } else {
      newSampleCollectionMonth = val;
    }

    setSampleCollectionMonth(newSampleCollectionMonth);

    let newSampleCollection;
    if (
      sampleCollectionDay &&
      sampleCollectionDay.length > 0 &&
      newSampleCollectionMonth &&
      newSampleCollectionMonth.length > 0 &&
      sampleCollectionYear &&
      sampleCollectionYear.length > 0
    ) {
      newSampleCollection = `${sampleCollectionDay}/${newSampleCollectionMonth}/${sampleCollectionYear}`;
    } else {
      newSampleCollection = '';
    }

    setSampleCollectionDate(newSampleCollection);
    setFormValue({
      ...formValue,
      24: newSampleCollection,
    });
  };

  const onSampleCollectionYearChange = (val: string) => {
    let newSampleCollectionYear;

    if (val.length > 4) {
      newSampleCollectionYear = val.slice(0, 4);
    } else {
      newSampleCollectionYear = val;
    }

    setSampleCollectionYear(newSampleCollectionYear);

    let newSampleCollection;
    if (
      sampleCollectionDay &&
      sampleCollectionDay.length > 0 &&
      sampleCollectionMonth &&
      sampleCollectionMonth.length > 0 &&
      newSampleCollectionYear &&
      newSampleCollectionYear.length > 0
    ) {
      newSampleCollection = `${sampleCollectionDay}/${sampleCollectionMonth}/${newSampleCollectionYear}`;
    } else {
      newSampleCollection = '';
    }

    setSampleCollectionDate(newSampleCollection);
    setFormValue({
      ...formValue,
      24: newSampleCollection,
    });
  };

  const onPickUpDayChange = (val: string) => {
    let newPickUpDay;

    if (val.length > 2) {
      newPickUpDay = val.slice(0, 2);
    } else {
      newPickUpDay = val;
    }

    setPickUpDay(newPickUpDay);

    let newPickUp;
    if (
      newPickUpDay &&
      newPickUpDay.length > 0 &&
      pickUpMonth &&
      pickUpMonth.length > 0 &&
      pickUpYear &&
      pickUpYear.length > 0
    ) {
      newPickUp = `${newPickUpDay}/${pickUpMonth}/${pickUpYear}`;
    } else {
      newPickUp = '';
    }

    setPickUpDate(newPickUp);
    const newFormValue = {
      ...formValue,
      20: newPickUp,
    };
    setFormValue(newFormValue);
  };

  const onPickUpMonthChange = (val: string) => {
    let newPickUpMonth;

    if (val.length > 2) {
      newPickUpMonth = val.slice(0, 2);
    } else {
      newPickUpMonth = val;
    }

    setPickUpMonth(newPickUpMonth);

    let newPickUp;
    if (
      pickUpDay &&
      pickUpDay.length > 0 &&
      newPickUpMonth &&
      newPickUpMonth.length > 0 &&
      pickUpYear &&
      pickUpYear.length > 0
    ) {
      newPickUp = `${pickUpDay}/${newPickUpMonth}/${pickUpYear}`;
    } else {
      newPickUp = '';
    }

    setPickUpDate(newPickUp);
    const newFormValue = {
      ...formValue,
      20: newPickUp,
    };
    setFormValue(newFormValue);
  };

  const onPickUpYearChange = (val: string) => {
    let newPickUpYear;

    if (val.length > 4) {
      newPickUpYear = val.slice(0, 4);
    } else {
      newPickUpYear = val;
    }

    setPickUpYear(newPickUpYear);

    let newPickUp;
    if (
      pickUpDay &&
      pickUpDay.length > 0 &&
      pickUpMonth &&
      pickUpMonth.length > 0 &&
      newPickUpYear &&
      newPickUpYear.length > 0
    ) {
      newPickUp = `${pickUpDay}/${pickUpMonth}/${newPickUpYear}`;
    } else {
      newPickUp = '';
    }

    setPickUpDate(newPickUp);
    const newFormValue = {
      ...formValue,
      20: newPickUp,
    };
    setFormValue(newFormValue);
  };

  const onIdentityNoChange = (val: string) => {
    setIdentityNo(val);
    setFormValue({
      ...formValue,
      8: val,
    });
  };

  const clearCollectionTime = () => {
    // 强制刷新表单的值
    setForceShowSampleCollectionTime(false);
    setTimeout(() => {
      setForceShowSampleCollectionTime(true);
    }, 1000);
    const emptyValue = '';
    setSampleCollectionTime(emptyValue);
    setFormValue({
      ...formValue,
      25: emptyValue,
    });
  };

  // 清除这Sample Collection Date & Sample Collection Time
  const clearCollectionDateAndTime = (newFormValue: any) => {
    // 强制刷新表单的值
    setForceShowSampleCollectionDate(false);
    setTimeout(() => {
      setForceShowSampleCollectionDate(true);
    }, 1000);
    const emptyValue = '';
    setSampleCollectionDate(emptyValue);
    setSampleCollectionTime(emptyValue);
    setFormValue({
      ...newFormValue,
      24: emptyValue,
      25: emptyValue,
    });
  };

  const onPickUpDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    // 强制清除Time表单的值
    console.log(date, dateString);
    setPickUpDate(dateString);
    const newFormValue = {
      ...formValue,
      20: dateString,
    };
    setFormValue(newFormValue);

    clearCollectionDateAndTime(newFormValue);
  };

  const onDropOffDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    // if (pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]) {
    //   clearCollectionTime();
    // }
    setDropOffDate(dateString);
    setFormValue({
      ...formValue,
      [DISAGREE_FIRST_POSITION - 1]: dateString,
    });

    clearLocation();
  };

  const onSampleCollectionDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]) {
      clearCollectionTime();
    }
    console.log(date, dateString);
    setSampleCollectionDate(dateString);
    setFormValue({
      ...formValue,
      24: dateString,
    });
  };

  const onSampleCollectionTimeChange = (val: string) => {
    setSampleCollectionTime(val);
    setFormValue({
      ...formValue,
      25: val,
    });
  };
  const onClickFirstNext: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    setIsFirstSubmitCheck(true);
    setIsOtpSubmitCheck(true);
    setIsClickFirstNext(true);

    //  因为不能在useEffect里面监听，所以在onclick这里设置初始值
    if (isCharitableOrganization) {
      setFormValue({
        ...formValue,
        19: PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]],
      });
    }

    checkFirstJump();
  };

  // 只有符合条件的时候，才会进行跳转
  useEffect(() => {
    const isJumpFirstClick = () => {
      // 只有isFirstSubmitCheck为true时，才能进行下一步
      if (!isClickFirstNext) {
        return;
      }

      let isConformToFormatIdentityNo = false;
      if ([enLocales.collectionBootle.question7HK].includes(idt?.option)) {
        isConformToFormatIdentityNo = isValidHKID(identityNo);
      } else if ([enLocales.collectionBootle.question7ID].includes(idt?.option)) {
        isConformToFormatIdentityNo = isValidCNID(identityNo);
      } else {
        isConformToFormatIdentityNo = identityNo && identityNo.length > 0 ? true : false;
      }

      if (
        !(
          isLastName(LastName) &&
          isLastName(firstName) &&
          isPhoneNumberValid &&
          // isEmail(email) &&
          gender &&
          dob &&
          idt?.option &&
          isConformToFormatIdentityNo &&
          isPassOtp &&
          email === doubleCheckEmail
        )
      ) {
        setFirstNextHelpText(t('collectionBootle.clickNextErrorMessage') as string);
        setFirstNextStatus('error');
        setIsClickFirstNext(false);
        return;
        // 弹出信息，请填写全部内容
      }
      setFirstNextStatus(undefined);
      // 如果成功调整，则把计数设置为0
      setIdentityNoClearNumber(0);
      setCurPage(CurPage[isHpvStudy ? CurPage.Delivery : CurPage.MedicalHistory] as keyof typeof CurPage);
      // 只有点击了，才能进行跳转，跳转之后，需要把isFirstSubmitCheck设置为false
      setIsFirstSubmitCheck(false);
      setIsClickFirstNext(false);
    };

    isJumpFirstClick();
  }, [
    LastName,
    dob,
    doubleCheckEmail,
    email,
    firstName,
    gender,
    identityNo,
    idt?.option,
    isClickFirstNext,
    // isFirstSubmitCheck,
    isPassOtp,
    isPhoneNumberValid,
    t,
    isHpvStudy,
  ]);

  const onClickSecondBack: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    // TODO 需要进行校验
    setCurPage(CurPage[CurPage.PersonalInfo] as keyof typeof CurPage);
  };

  console.log(isSecondSubmitCheck, 'isSecondSubmitCheck');
  const isPassSecondNext = () => {
    let isPass = false;

    // 当gender为Male时，不需要校验这几个值
    if (gender !== GenderType[GenderType['Male' as keyof typeof GenderType]]) {
      if (isEmptyValue(papSelectStatus)) {
        return isPass;
      }

      if (papSelectStatus === PapSelectType[PapSelectType['Yes' as keyof typeof PapSelectType]]) {
        if (isEmptyValue(lastPap) || isEmptyValue(smearSelectStatus)) {
          return isPass;
        }

        if (smearSelectStatus === SmearSelectType[SmearSelectType['Positive' as keyof typeof SmearSelectType]]) {
          // papResult?.value必须有值，选择了选项，但是不填值，也不允许通过
          if (isEmptyValue(papResult?.value)) {
            return isPass;
          }
        }
      }
    }

    if (isEmptyValue(HPVSelectStatus)) {
      return isPass;
    }

    // hpvResult?.value必须有值，选择了选项，但是不填值，也不允许通过
    if (HPVSelectStatus === HPVSelectType[HPVSelectType['Yes' as keyof typeof HPVSelectType]]) {
      if (isEmptyValue(hpvResult?.value)) {
        return isPass;
      }
    }

    // 校验疫苗有没有选
    if (!formValue[28]) {
      return false;
    }

    if (formValue[28] === '是' && !formValue[29]) {
      return false;
    }

    // 校验fullname是否符合规则
    const isPassFullName =
      (fullName &&
        [
          `${LastName.toUpperCase().trim()} ${firstName.toUpperCase().trim()}`,
          `${LastName.toUpperCase().trim()}${firstName.toUpperCase().trim()}`,
        ].includes(fullName.toUpperCase().trim())) ||
      (fullName &&
        [
          `${firstName.toUpperCase().trim()} ${LastName.toUpperCase().trim()}`,
          `${firstName.toUpperCase().trim()}${LastName.toUpperCase().trim()}`,
        ].includes(fullName.toUpperCase().trim()));

    isPass = isPassFullName as boolean;

    return isPass;
  };

  const onClickSecondNext: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    setIsSecondSubmitCheck(true);
    const isPass = isPassSecondNext();

    if (!isPass) {
      setSecondNextHelpText(t('collectionBootle.clickNextErrorMessage') as string);
      setSecondNextStatus('error');
      // TODO isPass必须通过
      return;
    }

    // TODO 需要进行校验

    // 通过的时候清空状态
    setCurPage(CurPage[CurPage.Delivery] as keyof typeof CurPage);
    setSecondNextStatus('error');
  };

  const onClickThirdBack: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    if (isHpvStudy) {
      setCurPage(CurPage[CurPage.PersonalInfo] as keyof typeof CurPage);
    } else {
      // TODO 需要进行校验
      setCurPage(CurPage[CurPage.MedicalHistory] as keyof typeof CurPage);
    }
  };

  // const alertSuccessReminder = useMemoizedFn(() => {
  //   // console.log(modalStatus, 'modalStatus');
  //   ResultAlert((close) => ({
  //     content: (
  //       <div>
  //         <div className="flex justify-between items-center">
  //           <div className="w-[28px]  lg:w-[28px] mr-[10px] lg:mr-[10px]">
  //             <img src={feeTick} alt="feeTick" className="object-cover w-[28px]  cursor-pointer  lg:w-[28px]" />
  //           </div>
  //           <div className="font-[600] text-[16px] leading-[24px] text-[#25282B] lg:text-[16px] lg:leading-[24px]">
  //             Submit Successful
  //           </div>
  //           <div
  //             className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px]"
  //             onClick={() => {
  //               close();
  //               // await RtcAdapter.leave();
  //               // navigate('/meeting/exception?type=end');
  //             }}
  //           >
  //             <img src={modalTick} alt="modalTick" className="object-cover w-[14px]  cursor-pointer  lg:w-[14px]" />
  //           </div>
  //         </div>
  //         <div className="ml-[38px] lg:ml-[38px]  font-[400] text-[14px] leading-[22px] text-[#25282B] lg:text-[14px] lg:leading-[22px]">
  //           Submit Successful. The content has been successfully submitted.
  //         </div>
  //       </div>
  //     ),
  //   }));
  // });

  const alertErrorReminder = useMemoizedFn(() => {
    // console.log(modalStatus, 'modalStatus');
    ResultAlert((close) => ({
      content: (
        <div>
          <div className="flex justify-between items-center">
            <div className="w-[28px]  lg:w-[28px] mr-[10px] lg:mr-[10px]">
              <img src={modalError} alt="modalError" className="object-cover w-[28px]  cursor-pointer  lg:w-[28px]" />
            </div>
            <div className="font-[600] text-[16px] leading-[24px] text-[#25282B] lg:text-[16px] lg:leading-[24px]">
              {t('collectionBootle.SubmitFailedTitle')}
            </div>
            <div
              className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px]"
              onClick={() => {
                close();
                // await RtcAdapter.leave();
                // navigate('/meeting/exception?type=end');
              }}
            >
              <img src={modalTick} alt="modalTick" className="object-cover w-[14px]  cursor-pointer  lg:w-[14px]" />
            </div>
          </div>
          <div className="ml-[38px] lg:ml-[38px]  font-[400] text-[14px] leading-[22px] text-[#25282B] lg:text-[14px] lg:leading-[22px]">
            {t('collectionBootle.SubmitFailedText')}
          </div>
        </div>
        // <div className="flex flex-col overflow-scroll">
        //   {/* TODO 展示不同的颜色=>Lg的时候，有背景颜色 */}
        //   <div className="flex flex-col lg:bg-[#E9E9E9] lg:p-[33px_98px_55px_83px] lg:rounded-[0_0_30px_30px]">
        //     <div
        //       className="font-[600] text-[16px] leading-[20px] underline text-[#F37021] text-center lg:text-[16px] lg:leading-[20px] "
        //       onClick={() => {
        //         close();
        //         // await RtcAdapter.leave();
        //         // navigate('/meeting/exception?type=end');
        //       }}
        //     >
        //       Do it later【alertErrorReminder】
        //     </div>
        //   </div>
        //   {/* TODO 需要补充逻辑 */}
        // </div>
      ),
    }));
  });

  const alertDeliveryInfo = useMemoizedFn((deliveryType: keyof typeof PickDropSelectType) => {
    const isPick = deliveryType
      ? deliveryType === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
      : pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]];

    // console.log(modalStatus, 'modalStatus');
    DeliveryAlert((close) => ({
      content: (
        <div>
          <div className="flex justify-between items-start relative">
            <div className="w-[24px]  lg:w-[24px] mr-[12px] lg:mr-[12px]">
              <img src={blueSigns} alt="blueSigns" className="object-cover w-[24px]  cursor-pointer  lg:w-[24px]" />
            </div>
            <div className="flex flex-col">
              <div
                className="font-[600] text-[16px] leading-[24px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                style={{
                  fontFamily: defaultFontStyle,
                }}
              >
                {isPick ? t('collectionBootle.PickUpTitle') : t('collectionBootle.DropOffTitle')}
              </div>
              <div className="font-[600] text-[16px] leading-[24px] text-[#25282B] w-[65vw] lg:text-[16px] lg:leading-[24px] lg:w-[265px]">
                {isPick ? (
                  <div
                    className="font-[400] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                    style={{
                      fontFamily: defaultFontStyle,
                    }}
                  >
                    {t('collectionBootle.PickUpAlert1')}{' '}
                    <span
                      className="font-[600] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                      style={{
                        fontFamily: defaultFontStyle,
                      }}
                    >
                      {t('collectionBootle.PickUpAlert2')}
                    </span>{' '}
                    {t('collectionBootle.PickUpAlert3')}​{' '}
                  </div>
                ) : (
                  <div
                    className="font-[400] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                    style={{
                      fontFamily: defaultFontStyle,
                    }}
                  >
                    {t('collectionBootle.DropOffAlert1')}{' '}
                    <span
                      className="font-[600] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                      style={{
                        fontFamily: defaultFontStyle,
                      }}
                    >
                      {t('collectionBootle.DropOffAlert2')}
                    </span>{' '}
                    {t('collectionBootle.DropOffAlert3')}
                  </div>
                )}
              </div>
            </div>
            {/* TODO 使用绝对定位 */}
            <div
              className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px] absolute top-[-9px] right-[-12px]  lg:top-[-9px] lg:right-[-12px]"
              onClick={() => {
                close();
                // await RtcAdapter.leave();
                // navigate('/meeting/exception?type=end');
              }}
            >
              <img src={modalTick} alt="modalTick" className="object-cover w-[14px]  cursor-pointer  lg:w-[14px]" />
            </div>
          </div>
          {/* <div className="ml-[38px] lg:ml-[38px]  font-[400] text-[14px] leading-[22px] text-[#25282B] lg:text-[14px] lg:leading-[22px]">
            Cannot submit. Required fields are not filled in.
          </div> */}
        </div>
        // <div className="flex flex-col overflow-scroll">
        //   {/* TODO 展示不同的颜色=>Lg的时候，有背景颜色 */}
        //   <div className="flex flex-col lg:bg-[#E9E9E9] lg:p-[33px_98px_55px_83px] lg:rounded-[0_0_30px_30px]">
        //     <div
        //       className="font-[600] text-[16px] leading-[20px] underline text-[#F37021] text-center lg:text-[16px] lg:leading-[20px] "
        //       onClick={() => {
        //         close();
        //         // await RtcAdapter.leave();
        //         // navigate('/meeting/exception?type=end');
        //       }}
        //     >
        //       Do it later【alertErrorReminder】
        //     </div>
        //   </div>
        //   {/* TODO 需要补充逻辑 */}
        // </div>
      ),
    }));
  });

  const checkHpvStudySingle = useRef<() => boolean>();

  const [isNeedUpgrade, setIsNeedUpgrade] = useState(false);

  const onClickThirdSubmit = async (e: any, isForthStep?: boolean) => {
    e.preventDefault();

    if (isHpvStudy) {
      const isPass = checkHpvStudySingle.current?.();
      if (!isPass) {
        return;
      }
    }

    setIsThirdSubmitCheck(true);
    const formatDate = formatCollectionBootleDate(
      papSelectStatus,
      HPVSelectStatus,
      pickDropSelectStatus,
      smearSelectStatus,
      gender,
      allValue,
      formValue,
    );
    // TODO getAnswerArr需要调整
    const answerArr = getAnswerArr(papSelectStatus, HPVSelectStatus, pickDropSelectStatus, smearSelectStatus, gender);

    if (answerArr.length === 0) {
      // TODO展示错误弹窗
      alertErrorReminder();
      return;
    }
    let notFill = true;

    answerArr.map((id) => {
      if (isHpvStudy && (id === DISAGREE_FIRST_POSITION - 5 || id === DISAGREE_FIRST_POSITION - 6)) {
        return;
      }

      // ①如果是表单的提交日期“Form submission date”，则不进行校验，跳过本次循环
      // ②邮箱也为非必填项
      // ③DISAGREE_FIRST_POSITION - 3也为非必填项
      if (
        [
          COMMIT_TIME_POSITION,
          '' + COMMIT_TIME_POSITION,
          4,
          '4',
          DISAGREE_FIRST_POSITION - 3,
          '' + (DISAGREE_FIRST_POSITION - 3),
        ].includes(id)
      ) {
        return;
      }

      // 针对[DISAGREE_FIRST_POSITION - 6]，至少选择两项
      if (id === DISAGREE_FIRST_POSITION - 6) {
        if (formValue[id as keyof typeof formValue].length < 2) {
          notFill = false;
          return;
        } else if (formValue[id as keyof typeof formValue].length >= 3) {
          // 如果有三个,则肯定符合至少有两个的条件
          return;
        } else if (formValue[id as keyof typeof formValue].length === 2) {
          // 过滤掉为空的状态
          let newValueArr = whyPurchase.value.filter((item: any) => item !== '');
          // 代表着textArea的值为空
          if (newValueArr.length < 2) {
            notFill = false;
            return;
          } else {
            return;
          }
        }
      }

      // 代表着不存在这个值【代表值还没填写】
      if (
        typeof formValue[id as keyof typeof formValue] == 'string' &&
        formValue[id as keyof typeof formValue].length === 0
      ) {
        notFill = false;
        return;
      }
    });
    if (!notFill) {
      alertErrorReminder();
      return;
    } else {
      // 代表着提交成功
      try {
        const submitTimeFormatDate = {
          ...formatDate,
        };
        const defaultValue = getDefaultCollectionBootleDate(getAllValue(t));
        submitTimeFormatDate[COMMIT_TIME_POSITION as keyof typeof defaultValue].answer =
          dayjs().format('YYYY/MM/DD hh:mma') + '';

        // TODO: 这里也需要值，从BE获取的值
        // TODO: 这里需要测试一下，是否成功
        // TODO: 感觉是这里发生了error=>需要再看一下=>解决一下bug
        if (isCharitableOrganization) {
          // TODO: ngo code需要从BE获取
          submitTimeFormatDate[(DISAGREE_FIRST_POSITION - 3) as keyof typeof defaultValue].answer = ngoCode;

          // TODO：
        }

        // console.log(formatDate, 'formatDate');
        // console.log(submitTimeFormatDate, 'submitTimeFormatDate');

        const isPick =
          pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]];

        let cnPostion;

        // 处理慈善组织的position
        if (isCharitableOrganization) {
          Array.isArray(charitableDate) &&
            charitableDate.map((obj: any) => {
              if (dropLocation === `${obj?.ngoEnName}, ${obj?.dropOffEnAddress}`) {
                cnPostion = `${obj?.ngoCnName}：${obj?.dropOffCnAddress}`;
              }
            });
        } else {
          cnPostion = dropLocationCn;
        }

        const params = {
          hpvInfo: {
            sampleId: handleLocalStorage.get(website.collection_bootle_id) || '',
            data: JSON.stringify(submitTimeFormatDate),
          },
          isDebug: false,
          userName: isHpvStudy ? LastName : fullName,
          dateTime: isPick ? pickUpDate : dropOffDate,
          // 区分一下中英文
          position: isPick ? addressTextArea : dropLocation,
          cnPosition: isPick ? addressTextArea : cnPostion,
        };

        // TODO: 先隐藏payment的功能=>后续等后端迁移完，再加新的功能
        // 如果是pickup，则需要到支付页面
        if (isPick) {
          sessionStorage.setItem(website.payment_submit_data, JSON.stringify(params));
          sessionStorage.setItem(website.is_user_pick_up_submit, 'true');
          setIsPickUpNeedPayment(true);
          if (!isHpvStudy && !isHpv12) {
            // 到达支付页面
            setIsOnPayment(true);
            return;
          } else if (isHpv12) {
            setCurPage(CurPage[CurPage.UpgradeTest] as keyof typeof CurPage);
            return;
          }
        } else if (isHpv12 && !isForthStep) {
          setIsPickUpNeedPayment(false);
          sessionStorage.setItem(website.payment_submit_data, JSON.stringify(params));
          setCurPage(CurPage[CurPage.UpgradeTest] as keyof typeof CurPage);
          return;
        }

        await post('/hpv/add', params);
        navigate('/collectionSuccess');
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage =
          (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || t('collectionBootle.error');
        message.error(errorMessage);
      }
      // alertSuccessReminder();
    }

    // TODO 提交至BE，提交之前，先检查有没有报错信息
    // TODO 需要进行校验
    // setCurPage(CurPage[CurPage.Delivery] as keyof typeof CurPage);
  };

  const handleLastPapChange = (value: string) => {
    setLastPap(value);
    setFormValue({
      ...formValue,
      12: value,
    });
    // console.log(`selected ${value}`);
  };

  const onWhyPurchaseTextAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setWhyPurchaseTextArea(val);
    const newOption = enLocales.collectionBootle.question24Select13;
    // 如果idtTextArea没值，则默认为空值
    let filterNewValueArr = [];
    let newValueArr: string[] = [];
    if (whyPurchase.option.includes(newOption)) {
      // 清除之前值
      filterNewValueArr = whyPurchase.value.filter((item: any) => item !== whyPurchaseTextArea);
      // 加入改变之后的值
      newValueArr = [...filterNewValueArr, val];
    }
    setWhyPurchase({
      ...whyPurchase,
      value: newValueArr,
    });
    setFormValue({
      ...formValue,
      [DISAGREE_FIRST_POSITION - 6]: newValueArr,
    });
  };

  const onWhereLearnTextAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setWhereLearnTextArea(val);
    setWhereLearn({
      ...whereLearn,
      value: val,
    });
    setFormValue({
      ...formValue,
      [DISAGREE_FIRST_POSITION - 5]: val,
    });
  };

  const onIdtTextAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setIdtTextArea(val);
    setIdt({
      ...idt,
      value: val,
    });
    setFormValue({
      ...formValue,
      7: val,
    });
  };

  // textAreaChange能change，代表着disable是true的
  const onPapResultTextAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setPapResultTextArea(val);
    setPapResult({
      ...papResult,
      value: val,
    });
    setFormValue({
      ...formValue,
      14: val,
    });
  };

  // const onSpecifyOthersTextAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const val = e.target.value;
  //   setSpecifyOthersTextArea(val);
  //   setFormValue({
  //     ...formValue,
  //     14: val,
  //   });
  // };

  // textAreaChange能change，代表着disable是true的
  const onHpvResultTextAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = e.target.value;
    setHpvResultTextArea(val);

    // const removeArr = removeHpvNotDefaultValue(hpvResult?.value);

    // let newValueArr = removeArr;
    // newValueArr.push(val);
    // const papResultTextArea=
    setHpvResult({
      ...hpvResult,
      value: val,
    });
    setFormValue({
      ...formValue,
      16: val,
    });
  };

  const onFullNameChange = (val: string) => {
    const newValue = val;
    setFullName(newValue);
    setFormValue({
      ...formValue,
      18: newValue,
    });
  };

  const onBlurFullName = () => {
    setFullName((pre: any) => pre.toUpperCase());
    setFormValue((pre: any) => {
      return {
        ...pre,
        18: pre[18].toUpperCase(),
      };
    });
  };

  useEffect(() => {
    setPapSelectStatus(null);
    setHPVSelectStatus(null);
    setPickDropSelectStatus(null);
    setCurPage(CurPage[CurPage.PersonalInfo] as keyof typeof CurPage);
    // 初始化
    // setCurPage(CurPage[CurPage.UpgradeTest] as keyof typeof CurPage);
  }, []);

  useEffect(() => {
    setAllValue(getAllValue(t));
  }, [t]);

  useEffect(() => {
    if (pickTime && pickTime.length > 0 && pickUpDate && pickUpDate.length > 0) {
      setLimit24Hour({
        limitDate: pickUpDate,
        limitTime: pickTime.slice(6),
      });
      setForceShowSampleCollectionDate(false);
      setTimeout(() => {
        setForceShowSampleCollectionDate(true);
      }, 1000);
    } else {
      setLimit24Hour(null);
    }
  }, [pickTime, pickUpDate]);

  useEffect(() => {
    if (pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]) {
      // 如果都是当天时间，那就没什么限制
      if (limit24Hour?.limitDate === sampleCollectionDate) {
        setDisabledTime(undefined);
      } else if (
        limit24Hour &&
        sampleCollectionDate &&
        dayjs(limit24Hour?.limitDate, 'YYYY/MM/DD').subtract(1, 'day').format('YYYY/MM/DD') === sampleCollectionDate
      ) {
        let limitHourArr: number[] = [];
        const time = parseInt(limit24Hour?.limitTime);
        for (let i = 0; i < time; i++) {
          limitHourArr.push(i);
        }
        setDisabledTime({
          disabledHours: () => limitHourArr,
          disabledMinutes: () => [],
          // disabledSeconds: () => [],
        });
      } else {
        setDisabledTime(undefined);
      }
    } else {
      setDisabledTime(undefined);
    }
  }, [limit24Hour, sampleCollectionDate, pickDropSelectStatus, t]);

  useEffect(() => {
    if (!handleLocalStorage.get(website.is_sign_agreements)) {
      const bottleId = handleLocalStorage.get(website.collection_bootle_id) || '0';
      navigate(`/build/${bottleId}`);
    }
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.can_resend_after}`)) {
      // setTxt(count === 0 ? '重新发送' : `重新发送 (${count}s)`);
      setTxt(
        count === 0
          ? (t('collectionBootle.ResendOTP') as string)
          : (t('collectionBootle.ResendCount', { count: count }) as string),
      );
    } else {
      // 获取验证码
      setTxt(t('collectionBootle.SendOTP') as string);
    }
  }, [count, t]);

  useEffect(() => {
    console.log(isClickFirstNext, 'isClickFirstNext');
    if (forceFirstSubmitShowError(isClickSendOtp)) {
      setOtpButtonStatus('emptyError');
    } else if (count === 0 && isPhoneNumberValid) {
      setOtpButtonStatus('check');
    } else {
      setOtpButtonStatus('disabled');
    }
  }, [count, isPhoneNumberValid, isClickSendOtp, forceFirstSubmitShowError, isClickFirstNext]);

  useEffect(() => {
    const getCharitableDate = async () => {
      try {
        const res = await get('/hpv/getNgoInfo', {
          sampleId: handleLocalStorage.get(website.collection_bootle_id || '0'),
        });

        if (res?.isExist === false) {
          setIsCharitableOrganization(false);
        } else {
          setIsCharitableOrganization(true);
          setPickDropSelectStatus(PickDropSelectType[PickDropSelectType.DropOff] as keyof typeof PickDropSelectType);
        }

        setCharitableDate(res?.ngoInfosList);
      } catch (err: any) {
        setIsCharitableOrganization(false);
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(errorMessage);
      }
    };

    getCharitableDate();
  }, []);
  useEffect(() => {
    const bottleId = handleLocalStorage.get(website.collection_bootle_id) || '0';
    const gotoAgreement = () => {
      navigate(`/agreements/${bottleId}`);
    };

    const checkBottleId = async () => {
      // 代表着没有值
      if (bottleId === '0') {
        gotoAgreement();
        return;
      }

      // const bottleId = handleLocalStorage.get(website.collection_bootle_id);
      try {
        let checkPath = '/hpv/isSampleIdExist';
        if (newVersion.find((v) => window.location.origin.includes(v))) {
          checkPath = '/hpv/isSampleIdExistV2';
        }

        const beValue = await get(checkPath, {
          sampleId: bottleId,
        });

        if (beValue?.isExist === true) {
          gotoAgreement();
        }
        // console.log(beValue, 'beValue');

        // let newBeValue: any = [];
        // if (Array.isArray(beValue) && beValue.length > 0) {
        //   beValue.map((str) => {
        //     newBeValue.push(JSON.parse(str));
        //   });
        // }
        // const csvValue = formatCNExcelDate(newBeValue);
        // setData(csvValue);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(errorMessage);
        // gotoAgreement();
      }
    };

    checkBottleId();
  }, [navigate]);

  useEffect(() => {
    getTimeOptionValue();
  }, []);

  useEffect(() => {
    // 代表用户已经填过了，就算用户刷新了也还是在payments页面
    const p = sessionStorage.getItem(website.payment_submit_data);
    if (
      (p && sessionStorage.getItem(website.is_user_pick_up_submit)) ||
      (p && sessionStorage.getItem(website.is_user_want_to_upgrade))
    ) {
      const pJson = JSON.parse(p);
      try {
        if (!(pJson.hpvInfo.sampleId as string)?.startsWith('0113')) {
          setIsOnPayment(true);
        }

        if (sessionStorage.getItem(website.is_user_want_to_upgrade)) {
          setIsNeedUpgrade(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    let val = '';
    if (pAddress && pAddressRegion && pAddressCountry && pAddressDistrict) {
      val = `${
        pApartmentAddress ? `${pApartmentAddress},` : ''
      }${pAddress},${pAddressDistrict},${pAddressRegion},${pAddressCountry}`;
    } else {
      val = '';
    }

    setAddressTextArea(val);
    setFormValue((pre: any) => {
      return {
        ...pre,
        23: val,
      };
    });
  }, [pAddress, pAddressCountry, pAddressDistrict, pAddressRegion, pApartmentAddress]);

  useEffect(() => {
    let newValue = '';
    if (idt?.option === enLocales.collectionBootle.question7HK) {
      newValue = t('collectionBootle.question8Placeholder');
    } else if (idt?.option === enLocales.collectionBootle.question7ID) {
      newValue = t('collectionBootle.question8Placeholder2');
    } else if (idt?.option === t('collectionBootle.question7Others')) {
      newValue = t('collectionBootle.question8Placeholder3');
    } else {
      newValue = t('collectionBootle.question8Placeholder');
    }
    setIdtPlaceholder(newValue as string);
  }, [idt?.option, t]);

  useEffect(() => {
    setFormValue((pre: any) => {
      return {
        ...pre,
        [DISAGREE_FIRST_POSITION - 1]: dropOffDate,
      };
    });
  }, [dropOffDate]);

  useEffect(() => {
    // 遍历option的选项
    let traversalPosition = 0;
    // 代表组别
    let group = 0;
    if (pAddressRegion) {
      if (pAddressRegion === enLocales.collectionBootle.question20RegionSelect1) {
        traversalPosition = 4;
        group = 1;
      } else if (pAddressRegion === enLocales.collectionBootle.question20RegionSelect2) {
        traversalPosition = 5;
        group = 2;
      } else if (pAddressRegion === enLocales.collectionBootle.question20RegionSelect3) {
        traversalPosition = 8;
        group = 3;
      }
      let arr: { label: string; value: string }[] = [];
      for (let i = 1; i <= traversalPosition; i++) {
        let obj: { label: string; value: string } = {
          label: t(`collectionBootle.region${group}District${i}`),
          value: enLocales.collectionBootle[
            `region${group}District${i}` as keyof typeof enLocales.collectionBootle
          ] as string,
        };
        arr.push(obj);
      }
      setPAddressDistrictOption(arr);
    } else {
      setPAddressDistrictOption(undefined);
    }
  }, [pAddressRegion, t]);

  useEffect(() => {
    const getDayOptionValue = () => {
      const options = [];
      let limitDay = 31;
      if (!isEmptyValue(dobMonth) && !isEmptyValue(dobYear)) {
        limitDay = new Date(Number(dobYear), Number(dobMonth), 0).getDate();
      }
      for (let day = 1; day <= limitDay; day++) {
        // 填充到长度2
        const dayString = day.toString().padStart(2, '0');
        options.push({
          label: dayString,
          value: dayString,
        });
      }
      setDayOptionValue(options);
    };
    getDayOptionValue();
  }, [dobMonth, dobYear]);
  useEffect(() => {
    getMonthOptionValue();
  }, []);
  useEffect(() => {
    getYearOptionValue();
  }, []);
  useEffect(() => {
    if (['success', false].includes(dobCheck)) {
      setIsShowDobCheckError(false);
    } else {
      setIsShowDobCheckError(true);
    }
  }, [dobCheck]);
  useEffect(() => {
    if (['success', false].includes(dobCheck)) {
      setIsShowDobCheckError(false);
    } else {
      setIsShowDobCheckError(true);
    }
  }, [dobCheck]);
  useEffect(() => {
    if (forceDayClear === 0) {
      return;
    } else {
      let timer1 = setTimeout(() => {
        setDobDay('');
      }, 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [forceDayClear]);

  // TODO 把序号减一就好了，问题不是很大
  const renderHpvList = () => {
    if (HPVSelectStatus === HPVSelectType[HPVSelectType['Yes' as keyof typeof HPVSelectType]]) {
      const selectHPVYesValue =
        allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType]
          .questionFifteen?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType];
      return (
        <div className="flex flex-col">
          {/* What was the result of your last HPV test? */}
          <div className="flex flex-col w-[100%] mt-[29px]  lg:mt-[45px]">
            {/*   TODO 换行的问题=>需要再看一下 */}
            <FiledTitle
              title={`${selectHPVYesValue?.questionSixteen?.questionDesc?.EN}`}
              style={{
                marginBottom: isLg ? '8px' : '0.1951rem',
              }}
            />
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]   md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
              value={t('collectionBootle.question14S1')}
              status={
                forceSecondSubmitShowError(hpvResult?.option)
                  ? 'emptyError'
                  : hpvResult?.value === enLocales.collectionBootle.question14S1
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                const newValue = enLocales.collectionBootle.question14S1;
                setHpvResult({
                  option: newValue,
                  value: newValue,
                });
                setFormValue({
                  ...formValue,
                  16: newValue,
                });
              }}
            ></CollectionBootleButton>

            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]   md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
              value={t('collectionBootle.question14S2')}
              status={
                forceSecondSubmitShowError(hpvResult?.option)
                  ? 'emptyError'
                  : hpvResult?.option === enLocales.collectionBootle.question14S2
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                // 如果hpvResultTextArea没值，则默认为空值
                const newOption = enLocales.collectionBootle.question14S2;
                const newValue = isAgreeHpvNotSure
                  ? enLocales.collectionBootle.question14BelowMessageCheckbox
                  : hpvResultTextArea || '';
                setHpvResult({
                  option: newOption,
                  value: newValue,
                });
                setFormValue({
                  ...formValue,
                  16: newValue,
                });
              }}
            ></CollectionBootleButton>
            {hpvResult?.option === enLocales.collectionBootle.question14S2 && (
              <div className="mb-[14px] font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px] md:text-[14px] md:leading-[20px] md:mt-[0px] md:mb-[14px]">
                {t('collectionBootle.question14BelowMessage')}
              </div>
            )}
            {hpvResult?.option === enLocales.collectionBootle.question14S2 && (
              <>
                <BaseTextArea
                  placeholder={t('collectionBootle.question14EnterPlaceholder') as string}
                  disable={!(hpvResult?.option === enLocales.collectionBootle.question14S2) || isAgreeHpvNotSure}
                  defaultValue={hpvResultTextArea}
                  onChange={onHpvResultTextAreaChange}
                  collectionDatePickerClassName={classNames(
                    isSecondSubmitCheck &&
                      (!hpvResult?.value || (hpvResult?.value && hpvResult?.value.length === 0)) &&
                      'my-select-selector-empty-type',
                    'mb-[18px]  md:mb-[18px]',
                  )}
                ></BaseTextArea>
                <div className="flex items-center  mb-[28px] lg:mb-[20px]">
                  <BaseCheckbox
                    // id="isAgreeHpvNotSure"
                    checked={isAgreeHpvNotSure}
                    onClick={() => {
                      const val = enLocales.collectionBootle.question14BelowMessageCheckbox;
                      setIsAgreeHpvNotSure(!isAgreeHpvNotSure);

                      // 代表着当前为true
                      if (!isAgreeHpvNotSure) {
                        setHpvResult({
                          ...hpvResult,
                          value: val,
                        });
                        setFormValue({
                          ...formValue,
                          16: val,
                        });
                      } else {
                        const textAreaValue = hpvResultTextArea;
                        setHpvResultTextArea(textAreaValue);

                        setHpvResult({
                          ...hpvResult,
                          value: textAreaValue,
                        });
                        setFormValue({
                          ...formValue,
                          16: textAreaValue,
                        });
                      }
                    }}
                    className="mt-[0px!important] md:mt-[0px!important]"
                  ></BaseCheckbox>
                  <div className="font-[400] text-[#25282B] text-[14px] tracking-[0.4px] md:text-[14px] md:font-[500] ml-[16px] md:ml-[16px]">
                    {t('collectionBootle.question14BelowMessageCheckbox')}
                  </div>
                </div>
              </>
            )}
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]   md:w-[26.66vw] md:h-[38px] md:text-[14px]  md:mb-[18px]"
              value={t('collectionBootle.question14S3')}
              status={
                forceSecondSubmitShowError(hpvResult?.option)
                  ? 'emptyError'
                  : hpvResult?.value === enLocales.collectionBootle.question14S3
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                const newValue = enLocales.collectionBootle.question14S3;
                setHpvResult({
                  option: newValue,
                  value: newValue,
                });
                setFormValue({
                  ...formValue,
                  16: newValue,
                });
              }}
            ></CollectionBootleButton>
            {forceSecondSubmitShowError(hpvResult?.option) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceSecondSubmitShowError(hpvResult?.option) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '0px' : '0px',
                  position: 'relative',
                  top: isLg ? '-10px' : '-0.1951rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
          </div>
        </div>
      );
    } else if (HPVSelectStatus === HPVSelectType[HPVSelectType['No' as keyof typeof HPVSelectType]]) {
      return null;
    } else {
      return null;
    }
  };

  {
    /* 问题15：Have you been tested for HPV?  */
  }
  const isTestHpvComponent = () => {
    const selectPapYesValue =
      allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType];
    const selectHPVYesValue =
      allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType].questionFifteen
        ?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType];
    const fullNameTitle = `${selectHPVYesValue?.questionEighteen?.questionDesc?.EN}`;

    return (
      <div className="flex flex-col w-[100%] mt-[29px] lg:mt-[45px]">
        <FiledTitle title={`${selectPapYesValue?.questionFifteen?.questionDesc?.EN}`} />
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
          value={HPVSelectType[HPVSelectType[t('collectionBootle.Yes') as keyof typeof HPVSelectType]]}
          status={
            forceSecondSubmitShowError(HPVSelectStatus)
              ? 'emptyError'
              : HPVSelectStatus === HPVSelectType[HPVSelectType['Yes' as keyof typeof HPVSelectType]]
              ? 'check'
              : 'unchecked'
          }
          onClick={() => {
            const val = HPVSelectType[HPVSelectType['Yes' as keyof typeof HPVSelectType]];
            setHPVSelectStatus(val as keyof typeof HPVSelectType);
            setFormValue({
              ...formValue,
              15: val,
            });
          }}
        ></CollectionBootleButton>
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[38px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
          value={HPVSelectType[HPVSelectType[t('collectionBootle.No') as keyof typeof HPVSelectType]]}
          status={
            forceSecondSubmitShowError(HPVSelectStatus)
              ? 'emptyError'
              : HPVSelectStatus === HPVSelectType[HPVSelectType['No' as keyof typeof HPVSelectType]]
              ? 'check'
              : 'unchecked'
          }
          onClick={() => {
            const val = HPVSelectType[HPVSelectType['No' as keyof typeof HPVSelectType]];
            setHPVSelectStatus(val as keyof typeof HPVSelectType);
            setFormValue({
              ...formValue,
              15: val,
            });
          }}
        ></CollectionBootleButton>
        {forceSecondSubmitShowError(HPVSelectStatus) && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={forceSecondSubmitShowError(HPVSelectStatus) ? 'error' : undefined}
            style={{
              marginTop: isLg ? '0px' : '0.0976rem',
              position: 'relative',
              top: isLg ? '-10px' : '0px',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
        {renderHpvList()}
        <RenderVaccinastionSelect
          isSecondSubmitCheck={isSecondSubmitCheck}
          formValue={formValue}
          setFormValue={setFormValue}
        />
        {/* fullName */}
        {HPVSelectStatus && (
          <>
            <div
              className="flex cursor-pointer"
              style={{
                marginTop: isLg ? '29px' : '0.7073rem',
                marginBottom: isLg ? '0px' : '0rem',
              }}
              onClick={() => {
                setUndersignedBoxValue((pre) => !pre);
              }}
            >
              <BaseCheckbox
                onChange={onUndersignedBoxChange}
                style={{
                  marginRight: isLg ? '16px' : '0.3902rem',
                }}
                checked={undersignedBoxValue}
              />
              <FiledTitle
                title={t('collectionBootle.FullNameInfo1') as string}
                style={{
                  marginTop: isLg ? '0px' : '0px',
                  marginBottom: isLg ? '0px' : '0px',
                }}
              />
            </div>
            <br />
            <div
              className="flex cursor-pointer"
              style={{
                // marginTop: isLg ? '29px' : '0.7073rem',
                marginBottom: isLg ? '0px' : '0rem',
              }}
              onClick={() => {
                setMiddleSignedBoxValue((pre) => !pre);
              }}
            >
              <BaseCheckbox
                onChange={onMiddleSignedBoxChange}
                style={{
                  marginRight: isLg ? '16px' : '0.3902rem',
                }}
                checked={middleSignedBoxValue}
              />
              <FiledTitle
                title={t('collectionBootle.FullNameInfoMiddle') as string}
                style={{
                  marginTop: isLg ? '0px' : '0px',
                  marginBottom: isLg ? '0px' : '0px',
                }}
              />
            </div>
            <br />
            <div
              className="flex cursor-pointer"
              onClick={() => {
                // if(isLowVersion){

                // }
                setReadBoxValue((pre) => !pre);
              }}
            >
              <BaseCheckbox
                onChange={onReadBoxChange}
                style={{
                  marginRight: isLg ? '16px' : '0.3902rem',
                }}
                checked={readBoxValue}
              />
              <FiledTitle
                title={t('collectionBootle.FullNameInfo2') as string}
                style={{
                  marginBottom: isLg ? '0px' : '0rem',
                }}
              />
            </div>

            <FiledInput
              value={fullName}
              onChange={onFullNameChange}
              title={fullNameTitle}
              placeholder={t('collectionBootle.question15Placeholder') as string}
              filedInputClassName="mt-[15px] lg:mt-[15px]"
              defaultValue={fullName}
              // forceUppercase={true}
              onCheckValid={handleCheckFullName}
              isSubmitCheck={isSecondSubmitCheck}
              isFullName={true}
              onBlur={onBlurFullName}
            />
          </>
        )}
      </div>
    );
  };

  {
    /* Choose your drop-off locations */
  }
  const dropOffLocations = () => {
    const selectPickYesValue =
      allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType].questionFifteen
        ?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType].questionNineteen?.select?.[
        PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType
      ];

    const isPickUp =
      pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]];

    const showSampleCollectionDate =
      (isPickUp &&
        pickUpDate &&
        pickUpDate.length > 0 &&
        pickTime &&
        pickTime.length > 0 &&
        forceShowSampleCollectionDate) ||
      (pickDropSelectStatus === PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]] &&
        dropOffDate &&
        dropOffDate.length > 0);

    const showSampleCollectionTime =
      (forceShowSampleCollectionTime && showSampleCollectionDate && sampleCollectionDate) ||
      (pickDropSelectStatus === PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]] &&
        dropOffDate &&
        dropOffDate.length > 0);

    return (
      <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[0px]">
        {/* Choose your drop-off locations这一项 */}
        {pickDropSelectStatus ===
          PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]] && (
          <>
            {/* TODO: drop off time 开始*/}
            {isLowVersion ? (
              <div
                className="flex flex-col mb-[29px] lg:mb-[45px]"
                style={{
                  marginTop:
                    pickDropSelectStatus ===
                    PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                      ? '0px'
                      : isHpvStudy
                      ? '30px'
                      : undefined,
                }}
              >
                <FiledTitle title={`${allValue?.labNewQuestionMinus4?.questionDesc?.EN}`} />
                <div className="flex justify-between">
                  <CnFiledInput
                    style={{
                      width: '30%',
                      margin: isLg ? '0px' : '0px',
                    }}
                    value={dropOffDay}
                    onChange={onDropOffDayChange}
                    placeholder={t('collectionBootle.DatePlaceHolder')}
                    onCheckValid={handleCheckDropOffDay}
                    isSubmitCheck={isThirdSubmitCheck}
                    number={true}
                  />
                  <CnFiledInput
                    style={{
                      width: '30%',
                      margin: isLg ? '0px' : '0px',
                    }}
                    value={dropOffMonth}
                    onChange={onDropOffMonthChange}
                    placeholder={t('collectionBootle.MonthPlaceHolder')}
                    onCheckValid={handleCheckDropOffMonth}
                    isSubmitCheck={isThirdSubmitCheck}
                    number={true}
                  />
                  <CnFiledInput
                    style={{
                      width: '30%',
                      margin: isLg ? '0px' : '0px',
                    }}
                    value={dropOffYear}
                    onChange={onDropOffYearChange}
                    placeholder={t('collectionBootle.YearPlaceHolder')}
                    onCheckValid={handleCheckDropOffYear}
                    isSubmitCheck={isThirdSubmitCheck}
                    number={true}
                  />
                </div>
              </div>
            ) : (
              <CollectionDatePicker
                title={`${allValue?.labNewQuestionMinus4?.questionDesc?.EN}`}
                onChange={onDropOffDateChange}
                style={{
                  marginTop:
                    pickDropSelectStatus ===
                    PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                      ? '0px'
                      : isHpvStudy
                      ? '30px'
                      : undefined,
                }}
                placeholder={t('collectionBootle.question6Placeholder') as string}
                defaultValue={dropOffDate}
                forceDisabledDate={true}
                collectionDatePickerClassName={classNames(
                  forceThirdSubmitShowError(dropOffDate) && 'my-select-selector-empty-type',
                  'mb-[29px] lg:mb-[45px]',
                )}
                disabledStartDate={dayjs().subtract(1, 'day').format('YYYY/MM/DD')}
                disabledEndDate={getSampleExpireDate() || dayjs('2100/01/01').format('YYYY/MM/DD')}
                isDropOffTime={true}
              />
            )}

            {forceThirdSubmitShowError(dropOffDate) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceThirdSubmitShowError(dropOffDate) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '11px' : '0.2683rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
            {/* TODO: drop off time 结束 */}
            {!isEmptyValue(dropOffDate) && (
              <FiledTitle title={`${selectPickYesValue?.questionTwentyTwo?.questionDesc?.EN}`} />
            )}

            {isCharitableOrganization ? (
              <>
                {Array.isArray(charitableDate) &&
                  charitableDate.map((obj: any) => {
                    return (
                      <CollectionBootleButton
                        htmlType="submit"
                        buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
                        // value={`${t('collectionBootle.locations1')}${t('collectionBootle.locations1Time')}`}
                        value={
                          isZhHKLanguage
                            ? `${obj?.ngoCnName}：${obj?.dropOffCnAddress}`
                            : `${obj?.ngoEnName}, ${obj?.dropOffEnAddress}`
                        }
                        status={
                          forceThirdSubmitShowError(dropLocation)
                            ? 'emptyError'
                            : dropLocation === `${obj?.ngoEnName}, ${obj?.dropOffEnAddress}`
                            ? 'check'
                            : 'unchecked'
                        }
                        onClick={() => {
                          console.log(obj?.dropOffCnAddress, 'obj?.dropOffCnAddress');
                          const val = `${obj?.ngoEnName}, ${obj?.dropOffEnAddress}`;
                          setDropLocation(val);
                          setFormValue({
                            ...formValue,
                            22: val,
                          });
                          setNgoCode(obj?.ngoCode);
                        }}
                        style={{
                          // TODO: 后续可能需要调整
                          height: isLg ? 'auto' : 'auto',
                          // height: isLg ? '220px' : '7.3171rem',
                          whiteSpace: 'pre-wrap',
                          width: isLg ? '26.66vw' : '100%',
                        }}
                      ></CollectionBootleButton>
                    );
                  })}
              </>
            ) : isEmptyValue(dropOffDate) ? null : (
              renderDropOffList({
                dropOffDate,
                dropLocation,
                isLg,
                setFormValue,
                formValue,
                t,
                setDropLocation,
                setDropLocationCn,
                forceThirdSubmitShowError,
                hiddenLocationTime,
              })
            )}

            {forceThirdSubmitShowError(dropLocation) && !isEmptyValue(dropOffDate) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceThirdSubmitShowError(dropLocation) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '0px' : '0px',
                  position: 'relative',
                  top: isLg ? '-15px' : '-0.3659rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
            <p
              className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
              style={{
                marginTop: isLg ? '45px' : '0.7073rem',
                marginBottom: isLg ? '0px' : '0rem',
              }}
            >
              {t('collectionBootle.question17InfoTitle')}
            </p>
            <FiledTitle
              title={t('collectionBootle.question17InfoText')}
              style={{
                marginTop: isLg ? '29px' : '0.7073rem',
                marginBottom: isLg ? '0px' : '0rem',
              }}
            />
          </>
        )}

        {/* Sample Collection Date ( DD / MM / YYYY ) */}
        {showSampleCollectionDate && (
          <>
            {isLowVersion ? (
              <div className="flex flex-col mt-[29px] lg:mt-[45px]">
                {/* sampleCollectionLocationsTitleSerial */}
                <FiledTitle
                  style={{
                    marginTop:
                      pickDropSelectStatus ===
                      PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                        ? '0px'
                        : undefined,
                  }}
                  title={`${allValue?.questionNine?.questionDesc?.EN}`}
                />
                <div className="flex justify-between">
                  <CnFiledInput
                    style={{
                      width: '30%',
                      margin: isLg ? '0px' : '0px',
                    }}
                    value={sampleCollectionDay}
                    onChange={onSampleCollectionDayChange}
                    placeholder={t('collectionBootle.DatePlaceHolder')}
                    onCheckValid={handleCheckSampleCollectionDay}
                    isSubmitCheck={isThirdSubmitCheck}
                    number={true}
                  />
                  <CnFiledInput
                    style={{
                      width: '30%',
                      margin: isLg ? '0px' : '0px',
                    }}
                    value={sampleCollectionMonth}
                    onChange={onSampleCollectionMonthChange}
                    placeholder={t('collectionBootle.MonthPlaceHolder')}
                    onCheckValid={handleCheckSampleCollectionMonth}
                    isSubmitCheck={isThirdSubmitCheck}
                    number={true}
                  />
                  <CnFiledInput
                    style={{
                      width: '30%',
                      margin: isLg ? '0px' : '0px',
                    }}
                    value={sampleCollectionYear}
                    onChange={onSampleCollectionYearChange}
                    placeholder={t('collectionBootle.YearPlaceHolder')}
                    onCheckValid={handleCheckSampleCollectionYear}
                    isSubmitCheck={isThirdSubmitCheck}
                    number={true}
                  />
                </div>
              </div>
            ) : (
              <CollectionDatePicker
                title={`${allValue?.questionNine?.questionDesc?.EN}`}
                onChange={onSampleCollectionDateChange}
                style={{
                  marginTop:
                    pickDropSelectStatus ===
                    PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                      ? '0px'
                      : undefined,
                }}
                placeholder={t('collectionBootle.question6Placeholder') as string}
                defaultValue={sampleCollectionDate}
                forceDisabledDate={
                  true
                  // pickDropSelectStatus ===
                  // PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                }
                collectionDatePickerClassName={classNames(
                  forceThirdSubmitShowError(sampleCollectionDate) && 'my-select-selector-empty-type',
                  'mt-[29px] lg:mt-[45px]',
                )}
                // TODO 需要做格式的校验
                disabledStartDate={
                  pickDropSelectStatus ===
                  PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                    ? // 代表为Pick Up
                      limit24Hour?.limitDate
                      ? dayjs(limit24Hour?.limitDate, 'YYYY/MM/DD').subtract(2, 'day').format('YYYY/MM/DD')
                      : undefined
                    : // 代表为drop off
                    dropOffDate && dropOffDate.length > 0
                    ? dayjs(dropOffDate, 'YYYY/MM/DD').subtract(2, 'day').format('YYYY/MM/DD')
                    : undefined
                }
                // 如果是pick up，需要限制不能超过取件日期
                disabledEndDate={
                  pickDropSelectStatus ===
                  PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                    ? dayjs(limit24Hour?.limitDate, 'YYYY/MM/DD').format('YYYY/MM/DD')
                    : dayjs(dropOffDate, 'YYYY/MM/DD').format('YYYY/MM/DD')
                }
              />
            )}

            {forceThirdSubmitShowError(sampleCollectionDate) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceThirdSubmitShowError(sampleCollectionDate) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '11px' : '0.2683rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
          </>
        )}
        {/* TODO 时间也需要进行限制 */}
        {/* Sample Collection Time ( HH : MM )  */}
        {showSampleCollectionTime && (
          <div
            className={classNames(
              forceThirdSubmitShowError(sampleCollectionTime) && 'my-select-selector-empty-type',
              'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[46px] lg:mt-[45px] lg:mb-[59px]',
            )}
          >
            <FiledTitle title={`${allValue?.questionTen?.questionDesc?.EN}`} />
            <Select
              onChange={onSampleCollectionTimeChange}
              style={{
                height: isLg ? '48px' : '1.1707rem',
                color: '#8B849B',
                fontWeight: 400,
                fontSize: isLg ? '14px' : '0.3415rem',
                lineHeight: isLg ? '24px' : '0.5854rem',
              }}
              placeholder={t('collectionBootle.question22Placeholder') as string}
              defaultValue={sampleCollectionTime || undefined}
              value={sampleCollectionTime || undefined}
              // inputReadOnly={true}
              options={timeOptionValue}
            ></Select>

            {forceThirdSubmitShowError(sampleCollectionTime) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceThirdSubmitShowError(sampleCollectionTime) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '11px' : '0.2683rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
            {showSampleCollectionTime && !isHpvStudy && (
              <>
                <p
                  className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] mt-[40px] lg:mt-[45px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
                  // style={{
                  //   marginTop: isLg ? '0px' : '0rem',
                  //   marginBottom: isLg ? '0px' : '0rem',
                  // }}
                >
                  {t('collectionBootle.otherInformation')}
                </p>
                {/* Where did you learn about the INDICAID HPV Urine Test? */}
                <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
                  <FiledTitle title={`${t('collectionBootle.question23')}`} />
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full  mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select1')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select1
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select1;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select2')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select2
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select2;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select3')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select3
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select3;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select4')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select4
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select4;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select5')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select5
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select5;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select6')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select6
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select6;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question23Select7')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === enLocales.collectionBootle.question23Select7
                        ? 'check'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question23Select7;
                      setWhereLearn({
                        option: newValue,
                        value: newValue,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  {/* TODO: other这里也需要填写 */}
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full  mb-[0px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[0px]"
                    value={t('collectionBootle.question23Select8')}
                    status={
                      forceThirdSubmitShowError(whereLearn?.option)
                        ? 'emptyError'
                        : whereLearn?.option === t('collectionBootle.question23Select8')
                        ? 'check'
                        : 'unchecked'
                    }
                    // status={idt === 'Passport' ? 'check' : 'unchecked'}
                    onClick={() => {
                      const newOption = t('collectionBootle.question23Select8');
                      // 如果idtTextArea没值，则默认为空值
                      const newValue = whereLearnTextArea || '';
                      setWhereLearn({
                        option: newOption,
                        value: newValue,
                      });
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 5]: newValue,
                      });
                    }}
                  ></CollectionBootleButton>
                  {whereLearn?.option === t('collectionBootle.question23Select8') && (
                    <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[26px]">
                      {t('collectionBootle.PleaseSpecify')}
                    </div>
                  )}
                  {whereLearn?.option === t('collectionBootle.question23Select8') && (
                    <BaseTextArea
                      placeholder={t('collectionBootle.EnterTypeMessage') as string}
                      disable={!(whereLearn?.option === t('collectionBootle.question23Select8'))}
                      defaultValue={whereLearnTextArea}
                      onChange={onWhereLearnTextAreaChange}
                      collectionDatePickerClassName={classNames(
                        isThirdSubmitCheck &&
                          (!whereLearn?.value || (whereLearn?.value && whereLearn?.value.length === 0)) &&
                          'my-select-selector-empty-type',
                      )}
                    ></BaseTextArea>
                  )}
                  {forceThirdSubmitShowError(whereLearn?.option) && (
                    <MessageText
                      helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                      status={forceThirdSubmitShowError(whereLearn?.option) ? 'error' : undefined}
                      style={{
                        marginTop: isLg ? '11px' : '0.2683rem',
                      }}
                      innerStyle={{
                        justifyContent: 'flex-start',
                        marginBottom: '0px',
                      }}
                    ></MessageText>
                  )}
                </div>
                {/* Where did you learn about the INDICAID HPV Urine Test? */}
                <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
                  <FiledTitle title={`${t('collectionBootle.question24')}`} />
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select1')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select1)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select1;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select2')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select2)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select2;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select3')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select3)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select3;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select4')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select4)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select4;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select5')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select5)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select5;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select6')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select6)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select6;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select7')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select7)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select7;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select8')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select8)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select8;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select9')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select9)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select9;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select10')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select10)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select10;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select11')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select11)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select11;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                    value={t('collectionBootle.question24Select12')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select12)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    onClick={() => {
                      const newValue = enLocales.collectionBootle.question24Select12;
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newValue)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newValue);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newValue];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      // 清空之前的表单
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  <CollectionBootleButton
                    style={{
                      height: isLg ? '90px' : '1.9024rem',
                      whiteSpace: 'pre-wrap',
                    }}
                    htmlType="submit"
                    buttonClassName="w-full h-[38px] mb-[0px]  lg:w-[26.66vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[0px]"
                    value={t('collectionBootle.question24Select13')}
                    status={
                      whyPurchase?.option.includes(enLocales.collectionBootle.question24Select13)
                        ? 'check'
                        : forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                        ? 'emptyError'
                        : 'unchecked'
                    }
                    // status={idt === 'Passport' ? 'check' : 'unchecked'}
                    onClick={() => {
                      const newOption = enLocales.collectionBootle.question24Select13;
                      // 如果idtTextArea没值，则默认为空值
                      const newValue = whyPurchaseTextArea || '';
                      let newOptionArr = [];
                      let newValueArr = [];
                      if (whyPurchase.option.includes(newOption)) {
                        newOptionArr = whyPurchase.option.filter((item: any) => item !== newOption);
                        newValueArr = whyPurchase.value.filter((item: any) => item !== newValue);
                      } else {
                        newOptionArr = [...whyPurchase.option, newOption];
                        newValueArr = [...whyPurchase.value, newValue];
                      }
                      setWhyPurchase({
                        option: newOptionArr,
                        value: newValueArr,
                      });
                      setFormValue({
                        ...formValue,
                        [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                      });
                    }}
                  ></CollectionBootleButton>
                  {whyPurchase?.option.includes(enLocales.collectionBootle.question24Select13) && (
                    <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[26px]">
                      {t('collectionBootle.PleaseSpecify')}
                    </div>
                  )}
                  {whyPurchase?.option.includes(enLocales.collectionBootle.question24Select13) && (
                    <BaseTextArea
                      placeholder={t('collectionBootle.EnterTypeMessage') as string}
                      disable={!whyPurchase?.option.includes(enLocales.collectionBootle.question24Select13)}
                      defaultValue={whyPurchaseTextArea}
                      onChange={onWhyPurchaseTextAreaChange}
                      collectionDatePickerClassName={classNames(
                        isThirdSubmitCheck &&
                          whyPurchase?.option.includes(enLocales.collectionBootle.question24Select13) &&
                          whyPurchaseTextArea.length === 0 &&
                          'my-select-selector-empty-type',
                      )}
                    ></BaseTextArea>
                  )}
                  {forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length) && (
                    <MessageText
                      helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                      status={
                        forceThirdSubmitShowError(whyPurchase?.option.length < 2 ? '' : whyPurchase?.option.length)
                          ? 'error'
                          : undefined
                      }
                      style={{
                        marginTop: isLg ? '11px' : '0.2683rem',
                      }}
                      innerStyle={{
                        justifyContent: 'flex-start',
                        marginBottom: '0px',
                      }}
                    ></MessageText>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderPickList = () => {
    if (pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]) {
      const selectPickYesValue =
        allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType]
          .questionFifteen?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType].questionNineteen
          ?.select?.[PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType];
      return (
        <div className="flex flex-col">
          <p
            className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
            style={{
              marginTop: isLg ? '0px' : '0.7073rem',
              marginBottom: isLg ? '0px' : '0rem',
            }}
          >
            {t('collectionBootle.question18InfoTitle')}
          </p>

          <FiledTitle
            title={t('collectionBootle.question18InfoText3') as string}
            style={{
              marginTop: isLg ? '29px' : '0.7073rem',
              marginBottom: isLg ? '29px' : '0.7073rem',
            }}
          />
          {/* Choose your preferred pick-up date */}
          {isLowVersion ? (
            <div className="flex flex-col mt-[29px] lg:mt-[45px]">
              {/* pickUpLocationsTitleSerial */}
              <FiledTitle
                style={{
                  marginTop: '0px',
                }}
                title={`${allValue?.labNewQuestionTwenty?.questionDesc?.EN}`}
              />
              <div className="flex justify-between">
                <CnFiledInput
                  style={{
                    width: '30%',
                    margin: isLg ? '0px' : '0px',
                  }}
                  value={pickUpDay}
                  onChange={onPickUpDayChange}
                  placeholder={t('collectionBootle.DatePlaceHolder')}
                  onCheckValid={handleCheckPickUpDay}
                  isSubmitCheck={isThirdSubmitCheck}
                  number={true}
                />
                <CnFiledInput
                  style={{
                    width: '30%',
                    margin: isLg ? '0px' : '0px',
                  }}
                  value={pickUpMonth}
                  onChange={onPickUpMonthChange}
                  placeholder={t('collectionBootle.MonthPlaceHolder')}
                  onCheckValid={handleCheckPickUpMonth}
                  isSubmitCheck={isThirdSubmitCheck}
                  number={true}
                />
                <CnFiledInput
                  style={{
                    width: '30%',
                    margin: isLg ? '0px' : '0px',
                  }}
                  value={pickUpYear}
                  onChange={onPickUpYearChange}
                  placeholder={t('collectionBootle.YearPlaceHolder')}
                  onCheckValid={handleCheckPickUpYear}
                  isSubmitCheck={isThirdSubmitCheck}
                  number={true}
                />
              </div>
            </div>
          ) : (
            <CollectionDatePicker
              title={`${allValue?.labNewQuestionTwenty?.questionDesc?.EN}`}
              onChange={onPickUpDateChange}
              // collectionDatePickerClassName={'mt-[29px] lg:mt-[45px]'}
              collectionDatePickerClassName={classNames(
                forceThirdSubmitShowError(pickUpDate) && 'my-select-selector-empty-type',
                'mt-[29px] lg:mt-[45px]',
              )}
              style={{
                marginTop: '0px',
              }}
              placeholder={t('collectionBootle.question18Placeholder') as string}
              defaultValue={pickUpDate}
              isPickUpTime={true}
              forceDisabledDate={true}
              disabledEndDate={getSampleExpireDate()}
            />
          )}

          {forceThirdSubmitShowError(pickUpDate) && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={forceThirdSubmitShowError(pickUpDate) ? 'error' : undefined}
              style={{
                marginTop: isLg ? '0px' : '0rem',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
          {/* Choose your preferred pick-up time */}
          <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
            <FiledTitle title={`${selectPickYesValue?.questionTwentyOne?.questionDesc?.EN}`} />
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
              value={'09:00-13:00'}
              status={
                forceThirdSubmitShowError(pickTime) ? 'emptyError' : pickTime === '09:00-13:00' ? 'check' : 'unchecked'
              }
              onClick={() => {
                const val = '09:00-13:00';
                setPickTime(val);
                const newFormValue = {
                  ...formValue,
                  21: val,
                };
                setFormValue(newFormValue);
                clearCollectionDateAndTime(newFormValue);
              }}
            ></CollectionBootleButton>
            {/* <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
              value={'12:00-15:00'}
              status={
                forceThirdSubmitShowError(pickTime) ? 'emptyError' : pickTime === '12:00-15:00' ? 'check' : 'unchecked'
              }
              onClick={() => {
                const val = '12:00-15:00';
                setPickTime(val);
                const newFormValue = {
                  ...formValue,
                  21: val,
                };
                setFormValue(newFormValue);
                clearCollectionDateAndTime(newFormValue);
              }}
            ></CollectionBootleButton> */}
            {/* <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
              value={'15:00-18:00'}
              status={
                forceThirdSubmitShowError(pickTime) ? 'emptyError' : pickTime === '15:00-18:00' ? 'check' : 'unchecked'
              }
              onClick={() => {
                const val = '15:00-18:00';
                setPickTime(val);
                const newFormValue = {
                  ...formValue,
                  21: val,
                };
                setFormValue(newFormValue);
                clearCollectionDateAndTime(newFormValue);
              }}
            ></CollectionBootleButton> */}
            {forceThirdSubmitShowError(pickTime) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceThirdSubmitShowError(pickTime) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '0px' : '0px',
                  position: 'relative',
                  top: isLg ? '-15px' : '-0.3659rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
          </div>
          {/* Provide your delivery address */}
          <div className="flex flex-col w-[100%] mt-[22px] lg:mt-[24px] lg:mb-[45px]">
            <FiledTitle
              title={`${selectPickYesValue?.questionTwenty?.questionDesc?.EN}`}
              style={{
                marginBottom: isLg ? '23px' : '0.4878rem',
              }}
            />
            {/* <BaseTextArea
              placeholder={t('collectionBootle.question20Placeholder') as string}
              defaultValue={addressTextArea}
              onChange={onAddressTextAreaChange}
              collectionDatePickerClassName={classNames(
                forceThirdSubmitShowError(addressTextArea) && 'my-select-selector-empty-type',
              )}
            ></BaseTextArea> */}
            <FiledInput
              value={pApartmentAddress}
              onChange={onPApartmentAddressChange}
              title={t('collectionBootle.question20Title2')}
              placeholder={t('collectionBootle.question20Title2Placeholder')}
              filedInputClassName="mt-[0px]  lg:mt-[0px]"
              defaultValue={pApartmentAddress}
            />
            <FiledInput
              value={pAddress}
              onChange={onPAddressChange}
              title={t('collectionBootle.question20Title1')}
              placeholder={t('collectionBootle.question20Title1Placeholder')}
              filedInputClassName="mt-[29px]  lg:mt-[29px]"
              defaultValue={pAddress}
              // forceUppercase={true}
              onCheckValid={handleCheckPAddress}
              isSubmitCheck={isThirdSubmitCheck}
            />
            <div
              className={classNames(
                forceThirdSubmitShowError(pAddressRegion) && 'my-select-selector-empty-type',
                'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[0px] lg:mt-[29px] lg:mb-[0px]',
              )}
            >
              <FiledTitle title={t('collectionBootle.question20Title3')} />
              <Select
                onChange={onPAddressRegionChange}
                style={{
                  height: isLg ? '48px' : '1.1707rem',
                  color: '#8B849B',
                  fontWeight: 400,
                  fontSize: isLg ? '14px' : '0.3415rem',
                  lineHeight: isLg ? '24px' : '0.5854rem',
                }}
                placeholder={t('collectionBootle.question20Title3Placeholder') as string}
                value={pAddressRegion || undefined}
                defaultValue={pAddressRegion || undefined}
                // inputReadOnly={true}
                options={pAddressRegionOption}
              ></Select>
              {forceThirdSubmitShowError(pAddressRegion) && (
                <MessageText
                  helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                  status={forceThirdSubmitShowError(pAddressRegion) ? 'error' : undefined}
                  style={{
                    marginTop: isLg ? '0px' : '0rem',
                  }}
                  innerStyle={{
                    justifyContent: 'flex-start',
                    marginBottom: '0px',
                  }}
                ></MessageText>
              )}
            </div>
            {pAddressRegion && (
              <div
                className={classNames(
                  forceThirdSubmitShowError(pAddressDistrict) && 'my-select-selector-empty-type',
                  'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[0px] lg:mt-[29px] lg:mb-[0px]',
                )}
              >
                <FiledTitle title={t('collectionBootle.question20Title5')} />
                <Select
                  value={pAddressDistrict || undefined}
                  onChange={onPAddressDistrictChange}
                  style={{
                    height: isLg ? '48px' : '1.1707rem',
                    color: '#8B849B',
                    fontWeight: 400,
                    fontSize: isLg ? '14px' : '0.3415rem',
                    lineHeight: isLg ? '24px' : '0.5854rem',
                  }}
                  placeholder={t('collectionBootle.question20Title5Placeholder') as string}
                  defaultValue={pAddressDistrict || undefined}
                  // inputReadOnly={true}
                  options={pAddressDistrictOption}
                ></Select>
                {forceThirdSubmitShowError(pAddressDistrict) && (
                  <MessageText
                    helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                    status={forceThirdSubmitShowError(pAddressDistrict) ? 'error' : undefined}
                    style={{
                      marginTop: isLg ? '0px' : '0rem',
                    }}
                    innerStyle={{
                      justifyContent: 'flex-start',
                      marginBottom: '0px',
                    }}
                  ></MessageText>
                )}
              </div>
            )}

            <div
              className={classNames(
                forceThirdSubmitShowError(pAddressCountry) && 'my-select-selector-empty-type',
                'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[0px] lg:mt-[29px] lg:mb-[0px]',
              )}
            >
              <FiledTitle title={t('collectionBootle.question20Title4')} />
              <Select
                onChange={onPAddressCountryChange}
                style={{
                  height: isLg ? '48px' : '1.1707rem',
                  color: '#8B849B',
                  fontWeight: 400,
                  fontSize: isLg ? '14px' : '0.3415rem',
                  lineHeight: isLg ? '24px' : '0.5854rem',
                }}
                placeholder={t('collectionBootle.question20Title4Placeholder') as string}
                defaultValue={pAddressCountry || undefined}
                value={pAddressCountry || undefined}
                // inputReadOnly={true}
                options={pAddressCountryOption}
              ></Select>
              {forceThirdSubmitShowError(pAddressCountry) && (
                <MessageText
                  helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                  status={forceThirdSubmitShowError(pAddressCountry) ? 'error' : undefined}
                  style={{
                    marginTop: isLg ? '0px' : '0rem',
                  }}
                  innerStyle={{
                    justifyContent: 'flex-start',
                    marginBottom: '0px',
                  }}
                ></MessageText>
              )}
            </div>
          </div>
          {dropOffLocations()}
        </div>
      );
    } else if (
      pickDropSelectStatus === PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]]
    ) {
      return dropOffLocations();
    } else {
      return null;
    }
  };

  const renderSmearList = () => {
    const selectPapYesValue =
      allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType];
    if (smearSelectStatus === SmearSelectType[SmearSelectType['Positive' as keyof typeof SmearSelectType]]) {
      {
        /* TODO: 这里的id估计要调整 */
      }
      {
        /* If you have a Pap Smear test before , what was the result of you last test? */
      }
      return (
        <div className="flex flex-col w-[100%] mt-[29px] lg:mt-[61px]">
          {/*   TODO 换行的问题=>需要再看一下 */}
          <FiledTitle
            title={`${selectPapYesValue?.questionThirteen?.questionDesc?.EN}`}
            style={{
              marginBottom: isLg ? '22px' : '0.7073rem',
            }}
          />
          {/* <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
            value={'Negative / Normal'}
            status={papResult?.value === 'Negative / Normal' ? 'check' : 'unchecked'}
            onClick={() => {
              const newValue = 'Negative / Normal';
              setPapResult({
                option: newValue,
                value: newValue,
              });
              setFormValue({
                ...formValue,
                14: newValue,
              });
            }}
          ></CollectionBootleButton> */}
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px]  mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
            value={t('collectionBootle.question12S1')}
            status={
              forceSecondSubmitShowError(papResult?.option)
                ? 'emptyError'
                : papResult?.value === t('collectionBootle.question12S1')
                ? 'check'
                : 'unchecked'
            }
            // TODO: 修改文案
            // if positive, what is the abnormality?
            // Please specify below
            onClick={() => {
              const newValue = t('collectionBootle.question12S1');
              setPapResult({
                option: newValue,
                value: newValue,
              });
              setFormValue({
                ...formValue,
                14: newValue,
              });
            }}
          ></CollectionBootleButton>
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
            value={t('collectionBootle.question12S2')}
            status={
              forceSecondSubmitShowError(papResult?.option)
                ? 'emptyError'
                : papResult?.value === t('collectionBootle.question12S2')
                ? 'check'
                : 'unchecked'
            }
            onClick={() => {
              const newValue = t('collectionBootle.question12S2');
              setPapResult({
                option: newValue,
                value: newValue,
              });
              setFormValue({
                ...formValue,
                14: newValue,
              });
            }}
          ></CollectionBootleButton>
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
            value={t('collectionBootle.question12S3')}
            status={
              forceSecondSubmitShowError(papResult?.option)
                ? 'emptyError'
                : papResult?.value === t('collectionBootle.question12S3')
                ? 'check'
                : 'unchecked'
            }
            onClick={() => {
              const newValue = t('collectionBootle.question12S3');
              setPapResult({
                option: newValue,
                value: newValue,
              });
              setFormValue({
                ...formValue,
                14: newValue,
              });
            }}
          ></CollectionBootleButton>
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
            value={t('collectionBootle.question12S4')}
            status={
              forceSecondSubmitShowError(papResult?.option)
                ? 'emptyError'
                : papResult?.value === enLocales.collectionBootle.question12S4
                ? 'check'
                : 'unchecked'
            }
            onClick={() => {
              const newValue = enLocales.collectionBootle.question12S4;
              setPapResult({
                option: newValue,
                value: newValue,
              });
              setFormValue({
                ...formValue,
                14: newValue,
              });
            }}
          ></CollectionBootleButton>
          {/* <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[38px] mb-[15px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
          value={'Not Sure'}
          status={hasValueMultiple(papResult?.option, 'Not Sure') ? 'check' : 'unchecked'}
          onClick={() => {
            const newArr = changeMultipleArr(papResult?.option, 'Not Sure');
            setPapResult({
              option: newArr,
              value: newArr,
            });
            setFormValue({
              ...formValue,
              14: newArr,
            });
          }}
        ></CollectionBootleButton> */}

          {/* TODO 最后一个有一个input表单 */}
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[38px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] mb-[14px]   md:mb-[14px]"
            value={t('collectionBootle.question12S5')}
            // status={hasValueMultiple(papResult?.option, 'Others') ? 'check' : 'unchecked'}
            status={
              forceSecondSubmitShowError(papResult?.option)
                ? 'emptyError'
                : papResult?.option === enLocales.collectionBootle.question12S5
                ? 'check'
                : 'unchecked'
            }
            onClick={() => {
              const newOption = enLocales.collectionBootle.question12S5;
              const newValue = papResultTextArea || '';
              setPapResult({
                option: newOption,
                value: newValue,
              });
              setFormValue({
                ...formValue,
                14: newValue,
              });
            }}
          ></CollectionBootleButton>
          {papResult?.option === enLocales.collectionBootle.question12S5 && (
            <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  md:text-[14px] md:leading-[20px] md:mt-[0px]  md:mb-[26px]">
              {t('collectionBootle.specifyBelowMessage')}
            </div>
          )}
          {/* TODO error状态也要展示 */}
          {papResult?.option === enLocales.collectionBootle.question12S5 && (
            <BaseTextArea
              placeholder={t('collectionBootle.question12EnterMessage') as string}
              disable={!(papResult?.option === enLocales.collectionBootle.question12S5)}
              defaultValue={papResultTextArea}
              onChange={onPapResultTextAreaChange}
              collectionDatePickerClassName={classNames(
                isSecondSubmitCheck &&
                  (!papResult?.value || (papResult?.value && papResult?.value.length === 0)) &&
                  'my-select-selector-empty-type',
              )}
            ></BaseTextArea>
          )}
          {forceSecondSubmitShowError(papResult?.option) && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={forceSecondSubmitShowError(papResult?.option) ? 'error' : undefined}
              style={{
                marginTop: isLg ? '-8px' : '-0.1951rem',
                position: 'relative',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderPapList = () => {
    if (gender === GenderType[GenderType['Male' as keyof typeof GenderType]]) {
      return isTestHpvComponent();
    } else if (papSelectStatus === PapSelectType[PapSelectType['Yes' as keyof typeof PapSelectType]]) {
      const selectPapYesValue =
        allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType];
      return (
        <div className="flex flex-col">
          {/* When was your last Pap Smear test? */}
          <div className="flex flex-col mt-[40px] lg:mt-[45px]">
            <FiledTitle title={`${selectPapYesValue?.questionTwelve?.questionDesc?.EN}`} />
            <Select
              // prefixCls="my-select"
              value={lastPap || undefined}
              defaultValue={lastPap || undefined}
              style={{
                height: isLg ? '48px' : '1.1707rem',
                // backgroundColor: forceSecondSubmitShowError(lastPap) ? '#FBEFEF' : undefined,
                // borderColor: forceSecondSubmitShowError(lastPap) ? '#C83532' : undefined,
              }}
              className={classNames(
                forceSecondSubmitShowError(lastPap) && 'my-select-selector-empty-type',
                'text-[14px] leading-[24px] tracking-[0.2px] text-[400]',
              )}
              placeholder={t('collectionBootle.question10Placeholder')}
              onChange={handleLastPapChange}
              options={[
                { value: 'Within 1 month', label: t('collectionBootle.question10S1') },
                { value: '1-3 months ago', label: t('collectionBootle.question10S2') },
                { value: '3-6 months ago', label: t('collectionBootle.question10S3') },
                { value: '6-12 months ago', label: t('collectionBootle.question10S4') },
                { value: '1-3 years ago', label: t('collectionBootle.question10S5') },
                { value: '3 years ago or more', label: t('collectionBootle.question10S6') },
              ]}
            />
            {forceSecondSubmitShowError(lastPap) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceSecondSubmitShowError(lastPap) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '0px' : '0.1463rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
          </div>
          <div className="flex flex-col mt-[40px] lg:mt-[45px] relative">
            {/* TODO title的序号需要调整 */}
            <FiledTitle title={`${allValue?.labNewQuestionThirteen?.questionDesc?.EN}`} />
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
              value={SmearSelectType[SmearSelectType[t('collectionBootle.Negative') as keyof typeof SmearSelectType]]}
              status={
                forceSecondSubmitShowError(smearSelectStatus)
                  ? 'emptyError'
                  : smearSelectStatus === SmearSelectType[SmearSelectType['Negative' as keyof typeof SmearSelectType]]
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                const val = SmearSelectType[SmearSelectType['Negative' as keyof typeof SmearSelectType]];
                setSmearSelectStatus(val as keyof typeof SmearSelectType);
                setFormValue({
                  ...formValue,
                  13: val,
                });
              }}
            ></CollectionBootleButton>
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
              value={SmearSelectType[SmearSelectType[t('collectionBootle.Positive') as keyof typeof SmearSelectType]]}
              status={
                forceSecondSubmitShowError(smearSelectStatus)
                  ? 'emptyError'
                  : smearSelectStatus === SmearSelectType[SmearSelectType['Positive' as keyof typeof SmearSelectType]]
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                const val = SmearSelectType[SmearSelectType['Positive' as keyof typeof SmearSelectType]];
                setSmearSelectStatus(val as keyof typeof SmearSelectType);
                setFormValue({
                  ...formValue,
                  13: val,
                });
              }}
            ></CollectionBootleButton>
            {forceSecondSubmitShowError(smearSelectStatus) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceSecondSubmitShowError(smearSelectStatus) ? 'error' : undefined}
                style={{
                  position: 'relative',
                  top: isLg ? '-10px' : undefined,
                  marginTop: isLg ? '0px' : '0.2683rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                }}
              ></MessageText>
            )}
          </div>

          {renderSmearList()}
          {/* 问题15有个分叉，需要再看一下 */}
          {/* Have you been tested for HPV?  */}
          {isTestHpvComponent()}
        </div>
      );
    } else if (papSelectStatus === PapSelectType[PapSelectType['No' as keyof typeof PapSelectType]]) {
      return isTestHpvComponent();
    } else {
      return null;
    }
  };

  const isPickUpNoPay = (handleLocalStorage.get(website.collection_bootle_id) || '').startsWith('0113');
  useEffect(() => {
    if (isHpvStudy) {
      setFirstName('HpvStudy');
      setDobDay('00');
      setDobMonth('00');
      setDobYear('0000');
      setDob('0000/00/00');
      const newOption = t('collectionBootle.question7Others');
      // 如果idtTextArea没值，则默认为空值
      const newValue = '';
      setIdt({
        option: newOption,
        value: newValue,
      });
      // 清空之前的表单
      setIdentityNoClearNumber((pre) => pre + 1);

      setIdtTextArea('HpvStudy');
      setIdt((pre: any) => ({
        ...pre,
        value: 'HpvStudy',
      }));

      setIdentityNo('HpvStudy');
      const val = PapSelectType[PapSelectType['No' as keyof typeof PapSelectType]];
      setPapSelectStatus(val as keyof typeof PapSelectType);

      const val2 = HPVSelectType[HPVSelectType['No' as keyof typeof HPVSelectType]];
      setHPVSelectStatus(val2 as keyof typeof HPVSelectType);

      setUndersignedBoxValue(true);
      setMiddleSignedBoxValue(true);
      setReadBoxValue(true);
      setFullName('hpvstudy hpvstudy');

      const val3 = PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]];
      setPickDropSelectStatus(val3 as keyof typeof PickDropSelectType);
      setFormValue((pre: any) => ({
        ...pre,
        2: 'HpvStudy',
        6: '0000/00/00',
        15: val2,
        19: val3,
        7: 'HpvStudy',
        8: 'HpvStudy',
        11: val,
        18: 'hpvstudy hpvstudy',
      }));
    }
  }, [isHpvStudy, t]);

  const isEn = i18n.language === 'en';

  const renderInputList = () => {
    if (curPage === CurPage[CurPage.PersonalInfo]) {
      return (
        <div className="flex flex-col">
          {/* LastName */}
          <FiledInput
            value={LastName}
            onChange={onLastNameChange}
            title={`${isHpvStudy ? (isEn ? 'Surname' : '姓氏') : allValue?.questionOne?.questionDesc?.EN}`}
            placeholder={isHpvStudy ? '' : t('collectionBootle.question1Placeholder')}
            filedInputClassName="mt-[43px] lg:mt-[74px]"
            defaultValue={LastName}
            // forceUppercase={true}
            onCheckValid={handleCheckLastName}
            isSubmitCheck={isFirstSubmitCheck}
            onBlur={onBlurLastName}
          />
          {!isHpvStudy && (
            <>
              {/* firstName */}
              <FiledInput
                value={firstName}
                onChange={onFirstNameChange}
                title={`${allValue?.questionTwo?.questionDesc?.EN}`}
                placeholder={t('collectionBootle.question2Placeholder')}
                filedInputClassName="mt-[29px]  lg:mt-[29px]"
                defaultValue={firstName}
                // forceUppercase={true}
                onCheckValid={handleCheckFirstName}
                isSubmitCheck={isFirstSubmitCheck}
                onBlur={onBlurFirstName}
              />
            </>
          )}
          {/* phone */}
          {/* TODO newPhone */}
          <div className="lg:w-[100%]  lg:mt-[29px] mt-[29px]">
            <FiledTitle title={`${allValue?.questionThree?.questionDesc?.EN}`} />
            {/*  isSubmitCheck={isSubmitCheck} */}
            <div
              className={classNames(
                true ? 'mb-[8px] lg:mb-[23px]' : 'mb-[0px] lg:mb-[0px]',
                'flex  lg:justify-between',
              )}
            >
              <PhoneNumberInput
                wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[6px] w-[100%] px-[15px] text-[16px]  border-[2px] flex items-center lg:text-[14px] lg:w-[100%] lg:h-[48px] lg:h-[48px]  lg:px-[15px]"
                value={phoneNumber}
                onChange={(val: string) => {
                  setPhoneNumber(val);
                  setFormValue({
                    ...formValue,
                    3: val,
                  });
                }}
                onIsValidChange={handleIsPhoneNumberValidChange}
                isSubmitCheck={isFirstSubmitCheck}
                defaultValue={phoneNumber}
                outsideStyle={{
                  width: isLg ? '70.52%' : undefined,
                }}
                style={{
                  width: isLg ? '100%' : undefined,
                }}
                onlyShowHkSelect={isHpvStudy}
              />
              {/* TODO: 需要调整 */}
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-[106px] ml-[5px] h-[48px] mb-[0px] md:w-[212px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[16px]"
                value={txt}
                disable={otpButtonStatus === 'disabled'}
                status={otpButtonStatus}
                // status="check"
                onClick={apiSendCode}
                sectionClassName={isLg ? 'flex justify-end h-[48px] md:h-[48px]' : 'h-[48px] md:h-[48px]'}
                style={{
                  padding: '0px',
                }}
              ></CollectionBootleButton>
            </div>
          </div>
          {true && (
            <CnFiledInput
              style={{
                width: isLg ? undefined : undefined,
                margin: isLg ? '0px' : undefined,
              }}
              value={otp}
              onChange={onOtpChange}
              placeholder={t('collectionBootle.OtpPlaceholder')}
              onCheckValid={handleCheckOtp}
              isSubmitCheck={isFirstSubmitCheck}
              isOtpSubmitCheck={isOtpSubmitCheck}
              fontFamilyValue={'Montserrat'}
              subHelpText={t('collectionBootle.OtpSubHelpText') || undefined}
            />
          )}
          {otp && otp.length > 0 && passwordHelpText && passwordHelpText.length > 0 && (
            <TextInput
              isOnlyRenderErrorText={true}
              sectionClassName="w-full lg:w-full"
              status={passwordStatus}
              placeholder={t('exportExcel.passwordPlaceholder') as string}
              inputClassName={
                'h-[48px] text-[14px] leading-[24px] tracking-[0.2px] text-[400] w-[100%] lg:text-[14px] lg:leading-[24px]  lg:w-[100%] md:h-[48px] lg:h-[48px] lg:tracking-[0.2px] lg:mr-[17px]'
              }
              // onChange={(e) => onPasswordChange?.(e.target.value)}
              helpText={passwordHelpText as string}
            ></TextInput>
          )}
          {/* <FiledInput
            value={phone}
            onChange={onPhoneChange}
            title={`${allValue?.questionThree?.questionDesc?.serial}. ${allValue?.questionThree?.questionDesc?.EN}`}
            placeholder="Please input a valid number"
            filedInputClassName="mt-[29px]"
            defaultValue={phone}
          // onCheckValid={handleCheckFirstName}
          // isSubmitCheck={isSubmitCheck}
          /> */}

          {!isHpvStudy && (
            <>
              <div className="mt-[30px] font-[500] text-[#25282B] text-[14px] leading-[28px] lg:mt-[40px] lg:text-[16px] lg:leading-[28px]">
                {t('collectionBootle.EmailReminder')}
              </div>
              {/* email */}
              <FiledInput
                value={email}
                onChange={onEmailChange}
                title={`${allValue?.questionFour?.questionDesc?.EN}`}
                placeholder={t('collectionBootle.question4Placeholder')}
                filedInputClassName="mt-[29px] lg:mt-[29px]"
                defaultValue={email}
                onCheckValid={handleCheckEmail}
                // onCheckValid={handleCheckFirstName}
                isSubmitCheck={isFirstSubmitCheck}
                fontFamilyValue={'Montserrat'}
              />
              {/* doubleCheckEmail */}
              <FiledInput
                value={doubleCheckEmail}
                onChange={onDoubleCheckEmailChange}
                title={t('collectionBootle.DoubleCheckEmailTitle')}
                placeholder={t('collectionBootle.question4Placeholder') as string}
                filedInputClassName="mt-[12px] lg:mt-[12px]"
                defaultValue={doubleCheckEmail}
                onCheckValid={handleCheckDoubleCheckEmail}
                isSubmitCheck={isFirstSubmitCheck}
                fontFamilyValue={'Montserrat'}
              />
            </>
          )}
          {/* Gender项 */}
          <div className="flex flex-col w-[100%] mt-[29px]  lg:mt-[29px]">
            <FiledTitle title={`${allValue?.questionFive?.questionDesc?.EN}`} />
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[24px]"
              value={t('collectionBootle.question5Male')}
              status={
                forceFirstSubmitShowError(gender)
                  ? 'emptyError'
                  : gender === GenderType[GenderType['Male' as keyof typeof GenderType]]
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                const val = GenderType[GenderType['Male' as keyof typeof GenderType]];
                setGender(val as keyof typeof GenderType);
                setFormValue({
                  ...formValue,
                  5: val,
                });
              }}
            ></CollectionBootleButton>
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[38px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[0px]"
              value={t('collectionBootle.question5Female')}
              status={
                forceFirstSubmitShowError(gender)
                  ? 'emptyError'
                  : gender === GenderType[GenderType['Female' as keyof typeof GenderType]]
                  ? 'check'
                  : 'unchecked'
              }
              onClick={() => {
                const val = GenderType[GenderType['Female' as keyof typeof GenderType]];
                setGender(val as keyof typeof GenderType);
                setFormValue({
                  ...formValue,
                  5: val,
                });
              }}
            ></CollectionBootleButton>
            {forceFirstSubmitShowError(gender) && (
              <MessageText
                helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                status={forceFirstSubmitShowError(gender) ? 'error' : undefined}
                style={{
                  marginTop: isLg ? '11px' : '0.2683rem',
                }}
                innerStyle={{
                  justifyContent: 'flex-start',
                  marginBottom: '0px',
                }}
              ></MessageText>
            )}
          </div>
          {/* Date of Birth */}
          {isForceNoUseDataPicker ? (
            <>
              {!isHpvStudy && (
                <div className="flex flex-col  mt-[40px] lg:mt-[50px]">
                  <FiledTitle title={`${allValue?.questionSix?.questionDesc?.EN}`} />
                  <div className="flex justify-between">
                    <div
                      className={classNames(
                        (forceFirstSubmitShowError(dobDay) || isShowDobCheckError) && 'my-select-selector-empty-type',
                        'lg:w-[30%] m-[0px] w-[30%] flex flex-col mt-[0px] mb-[0px] lg:mt-[0px] lg:mb-[0px]',
                      )}
                    >
                      <Select
                        value={dobDay || undefined}
                        onChange={onDobDayChange}
                        style={{
                          height: isLg ? '48px' : '1.1707rem',
                          color: '#8B849B',
                          fontWeight: 400,
                          fontSize: isLg ? '14px' : '0.3415rem',
                          lineHeight: isLg ? '24px' : '0.5854rem',
                        }}
                        placeholder={t('collectionBootle.DatePlaceHolder') as string}
                        defaultValue={dobDay || undefined}
                        // inputReadOnly={true}
                        options={dayOptionValue}
                        getPopupContainer={triggerNode => (triggerNode.parentElement || document.body)}
                      ></Select>
                      {(forceFirstSubmitShowError(dobDay) || isShowDobCheckError) && (
                        <MessageText
                          helpText={
                            (isShowDobCheckError ? dobCheck : t('collectionBootle.fieldsRequiredMessage')) as string
                          }
                          status={forceFirstSubmitShowError(dobDay) || isShowDobCheckError ? 'error' : undefined}
                          style={{
                            marginTop: isLg ? '0px' : '0rem',
                          }}
                          innerStyle={{
                            justifyContent: 'flex-start',
                            marginBottom: '0px',
                          }}
                        ></MessageText>
                      )}
                    </div>
                    <div
                      className={classNames(
                        (forceFirstSubmitShowError(dobMonth) || isShowDobCheckError) && 'my-select-selector-empty-type',
                        'lg:w-[30%] m-[0px] w-[30%] flex flex-col mt-[0px] mb-[0px] lg:mt-[0px] lg:mb-[0px]',
                      )}
                    >
                      <Select
                        value={dobMonth || undefined}
                        onChange={onDobMonthChange}
                        style={{
                          height: isLg ? '48px' : '1.1707rem',
                          color: '#8B849B',
                          fontWeight: 400,
                          fontSize: isLg ? '14px' : '0.3415rem',
                          lineHeight: isLg ? '24px' : '0.5854rem',
                        }}
                        placeholder={t('collectionBootle.MonthPlaceHolder') as string}
                        defaultValue={dobMonth || undefined}
                        // inputReadOnly={true}
                        options={monthOptionValue}
                        getPopupContainer={triggerNode => (triggerNode.parentElement || document.body)}
                      ></Select>
                      {(forceFirstSubmitShowError(dobMonth) || isShowDobCheckError) && (
                        <MessageText
                          helpText={
                            (isShowDobCheckError ? dobCheck : t('collectionBootle.fieldsRequiredMessage')) as string
                          }
                          status={forceFirstSubmitShowError(dobMonth) || isShowDobCheckError ? 'error' : undefined}
                          style={{
                            marginTop: isLg ? '0px' : '0rem',
                          }}
                          innerStyle={{
                            justifyContent: 'flex-start',
                            marginBottom: '0px',
                          }}
                        ></MessageText>
                      )}
                    </div>
                    <div
                      className={classNames(
                        (forceFirstSubmitShowError(dobYear) || isShowDobCheckError) && 'my-select-selector-empty-type',
                        'lg:w-[30%] m-[0px] w-[30%] flex flex-col mt-[0px] mb-[0px] lg:mt-[0px] lg:mb-[0px]',
                      )}
                    >
                      <Select
                        value={dobYear || undefined}
                        onChange={onDobYearChange}
                        style={{
                          height: isLg ? '48px' : '1.1707rem',
                          color: '#8B849B',
                          fontWeight: 400,
                          fontSize: isLg ? '14px' : '0.3415rem',
                          lineHeight: isLg ? '24px' : '0.5854rem',
                        }}
                        placeholder={t('collectionBootle.YearPlaceHolder') as string}
                        defaultValue={dobYear || undefined}
                        // inputReadOnly={true}
                        options={yearOptionValue}
                        getPopupContainer={triggerNode => (triggerNode.parentElement || document.body)}
                      ></Select>
                      {(forceFirstSubmitShowError(dobYear) || isShowDobCheckError) && (
                        <MessageText
                          helpText={
                            (isShowDobCheckError ? dobCheck : t('collectionBootle.fieldsRequiredMessage')) as string
                          }
                          status={forceFirstSubmitShowError(dobYear) || isShowDobCheckError ? 'error' : undefined}
                          style={{
                            marginTop: isLg ? '0px' : '0rem',
                          }}
                          innerStyle={{
                            justifyContent: 'flex-start',
                            marginBottom: '0px',
                          }}
                        ></MessageText>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <CollectionDatePicker
              title={`${allValue?.questionSix?.questionDesc?.EN}`}
              onChange={onDobChange}
              // collectionDatePickerClassName={'mt-[40px] lg:mt-[50px]'}
              collectionDatePickerClassName={classNames(
                forceFirstSubmitShowError(dob) && 'my-select-selector-empty-type',
                'mt-[40px] lg:mt-[50px]',
              )}
              placeholder={t('collectionBootle.question21Placeholder') as string}
              defaultValue={dob}
              style={{
                width: isLg ? '26.66vw' : undefined,
                marginLeft: isLg ? '0px' : undefined,
              }}
              // datePickerClassName={classNames(forceFirstSubmitShowError(dob) && 'my-select-selector-empty-type','mt-[40px] lg:mt-[50px]')}

              // className={classNames(
              //   forceSecondSubmitShowError(lastPap) && 'my-select-selector-empty-type',
              //   'text-[14px] leading-[24px] tracking-[0.2px] text-[400]',
              // )}
              // forceDisabledDate={true}
            />
          )}

          {/* {forceFirstSubmitShowError(dob) && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={forceFirstSubmitShowError(dob) ? 'error' : undefined}
              style={{
                marginTop: isLg ? '11px' : '0.2683rem',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )} */}
          {/* IDT */}
          {!isHpvStudy && (
            <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
              <FiledTitle title={`${allValue?.questionSeven?.questionDesc?.EN}`} />
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-full h-[38px] mb-[18px]  lg:w-[26.66vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                value={t('collectionBootle.question7HK')}
                status={
                  forceFirstSubmitShowError(idt?.option)
                    ? 'emptyError'
                    : idt?.option === enLocales.collectionBootle.question7HK
                    ? 'check'
                    : 'unchecked'
                }
                onClick={() => {
                  const newValue = enLocales.collectionBootle.question7HK;
                  setIdt({
                    option: newValue,
                    value: newValue,
                  });
                  // 清空之前的表单
                  setIdentityNoClearNumber((pre) => pre + 1);
                  setFormValue({
                    ...formValue,
                    7: newValue,
                    8: '',
                  });
                }}
              ></CollectionBootleButton>
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-full h-[38px] mb-[18px]  lg:w-[26.66vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
                value={t('collectionBootle.question7ID')}
                status={
                  forceFirstSubmitShowError(idt?.option)
                    ? 'emptyError'
                    : idt?.option === enLocales.collectionBootle.question7ID
                    ? 'check'
                    : 'unchecked'
                }
                onClick={() => {
                  const newValue = enLocales.collectionBootle.question7ID;
                  setIdt({
                    option: newValue,
                    value: newValue,
                  });
                  setIdentityNoClearNumber((pre) => pre + 1);
                  setFormValue({
                    ...formValue,
                    7: newValue,
                    8: '',
                  });
                }}
              ></CollectionBootleButton>
              {/* TODO: other这里也需要填写 */}
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-full h-[38px] mb-[0px]  lg:w-[26.66vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[0px]"
                value={t('collectionBootle.question7Others')}
                status={
                  forceFirstSubmitShowError(idt?.option)
                    ? 'emptyError'
                    : idt?.option === t('collectionBootle.question7Others')
                    ? 'check'
                    : 'unchecked'
                }
                // status={idt === 'Passport' ? 'check' : 'unchecked'}
                onClick={() => {
                  const newOption = t('collectionBootle.question7Others');
                  // 如果idtTextArea没值，则默认为空值
                  const newValue = idtTextArea || '';
                  setIdt({
                    option: newOption,
                    value: newValue,
                  });
                  // 清空之前的表单
                  setIdentityNoClearNumber((pre) => pre + 1);
                  setFormValue({
                    ...formValue,
                    7: newValue,
                    8: '',
                  });
                }}
              ></CollectionBootleButton>
              {idt?.option === t('collectionBootle.question7Others') && (
                <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[26px]">
                  {t('collectionBootle.PleaseSpecify')}
                </div>
              )}
              {/* TODO: @alan 这里的字体需要处理 */}
              {idt?.option === t('collectionBootle.question7Others') && (
                <BaseTextArea
                  placeholder={t('collectionBootle.EnterTypeMessage') as string}
                  disable={!(idt?.option === t('collectionBootle.question7Others'))}
                  defaultValue={idtTextArea}
                  onChange={onIdtTextAreaChange}
                  collectionDatePickerClassName={classNames(
                    isFirstSubmitCheck &&
                      (!idt?.value || (idt?.value && idt?.value.length === 0)) &&
                      'my-select-selector-empty-type',
                  )}
                ></BaseTextArea>
              )}
              {forceFirstSubmitShowError(idt?.option) && (
                <MessageText
                  helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                  status={forceFirstSubmitShowError(idt?.option) ? 'error' : undefined}
                  style={{
                    marginTop: isLg ? '11px' : '0.2683rem',
                  }}
                  innerStyle={{
                    justifyContent: 'flex-start',
                    marginBottom: '0px',
                  }}
                ></MessageText>
              )}
            </div>
          )}
          {/* Identity No. */}
          {!isHpvStudy && (
            <FiledInput
              fontFamilyValue={'Montserrat'}
              value={identityNo}
              onChange={onIdentityNoChange}
              title={`${allValue?.questionEight?.questionDesc?.EN}${t('collectionBootle.question8Remind') as string}`}
              placeholder={idtPlaceholder as string}
              filedInputClassName="mt-[40px] mb-[46px]  lg:mb-[59px]"
              defaultValue={identityNo}
              onCheckValid={handleCheckIdentityNo}
              isSubmitCheck={isFirstSubmitCheck}
              showPop={true}
              formName={'IdentityNo'}
              clearNumber={identityNoClearNumber}
              overlayStyle={{
                width:
                  ([enLocales.collectionBootle.question7ID, enLocales.collectionBootle.question7HK].includes(
                    idt?.option,
                  ) ||
                    isEmptyValue(idt?.option)) &&
                  !isLg
                    ? '80%'
                    : undefined,
              }}
              // isSubmitCheck={isSubmitCheck}
            />
          )}

          {/* 校验otp的时候，不展示错误的信息 */}
          {!firstButtonLoading && firstNextstatus && (
            <MessageText helpText={firstNextHelpText} status={firstNextstatus}></MessageText>
          )}
          {/* Next 按钮 */}
          {/* TODO 按钮状态失效，需要再看一下，颜色的问题 */}
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] mb-[0px] mt-[40px] md:mt-[40px] md:w-[170px] md:h-[50px] md:text-[16px] md:mb-[0px] md:leading-[24px]"
            value={t('collectionBootle.Next')}
            status="check"
            onClick={onClickFirstNext}
            sectionClassName={isLg ? 'flex justify-end' : undefined}
            loading={firstButtonLoading}
          ></CollectionBootleButton>
        </div>
      );
    } else if (curPage === CurPage[CurPage.MedicalHistory]) {
      return (
        <div>
          {/* Have you had a Pap Smear test before? */}
          <div
            className={classNames(
              gender === GenderType[GenderType['Male' as keyof typeof GenderType]]
                ? 'mt-[0px] lg:mt-[0px]'
                : 'mt-[43px] lg:mt-[74px]',
              'flex flex-col w-[100%] ',
            )}
          >
            {gender !== GenderType[GenderType['Male' as keyof typeof GenderType]] && (
              <>
                <FiledTitle
                  title={`${allValue?.questionEleven?.questionDesc?.EN}`}
                  style={{ marginBottom: isLg ? '19px' : undefined }}
                />
                <CollectionBootleButton
                  htmlType="submit"
                  buttonClassName="w-full h-[38px] mb-[18px] md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[18px]"
                  value={PapSelectType[PapSelectType[t('collectionBootle.Yes') as keyof typeof PapSelectType]]}
                  status={
                    forceSecondSubmitShowError(papSelectStatus)
                      ? 'emptyError'
                      : papSelectStatus === PapSelectType[PapSelectType['Yes' as keyof typeof PapSelectType]]
                      ? 'check'
                      : 'unchecked'
                  }
                  onClick={() => {
                    const val = PapSelectType[PapSelectType['Yes' as keyof typeof PapSelectType]];
                    setPapSelectStatus(val as keyof typeof PapSelectType);
                    setFormValue({
                      ...formValue,
                      11: val,
                    });
                  }}
                ></CollectionBootleButton>
                <CollectionBootleButton
                  htmlType="submit"
                  buttonClassName="w-full h-[38px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[0px]"
                  value={PapSelectType[PapSelectType[t('collectionBootle.No') as keyof typeof PapSelectType]]}
                  status={
                    forceSecondSubmitShowError(papSelectStatus)
                      ? 'emptyError'
                      : papSelectStatus === PapSelectType[PapSelectType['No' as keyof typeof PapSelectType]]
                      ? 'check'
                      : 'unchecked'
                  }
                  onClick={() => {
                    const val = PapSelectType[PapSelectType['No' as keyof typeof PapSelectType]];
                    setPapSelectStatus(val as keyof typeof PapSelectType);
                    setFormValue({
                      ...formValue,
                      11: val,
                    });
                  }}
                ></CollectionBootleButton>
                {forceSecondSubmitShowError(papSelectStatus) && (
                  <MessageText
                    helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                    status={forceSecondSubmitShowError(papSelectStatus) ? 'error' : undefined}
                    style={{
                      marginTop: isLg ? '11px' : '0.2683rem',
                    }}
                    innerStyle={{
                      justifyContent: 'flex-start',
                    }}
                  ></MessageText>
                )}
              </>
            )}

            {renderPapList()}
          </div>
          {/* TODO 测试按钮项 */}
          {/* TODO 按钮就在最底部就好了=>不需要放在List里面 */}
          {/* TODO 开始写按钮=>写完之后再说 */}

          <div className="flex justify-between mt-[50px] lg:mt-[58px] relative">
            {secondNextstatus && (
              <MessageText
                helpText={secondNextHelpText}
                status={secondNextstatus}
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: isLg ? '-30px' : '-0.7317rem',
                }}
              ></MessageText>
            )}
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-[170px] h-[50px] mb-[63px] md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
              value={t('collectionBootle.Back')}
              status="check"
              onClick={onClickSecondBack}
            ></CollectionBootleButton>
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-[170px] h-[50px] mb-[63px]  md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
              disable={!(undersignedBoxValue && middleSignedBoxValue && readBoxValue)}
              value={t('collectionBootle.Next')}
              status={undersignedBoxValue && middleSignedBoxValue && readBoxValue ? 'check' : 'disabled'}
              onClick={onClickSecondNext}
            ></CollectionBootleButton>
          </div>
        </div>
      );
    } else if (curPage === CurPage[CurPage.Delivery]) {
      const selectHPVYesValue =
        allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType]
          .questionFifteen?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType];

      return (
        <div className="flex flex-col w-[100%]">
          {/* 问题19：Sample Bottle Pick Up / Drop Off */}
          {HPVSelectStatus && !isHpvStudy && (
            <div className="flex flex-col w-[100%]  mt-[43px] lg:mt-[74px]">
              <FiledTitle title={`${selectHPVYesValue?.questionNineteen?.questionDesc?.EN}`} />
              <CollectionBootleButton
                disable={isCharitableOrganization ? true : undefined}
                htmlType="submit"
                buttonClassName="w-full h-[38px]  mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px]  md:mt-[0px] md:mb-[18px]"
                value={t('collectionBootle.Drop_Off')}
                status={
                  forceThirdSubmitShowError(pickDropSelectStatus)
                    ? 'emptyError'
                    : pickDropSelectStatus ===
                      PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]]
                    ? 'check'
                    : 'unchecked'
                }
                onClick={() => {
                  const val = PickDropSelectType[PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]];
                  setPickDropSelectStatus(val as keyof typeof PickDropSelectType);
                  setFormValue({
                    ...formValue,
                    19: val,
                  });
                  // 展示弹窗
                  alertDeliveryInfo(
                    PickDropSelectType[
                      PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]
                    ] as keyof typeof PickDropSelectType,
                  );
                }}
              ></CollectionBootleButton>
              {!isCharitableOrganization && (
                <CollectionBootleButton
                  disable={isCharitableOrganization ? true : undefined}
                  htmlType="submit"
                  buttonClassName="w-full h-[38px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[45px]"
                  value={t(
                    isPickUpNoPay ? 'collectionBootle.PickUpButtonTextNoPay' : 'collectionBootle.PickUpButtonText',
                  )}
                  status={
                    forceThirdSubmitShowError(pickDropSelectStatus)
                      ? 'emptyError'
                      : pickDropSelectStatus ===
                        PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
                      ? 'check'
                      : 'unchecked'
                  }
                  onClick={() => {
                    const val = PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]];
                    setPickDropSelectStatus(val as keyof typeof PickDropSelectType);
                    setFormValue({
                      ...formValue,
                      19: val,
                    });
                    // 展示弹窗
                    alertDeliveryInfo(
                      PickDropSelectType[
                        PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]
                      ] as keyof typeof PickDropSelectType,
                    );
                  }}
                ></CollectionBootleButton>
              )}
              {forceThirdSubmitShowError(pickDropSelectStatus) && (
                <MessageText
                  helpText={t('collectionBootle.fieldsRequiredMessage') as string}
                  status={forceThirdSubmitShowError(pickDropSelectStatus) ? 'error' : undefined}
                  style={{
                    marginTop: isLg ? '0px' : '0rem',
                    position: 'relative',
                    top: isLg ? '-30px' : '0.0976rem',
                  }}
                  innerStyle={{
                    justifyContent: 'flex-start',
                    marginBottom: '0px',
                  }}
                ></MessageText>
              )}
            </div>
          )}
          {HPVSelectStatus && renderPickList()}

          {isHpvStudy && <HpvStudyConfirm lastName={LastName} handleCheck={(f) => (checkHpvStudySingle.current = f)} />}

          <div className="flex justify-between mt-[50px] lg:mt-[25px]">
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-[170px] h-[50px] mb-[63px]  md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
              value={t('collectionBootle.Back')}
              status="check"
              onClick={onClickThirdBack}
            ></CollectionBootleButton>
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-[170px] h-[50px] mb-[63px]  md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
              value={t('collectionBootle.Next')}
              status="check"
              onClick={onClickThirdSubmit}
            ></CollectionBootleButton>
          </div>
        </div>
      );
    } else {
      return (
        <RenderUpgrade
          isPickUpNeedPayment={isPickUpNeedPayment}
          onClick={() => {
            setIsNeedUpgrade(true);
            setIsOnPayment(true);
            sessionStorage.setItem(website.is_user_want_to_upgrade, 'true');
          }}
          onSkipClick={async () => {
            setIsNeedUpgrade(false);
            const formatDate = formatCollectionBootleDate(
              papSelectStatus,
              HPVSelectStatus,
              pickDropSelectStatus,
              smearSelectStatus,
              gender,
              allValue,
              formValue,
            );
            try {
              const submitTimeFormatDate = {
                ...formatDate,
              };
              const defaultValue = getDefaultCollectionBootleDate(getAllValue(t));
              submitTimeFormatDate[COMMIT_TIME_POSITION as keyof typeof defaultValue].answer =
                dayjs().format('YYYY/MM/DD hh:mma') + '';

              // TODO: 这里也需要值，从BE获取的值
              // TODO: 这里需要测试一下，是否成功
              // TODO: 感觉是这里发生了error=>需要再看一下=>解决一下bug
              if (isCharitableOrganization) {
                // TODO: ngo code需要从BE获取
                submitTimeFormatDate[(DISAGREE_FIRST_POSITION - 3) as keyof typeof defaultValue].answer = ngoCode;

                // TODO：
              }

              // console.log(formatDate, 'formatDate');
              // console.log(submitTimeFormatDate, 'submitTimeFormatDate');

              const isPick =
                pickDropSelectStatus ===
                PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]];

              let cnPostion;

              // 处理慈善组织的position
              if (isCharitableOrganization) {
                Array.isArray(charitableDate) &&
                  charitableDate.map((obj: any) => {
                    if (dropLocation === `${obj?.ngoEnName}, ${obj?.dropOffEnAddress}`) {
                      cnPostion = `${obj?.ngoCnName}：${obj?.dropOffCnAddress}`;
                    }
                  });
              } else {
                cnPostion = dropLocationCn;
              }

              const params = {
                hpvInfo: {
                  sampleId: handleLocalStorage.get(website.collection_bootle_id) || '',
                  data: JSON.stringify(submitTimeFormatDate),
                },
                isDebug: false,
                userName: isHpvStudy ? LastName : fullName,
                dateTime: isPick ? pickUpDate : dropOffDate,
                // 区分一下中英文
                position: isPick ? addressTextArea : dropLocation,
                cnPosition: isPick ? addressTextArea : cnPostion,
              };

              // pick 要交钱
              if (isPick) {
                sessionStorage.setItem(website.payment_submit_data, JSON.stringify(params));
                sessionStorage.setItem(website.is_user_pick_up_submit, 'true');
                setIsPickUpNeedPayment(true);

                // 到达支付页面
                setIsOnPayment(true);
                return;
              }

              await post('/hpv/add', params);
              navigate('/collectionSuccess');
            } catch (err: any) {
              const responseMessage = err?.response?.data?.message;
              const errorMessage =
                (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || t('collectionBootle.error');
              message.error(errorMessage);
            }
          }}
          onBackClick={() => {
            setCurPage(CurPage[CurPage.Delivery] as keyof typeof CurPage);
          }}
        />
      );
    }
  };

  // const onClickTou = () => {
  //   const bottleId = handleLocalStorage.get(website.collection_bootle_id) || '01010013437614';
  //   navigate(`/build/${bottleId}`);
  // };

  const renderTitleText = () => {
    if (curPage === CurPage[CurPage.PersonalInfo]) {
      return `1. ${t('collectionBootle.title')}`;
    } else if (curPage === CurPage[CurPage.MedicalHistory]) {
      return `2. ${t('collectionBootle.progress2')}`;
    } else if (curPage === CurPage[CurPage.Delivery]) {
      return `3. ${t('collectionBootle.progress3')}`;
    } else {
      return `4. ${t('collectionBootle.progress4')}`;
    }
  };

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  const renderPayment = () => {
    return (
      <Payment setIsOnPayment={setIsOnPayment} isNeedUpgrade={isNeedUpgrade} isPickUp={isPickUpNeedPayment}></Payment>
    );
  };

  if (isOnPayment) {
    return renderPayment();
  }

  const steps = [
    {
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.progress1')}
        </span>
      ),
      // description: 'This is a description.',
    },
    {
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.progress2')}
        </span>
      ),
      // description: 'This is a description.',
    },
    {
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.progress3')}
        </span>
      ),
      // description: 'This is a description.',
    },
  ];

  if (isHpv12) {
    steps.push({
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.progress4')}
        </span>
      ),
    });
  }

  if (isHpvStudy) {
    steps.splice(1, 1);
  }

  return (
    // lg:pt-[97px]
    <div className="collection-bottle min-h-[100vh] bg-[#f5f7fa] flex-col lg:h-full pb-[85px] lg:pb-[85px]">
      <div className="flex p-[42px_25px_28px_34px] justify-between items-center  lg:w-[71.38vw] lg:mx-[auto] lg:pl-[0px] lg:px-[0vw] lg:pt-[45px] lg:pb-[52px]">
        <div className="w-[180px] lg:w-[180px]">
          <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer  lg:w-[180px]" />
        </div>
        {isLg ? (
          <div
            className="flex items-center justify-center lg:mr-[8px] cursor-pointer"
            onClick={() => {
              onClickLanguage();
            }}
          >
            <div className="w-[24px]  lg:w-[29px]">
              <img src={greenEarth} alt="greenEarth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
            </div>
            <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
              {t('header.language')}
            </div>
          </div>
        ) : (
          <div
            className="w-[24px]  lg:w-[29px] cursor-pointer"
            onClick={() => {
              onClickLanguage();
            }}
          >
            <img src={greenEarth} alt="greenEarth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
          </div>
        )}
      </div>
      {/* 中间表单栏 */}
      <div
        className="w-full h-full flex flex-col bg-[#f5f7fa]  min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] lg:w-[71.38vw] lg:m-auto  lg:bg-[#FFFFFF] lg:rounded-[8px] lg:shadow-[0_12px_26px_rgba(16,30,115,0.06)]"
        // style={style.container}
      >
        {/* TODO 修改到header栏 */}
        <div className="h-[104px] flex items-center px-[25px] justify-between bg-[#2DBDB6] lg:h-[105px]  lg:px-[7.56vw]">
          <div className="font-[600] text-[#FFFFFF] text-[20px] leading-[24px] w-[100%] lg:w-[100%] lg:text-[28px] lg:leading-[24px] ">
            {renderTitleText()}
          </div>
        </div>
        <div className="p-[20px_25px_63px_25px] lg:p-[36px_7.63vw_64px_7.63vw]">
          {curPage === CurPage[CurPage.PersonalInfo] && (
            <div className="flex flex-col mb-[28px] lg:mb-[58px]">
              <div className="font-[500] text-[#2dbdb6] text-[18px] leading-[28px] lg:text-[22px] lg:leading-[28px]">
                {`${t('collectionBootle.yourSampleId')} :`}
              </div>
              <div
                className="font-[500] text-[#000000] text-[14px] leading-[28px]  lg:text-[18px] lg:leading-[38px]"
                style={{
                  fontFamily: 'Montserrat',
                }}
              >
                {/* TODO 可能需要调整 */}
                {handleLocalStorage.get(website.collection_bootle_id)}
                {/* {'2dbdb6'} */}
              </div>
              <div className="mt-[8px] font-[500] text-[#25282B] text-[14px] leading-[28px] lg:mt-[15px] lg:text-[16px] lg:leading-[28px]">
                {t('collectionBootle.allRequiredDescription')}
              </div>
            </div>
          )}
          <div
            className={classNames(
              isZhHKLanguage
                ? 'w-[100vw] left-[-28px] p-[0px_0px_0px_0px]'
                : 'w-[105vw] left-[-50px] p-[0px_25px_0px_25px]',
              'relative lg:w-[100%!important] lg:left-[-2.2rem] lg:p-0',
            )}
          >
            <Steps
              className={classNames(
                curPage === CurPage[CurPage.PersonalInfo] ? 'lg:mt-[0px]' : 'lg:mt-[40px]',
                'mt-[18px] w-[100%] lg:w-[120%]',
              )}
              // TODO horizontal失效的问题
              direction="horizontal"
              // style={{
              //   fontSize: isLg ? '16px' : '0.3415rem',
              //   lineHeight: isLg ? '24px' : '0.5854rem',
              //   fontWeight: 500,
              // }}
              responsive={false}
              progressDot
              current={CurPage[curPage as keyof typeof CurPage]}
              items={steps}
            />
          </div>
          {renderInputList()}
        </div>
      </div>
      {/* 底部栏 */}
      {/* <div className="w-full py-[35px] pl-[36px] pr-[25px] bg-[#989898] font-[400] text-[#FFFFFF] text-[12px] leading-[18px] lg:pl-[14.3vw] lg:pr-[14.65vw]  lg:pt-[35px] lg:pb-[58px] lg:text-[16px] lg:leading-[28px]">
        {t('footer.text1')}
        <br></br>
        <br></br>
        {t('footer.text2')}{' '}
        <span
          className="font-[400] text-[#FFFFFF] text-[12px] leading-[18px] lg:text-[16px] lg:leading-[28px] cursor-pointer underline"
          onClick={onClickTou}
        >
          {t('footer.text3')}
        </span>
      </div> */}
    </div>
  );
};

export default CollectionBootle;
