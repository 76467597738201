/* eslint-disable @typescript-eslint/indent */
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useEffect, useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { CSSProperties } from 'react';

import classNames from 'classnames';
import { PhoneRegion } from '@consts/enums';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { useTranslation } from 'react-i18next';

import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import './PhoneNumberInput.css';

// import en from './en.json';
import phoneEn from '@locales/phoneEn.json';
import phoneZhHK from '@locales/phoneZhHK.json';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';

const wrapperDefaultClass =
  'rounded-[0.192rem] w-[7.9rem] text-[0.386rem] h-[1.15rem] flex items-center justify-center border-[0.04rem] md:h-[48px] lg:h-[43px]';

const regionNumber = {
  [PhoneRegion[PhoneRegion.HK]]: '+852',
  [PhoneRegion[PhoneRegion.CN]]: '+86',
};

const borderColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#C83532',
  success: '#2BAC47',
};
const bgColor = {
  default: '#ffffff',
  warning: '#FDF3EC',
  error: '#FBEFEF',
  success: '#F1F8F2',
};

// TODO 这个需要再看一下
// OTP input field should be a number input field
// https://phasescientific.atlassian.net/browse/DPMOD-383
type PhoneNumberInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onIsValidChange?: (v: boolean) => void;
  style?: CSSProperties;
  outsideStyle?: CSSProperties;
  wrapperClassName?: string;
  isSubmitCheck?: boolean;
  defaultValue?: string;
  defaultCountry?: CountryCode;
  contactNumberEmptyMessage?: string;
  contactNumberInvalidMessage?: string;
  onlyShowHkSelect?: boolean;
  isCnLanguage?: boolean;
};
export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const [t] = useTranslation();
  const isZhHKLanguage = useIsZhHKLanguage();
  const [key, setKey] = useState<string | null>(null);
  const [isValid, setValid] = useState(true);
  const [allPhonenumberStr, setAllPhonenumberStr] = useState('');
  const [curRegion, setCurRegion] = useState(
    props?.defaultCountry === 'CN'
      ? (PhoneRegion[PhoneRegion.CN] as keyof typeof PhoneRegion)
      : (PhoneRegion[PhoneRegion.HK] as keyof typeof PhoneRegion),
  );

  const [curCallingCode, setCurCallingCode] = useState(
    regionNumber[
      props?.defaultCountry === 'CN'
        ? (PhoneRegion[PhoneRegion.CN] as keyof typeof PhoneRegion)
        : (PhoneRegion[PhoneRegion.HK] as keyof typeof PhoneRegion)
    ],
  );
  const [firstChangeRegion, setFirstChangeRegion] = useState(false);
  const [showStatus, setShowStatus] = useState<keyof typeof bgColor>('default');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    setKey(event.key);
  };

  const phoneNumberData = useMemo(() => {
    if (!allPhonenumberStr) {
      return {
        phoneNumber: '',
        countryCode: curCallingCode,
      };
    }
    const asYouType = new AsYouType();
    asYouType.input(allPhonenumberStr);
    return { phoneNumber: asYouType.getNationalNumber(), countryCode: '+' + (asYouType.getCallingCode() || '') };
  }, [allPhonenumberStr, curCallingCode]);

  const handlePhoneinputChange: React.ChangeEventHandler<HTMLInputElement> = useMemoizedFn((e) => {
    // 当用户输入值时，报错信息进行修改
    setFirstChangeRegion(false);
    let phonenumberWithCountyCode = '';
    if (e.target.value.startsWith('+')) {
      setAllPhonenumberStr(e.target.value);
      return;
    } else {
      // 如果为空，判断是否有初始值，有的话设置为初始值

      if (e.target.value) {
        phonenumberWithCountyCode = curCallingCode + e.target.value;
      } else {
        if (key === 'Backspace') {
          // ①当e.target.value为空，并且为Backspace时，需要把值清空
          // ②不以defaultValue为基准
          phonenumberWithCountyCode = curCallingCode + e.target.value;
          setKey(null);
        } else {
          phonenumberWithCountyCode = props?.defaultValue || curCallingCode + e.target.value;
        }
      }
      // 变为初始值
      setKey(null);

      // 根据PO需求，HK的number限制在12位
      if (phonenumberWithCountyCode.length > 12 && curRegion === PhoneRegion[PhoneRegion.HK]) {
        const afterSlicePhoneNumber = phonenumberWithCountyCode.slice(0, 12);
        // 更新phonenumberWithCountyCode的值
        phonenumberWithCountyCode = afterSlicePhoneNumber;
        setAllPhonenumberStr(afterSlicePhoneNumber);
      } else {
        console.log(phonenumberWithCountyCode, 'phonenumberWithCountyCode');
        setAllPhonenumberStr(phonenumberWithCountyCode);
      }
    }
    if (isValidPhoneNumber(phonenumberWithCountyCode)) {
      setValid(true);
      props.onIsValidChange?.(true);
    } else {
      e.type == 'blur' && setValid(false);
      e.type == 'change' && setValid(true);
      props.onIsValidChange?.(false);
    }
    props.onChange?.(phonenumberWithCountyCode || '');
  });

  const isValidNumber = useMemoizedFn((val: string) => {
    // 第一次的时候，设置失效
    // if (firstChangeRegion) {
    //   setValid(true);
    //   // props.onIsValidChange?.(true);
    // } else
    if (val && isValidPhoneNumber(val)) {
      setValid(true);
      props.onIsValidChange?.(true);
    } else {
      setValid(false);
      props.onIsValidChange?.(false);
    }
    props.onChange?.(val || '');
  });

  useEffect(() => {
    setCurRegion(
      props?.defaultCountry === 'CN'
        ? (PhoneRegion[PhoneRegion.CN] as keyof typeof PhoneRegion)
        : (PhoneRegion[PhoneRegion.HK] as keyof typeof PhoneRegion),
    );
  }, [props?.defaultCountry]);

  useEffect(() => {
    setCurCallingCode(regionNumber[curRegion as keyof typeof PhoneRegion]);
  }, [curRegion]);

  useEffect(() => {
    const forceCheck = () => {
      let input = document.getElementById('phone-number-id');
      input && input.focus();
      input && input.blur();
    };

    if (props?.isSubmitCheck) {
      forceCheck();
    }
  }, [props?.isSubmitCheck]);

  useEffect(() => {
    if (props?.defaultValue) {
      if (props?.defaultValue.startsWith('+')) {
        setAllPhonenumberStr(props?.defaultValue);
      } else {
        setAllPhonenumberStr(curCallingCode + props?.defaultValue);
      }
    }
  }, [props?.defaultValue, curCallingCode]);

  console.log(props?.defaultValue, 'props?.defaultValue');
  console.log(phoneNumberData, 'phoneNumberData');

  useEffect(() => {
    if (props.value && isValidPhoneNumber(props.value)) {
      setValid(true);
    }
    // when PhoneNumberInput mounted, we need know that is the default value valid,
    // but there is no need to re-determine the value has changed is valid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if ((!props.value || props.value === '' || props.value === '+' + curCallingCode) && props.isSubmitCheck) {
      setShowStatus('error');
    } else {
      if (isValid || props.value === '') {
        setShowStatus('default');
      } else {
        setShowStatus('error');
      }
    }
  }, [props.value, isValid, curCallingCode, props.isSubmitCheck]);

  return (
    <div
      style={{
        ...props.outsideStyle,
        fontFamily: 'Montserrat',
      }}
    >
      <div
        className={classNames(
          (!props.value || props.value === '' || props.value === '+' + curCallingCode) && props.isSubmitCheck
            ? 'bg-[#FBEFEF] border-[#C83532]'
            : isValid || props.value === ''
            ? 'bg-[#ffffff] border-[#D5D4DC]'
            : 'bg-[#FBEFEF] border-[#C83532]',
          props.wrapperClassName ?? wrapperDefaultClass,
        )}
        style={{
          backgroundColor: bgColor[(showStatus as keyof typeof bgColor) || 'default'],
          borderColor: borderColor[(showStatus as keyof typeof bgColor) || 'default'],
          ...props.style,
        }}
      >
        <PhoneInput
          labels={isZhHKLanguage ? phoneZhHK : phoneEn}
          // locales={'en-US'}
          // locales={'zh'}
          onFocus={props.onFocus}
          // @ts-ignore
          value={props.value}
          className="lg:w-[40px] w-[40px]  border-transparent"
          numberInputProps={{
            className: 'bg-[#00000000] outline-0 text-[0.386rem] w-[6rem] border-transparent hidden',
            'data-testid': 'phonenumber-input',
          }}
          style={{
            borderColor: '#fff',
          }}
          onCountryChange={(c) => {
            // 如果要添加international，再进行其他操作
            // console.log(c, 'c');
            setCurRegion(c as keyof typeof PhoneRegion);
            // 切换的时候，展示please enter的报错信息
            setFirstChangeRegion(true);
          }}
          withCountryCallingCode={false}
          addInternationalOption={false}
          international
          defaultCountry={props?.defaultCountry || 'HK'}
          //@ts-ignore
          onChange={isValidNumber}
          countries={props.onlyShowHkSelect ? ['HK'] : ['HK', 'CN']}
        />
        <p className="lg:w-[40px] w-[40px]">{phoneNumberData.countryCode}</p>
        <input
          id="phone-number-id"
          style={{
            backgroundColor: bgColor[(showStatus as keyof typeof bgColor) || 'default'],
            boxShadow: `inset 0 0 0 200000px ${bgColor[(showStatus as keyof typeof bgColor) || 'default']}`,
          }}
          value={phoneNumberData.phoneNumber}
          onChange={handlePhoneinputChange}
          onBlur={handlePhoneinputChange}
          className="outline-0 w-[70%] ml-[8px]  border-transparent bg-transparent lg:w-[70%] lg:ml-[0px]"
          onKeyDown={handleKeyDown}
        />
      </div>
      {!isValid && (
        <div className="flex items-center lg:mt-[0px]">
          <ExclamationCircleFilled
            className={'text-[16px] sm:text-[16px]'}
            style={{
              color: '#C83532',
            }}
          />
          <span
            className="text-[14px] ml-[4px] lg:text-[14px] lg:ml-[4px]"
            style={{
              color: '#C83532',
            }}
          >
            {firstChangeRegion
              ? props?.contactNumberEmptyMessage || t('collectionBootle.ContactNumberEmptyMessage')
              : props?.contactNumberInvalidMessage || t('collectionBootle.ContactNumberInvalidMessage')}
          </span>
        </div>
      )}
    </div>
  );
}
