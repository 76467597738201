import { LeftOutlined } from '@ant-design/icons';
import alertInfo from '@utils/globalComponents/Alert';
import { Button, message, Select } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import enLocales from '@locales/en.json';
import { useTranslation } from 'react-i18next';
import i18n from '@utils/i18n';
import dayjs from 'dayjs';
import { HiddenDayType } from '@consts/enums';
import { post } from '@utils/request';

const newLocationList0111 = [
  {
    id: 8,
    value: enLocales.collectionBootle.locations8,
    t1: 'collectionBootle.locations8',
    t2: 'collectionBootle.locations8Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 9,
    value: enLocales.collectionBootle.locations9,
    t1: 'collectionBootle.locations9',
    t2: 'collectionBootle.locations9Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 3,
    value: enLocales.collectionBootle.locations3,
    t1: 'collectionBootle.locations3',
    t2: 'collectionBootle.locations3Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 12,
    value: enLocales.collectionBootle.locations12,
    t1: 'collectionBootle.locations12',
    t2: 'collectionBootle.locations12Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 13,
    value: enLocales.collectionBootle.locations13,
    t1: 'collectionBootle.locations13',
    t2: 'collectionBootle.locations13Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Saturday, HiddenDayType.Sunday],
  },
  {
    id: 14,
    value: enLocales.collectionBootle.locations14,
    t1: 'collectionBootle.locations14',
    t2: 'collectionBootle.locations14Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 10,
    value: enLocales.collectionBootle.locations10,
    t1: 'collectionBootle.locations10',
    t2: 'collectionBootle.locations10Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 11,
    value: enLocales.collectionBootle.locations11,
    t1: 'collectionBootle.locations11',
    t2: 'collectionBootle.locations11Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
];

const newLocationList0205 = [
  {
    id: 8,
    value: enLocales.collectionBootle.locations8,
    t1: 'collectionBootle.locations8',
    t2: 'collectionBootle.locations8Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 9,
    value: enLocales.collectionBootle.locations9,
    t1: 'collectionBootle.locations9',
    t2: 'collectionBootle.locations9Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 15,
    value: enLocales.collectionBootle.locations15,
    t1: 'collectionBootle.locations15',
    t2: 'collectionBootle.locations15Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 16,
    value: enLocales.collectionBootle.locations16,
    t1: 'collectionBootle.locations16',
    t2: 'collectionBootle.locations16Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 3,
    value: enLocales.collectionBootle.locations3,
    t1: 'collectionBootle.locations3',
    t2: 'collectionBootle.locations3Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 12,
    value: enLocales.collectionBootle.locations12,
    t1: 'collectionBootle.locations12',
    t2: 'collectionBootle.locations12Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 13,
    value: enLocales.collectionBootle.locations13,
    t1: 'collectionBootle.locations13',
    t2: 'collectionBootle.locations13Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Saturday, HiddenDayType.Sunday],
  },
  {
    id: 14,
    value: enLocales.collectionBootle.locations14,
    t1: 'collectionBootle.locations14',
    t2: 'collectionBootle.locations14Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 10,
    value: enLocales.collectionBootle.locations10,
    t1: 'collectionBootle.locations10',
    t2: 'collectionBootle.locations10Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 11,
    value: enLocales.collectionBootle.locations11,
    t1: 'collectionBootle.locations11',
    t2: 'collectionBootle.locations11Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 17,
    value: enLocales.collectionBootle.locations17,
    t1: 'collectionBootle.locations17',
    t2: 'collectionBootle.locations17Time',
    hiddenDays: [HiddenDayType.PublicHoliday],
  },
];

const newLocationList0227 = [
  {
    id: 8,
    value: enLocales.collectionBootle.locations8,
    t1: 'collectionBootle.locations8',
    t2: 'collectionBootle.locations8Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 15,
    value: enLocales.collectionBootle.locations15,
    t1: 'collectionBootle.locations15',
    t2: 'collectionBootle.locations15Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 16,
    value: enLocales.collectionBootle.locations16,
    t1: 'collectionBootle.locations16',
    t2: 'collectionBootle.locations16Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 3,
    value: enLocales.collectionBootle.locations3,
    t1: 'collectionBootle.locations3',
    t2: 'collectionBootle.locations3Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 12,
    value: enLocales.collectionBootle.locations12,
    t1: 'collectionBootle.locations12',
    t2: 'collectionBootle.locations12Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 13,
    value: enLocales.collectionBootle.locations13,
    t1: 'collectionBootle.locations13',
    t2: 'collectionBootle.locations13Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Saturday, HiddenDayType.Sunday],
  },
  {
    id: 14,
    value: enLocales.collectionBootle.locations14,
    t1: 'collectionBootle.locations14',
    t2: 'collectionBootle.locations14Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 10,
    value: enLocales.collectionBootle.locations10,
    t1: 'collectionBootle.locations10',
    t2: 'collectionBootle.locations10Time',
    hiddenDays: [HiddenDayType.PublicHoliday, HiddenDayType.Sunday],
  },
  {
    id: 17,
    value: enLocales.collectionBootle.locations17,
    t1: 'collectionBootle.locations17',
    t2: 'collectionBootle.locations17Time',
    hiddenDays: [HiddenDayType.PublicHoliday],
  },
];

const style = {
  container: {
    background: '#f5f7fa',
  },
};

const ManualDropOff: React.FC = () => {
  const navigate = useNavigate();
  const collectionBottleId = useParams().collectionBottleId;
  const [address, setAddress] = useState<number>();
  const [t] = useTranslation();
  const isEn = i18n.language === 'en';
  const splitAddress = (addressStr: string) => {
    if (isEn) {
      const d1 = addressStr.split(',')[0];
      const arr = addressStr.split(',');
      arr.shift();
      const d2 = arr.join(',');
      return {
        d1,
        d2,
      };
    } else {
      const d1 = addressStr.split('：')[0];
      const d2 = addressStr.split('：')[1];
      return {
        d1,
        d2,
      };
    }
  };

  let newLocationList = newLocationList0111;
  const now = dayjs();
  if (now.isAfter('2024-02-05')) {
    newLocationList = newLocationList0205;
  }
  if (now.isAfter('2024-02-27')) {
    newLocationList = newLocationList0227;
  }
  const [loading, setLoading] = useState(false);

  return (
    <div
      className="w-full h-[100vh] flex  bg-opacity-[0.8] flex-col py-[0] pl-[20px] pr-[20px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="font-[Inter] flex justify-between w-full my-[40px] text-[#212121] text-[20px] font-semibold">
        <LeftOutlined
          onClick={() => {
            navigate(-1);
          }}
        />
        <p>{isEn ? 'Select address' : '選擇地點'}</p>
        <div className="w-[24px]"></div>
      </div>
      <div>
        <p className="text-[#25282b] text-[16px] font-semibold leading-[20px]">
          {isEn ? 'Select your drop-off address' : '選擇您的送件地點'}
        </p>
        <Select
          className="manual-drop-off-select w-[373px] md:w-full"
          rootClassName="manual-drop-off-select-root"
          placeholder={isEn ? 'Select your drop-off address' : '请選擇您的送件地點'}
          value={address}
          onChange={(v) => {
            setAddress(v);
          }}
          options={newLocationList.map((item, idx) => ({
            value: idx,
            label: t(item.t1),
          }))}
          size="large"
        />
        <Button
          loading={loading}
          onClick={() => {
            if (address === undefined) return;
            const addr = splitAddress(t(newLocationList[address].t1));
            alertInfo((close) => ({
              content: (
                <div onClick={(e) => e.stopPropagation()} className="w-full h-full flex flex-col bg-white">
                  <p className="text-[#212121] m-0 font-[Inter] text-[17px] font-medium leading-[28px]">
                    {isEn ? 'Please confirm your drop-off address' : '請確認您的送件地點'}
                  </p>
                  <p className="text-[#2DBDB6] mt-[14px] mb-[5px] font-[Inter] text-[15px] font-medium">{addr.d1}</p>
                  <p className="text-[rgba(33,33,33,0.45)] m-0 font-[Inter] text-[15px] font-medium">{addr.d2}</p>
                  <Button
                    onClick={() => {
                      if (address !== undefined) {
                        const addressTemp = t(newLocationList[address].t1) || '';

                        setLoading && setLoading(true);
                        post('/hpv/attachNewCilinic', {
                          sample_id: collectionBottleId,
                          new_clinic: addressTemp,
                          timestamp: dayjs().format('YYYY/MM/DD hh:mma'),
                        })
                          .then(() => {
                            window.location.href = `/scanSuccess/${collectionBottleId}?res=${encodeURIComponent(
                              addressTemp,
                            )}`;

                            // 跳转至成功页面
                          })
                          .catch((e: any) => {
                            // 专门的错误码，代表着用户之前已经扫描过，不抛出异常
                            if (['15027', 15027].includes(e.response.data.code)) {
                              return;
                            }
                            message.error(e.response.data.message);
                          })
                          .finally(() => {
                            setLoading && setLoading(false);
                          });

                        close();
                      }
                    }}
                    type="primary"
                    size="large"
                    className="w-full mt-[20px]"
                  >
                    {isEn ? 'Confirm' : '確認'}
                  </Button>
                  <Button
                    onClick={() => {
                      close();
                    }}
                    size="large"
                    className="w-full mt-[8px]"
                  >
                    <span className="text-[#2DBDB6]">{isEn ? 'Cancel' : '取消'}</span>
                  </Button>
                </div>
              ),
            }));
          }}
          type="primary"
          size="large"
          className="w-full mt-[20px]"
          disabled={address === undefined}
          style={{
            borderColor: !address ? '#d9d9d9' : '#2DBDB6',
          }}
        >
          {isEn ? 'Submit' : '提交'}
        </Button>
      </div>
    </div>
  );
};

export default ManualDropOff;
