/* eslint-disable @typescript-eslint/indent */

import dayjs from 'dayjs';
import {
  PapSelectType,
  HPVSelectType,
  PickDropSelectType,
  SmearSelectType,
  GenderType,
  HiddenDayType,
} from '@consts/enums';
import {
  COMMIT_TIME_POSITION,
  Logistic_COMMIT_TIME_POSITION,
  DISAGREE_FIRST_POSITION,
  DROP_OFF_DATE_POSITION,
} from '@consts/consts';
import { TFunction } from 'i18next';
import { getAllValue, getCNAllValue } from '@utils/excel';
import { isAndroid, osVersion } from 'react-device-detect';
import { Dayjs } from 'dayjs';

// import { OpenCC } from 'opencc';

// const OpenCC = require('opencc');
// const converter = new OpenCC('s2t.json'); // s2t.json表示简体中文转繁体中文的配置文件

/**
 * 校验val是否为邮箱
 * @param {string | null} val 需要校验的邮箱
 * @returns {boolean} 是否为邮箱
 */
export const isEmail = (val: string | null) => {
  if (!val) {
    return false;
  }
  const reg =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return reg.test(val);
};

// TODO 需要写测试
/**
 * 校验val是否符合密码规则
 * @param {string | null} val 需要校验的密码
 * @returns {boolean}         是否为符合规则的密码
 */
export const isPasswordValid = (val: string | null) => {
  if (!val) {
    return false;
  }
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/;
  return reg.test(val);
};

/**
 * loacalStore存取函数
 */
const handleLocalStorage = {
  set(name: string, value: string | null | number | object | boolean) {
    localStorage.setItem(name, JSON.stringify(value));
  },
  get(name: string) {
    const value = localStorage.getItem(name);
    if (value && value !== 'undefined') {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    } else {
      return null;
    }
  },
  remove(name: string) {
    localStorage.removeItem(name);
  },
};
export { handleLocalStorage };

/**
 * 字符串中间替换成星号
 * @param {string} str  需要操作的字符串
 * @param {number} len1 开头不需要变成星号的长度
 * @param {number} len2 末尾不需要变成星号的长度，设置默认值为0，不传的时候就除了设置的开头几位剩余全部变成星号
 * @returns {string}    转换之后的字符串
 */
export const changeToStar = (str: string | null, len1: number, len2: number = 0) => {
  if (!str) {
    return null;
  }
  let strLen = str.length;
  let stars = '';
  for (let i = 0; i < strLen - len1 - len2; i++) {
    stars += '*';
  }
  str = str.substr(0, len1) + stars + str.substr(strLen - len2);
  return str;
};

/**
 * 格式化数据，如把1_1转换成，id为1，answer为1的对象
 * @param {string} str  需要操作的字符串
 * @returns {object}    转换之后的对象
 */
export const formatData = (str: string | null) => {
  if (!str) {
    return null;
  }
  const strArr = str.split('_');
  const obj = {
    id: strArr[0],
    answer: strArr[1],
  };
  return obj;
};

/**
 * 格式化后端日期，如将’2023-01-01‘转为’1 / 1 / 2023‘
 * @param {string} str  需要操作的字符串
 * @returns {string}    转换之后的字符串
 */
export const formatBeDate = (str: string | null | undefined) => {
  if (!str) {
    return null;
  }
  const val = str && new Date(str).toLocaleDateString('zh-HK');
  const newVal = val && val.replaceAll('/', ' / ');
  return newVal;
};

/**
 * 将当前日期转换为标准数据格式，如’22 Mar 2023 12:30pm ‘
 * @returns {string}    转换之后的字符串
 */
export const formatCurDate = () => {
  return dayjs().format('DD MMM YYYY hh:mma');
};

/**
 * 判断当前是否存在当前复选框的值
 * @param {array} arr   判断的数组
 * @param {string} val  判断的值
 * @returns {boolean}   是否存在于当前数组
 */
export const hasValueMultiple = (arr: string[], val: string) => {
  let hasValue = false;
  arr.map((str) => {
    if (str === val) {
      hasValue = true;
    }
  });
  return hasValue;
};

/**
 * 判断当前是否存在当前复选框的值,返回移除之后的数组/新增之后的数组
 * @param {array} arr   判断的数组
 * @param {string} val  判断的值
 * @returns {boolean}   是否存在于当前数组
 */
export const changeMultipleArr = (arr: string[], val: string) => {
  let newArr = arr;
  const hasValue = hasValueMultiple(arr, val);
  if (hasValue) {
    newArr = arr.filter(function (value) {
      return value !== val;
    });
    // 移除值
  } else {
    // 添加值
    newArr.push(val);
  }
  return newArr;
};

/**
 * 移除非选项的值
 * @param {array} arr   判断的数组
 * @returns {array}     移除值之后的数组
 */
export const removeNotDefaultValue = (arr: string[]) => {
  const defaultArr = [
    'Negative / Normal',
    'ASC - US',
    'LSIL',
    'HSIL',
    'Not Sure',
    // 'Positive / Abnormal - Other',
  ];

  let removeValue: any[] = [];
  // 筛选出不符合的值
  arr.map((val) => {
    if (defaultArr.indexOf(val) < 0) {
      removeValue.push(val);
    }
  });

  // 移除不符合的值
  const newArr = arr.filter(function (value) {
    let isRemove = true;
    removeValue.map((rVaule) => {
      if (rVaule === value) {
        isRemove = false;
      }
    });
    return isRemove;
  });

  return newArr;
};

/**
 * 移除非选项的值
 * @param {array} arr   判断的数组
 * @returns {array}     移除值之后的数组
 */
export const removeHpvNotDefaultValue = (arr: string[]) => {
  const defaultArr = [
    'Negative',
    'Positive for Low Risk HPV',
    'Not Sure',
    // 'Positive',
  ];

  let removeValue: any[] = [];
  // 筛选出不符合的值
  arr.map((val) => {
    if (defaultArr.indexOf(val) < 0) {
      removeValue.push(val);
    }
  });

  // 移除不符合的值
  const newArr = arr.filter(function (value) {
    let isRemove = true;
    removeValue.map((rVaule) => {
      if (rVaule === value) {
        isRemove = false;
      }
    });
    return isRemove;
  });

  return newArr;
};

/**
 * 获取答案的id列表
 */
export const getAnswerArr = (
  papSelectStatus: keyof typeof PapSelectType | null,
  HPVSelectStatus: keyof typeof HPVSelectType | null,
  pickDropSelectStatus: keyof typeof PickDropSelectType | null,
  smearSelectStatus: keyof typeof SmearSelectType | null,
  gender: keyof typeof GenderType | null,
) => {
  if (gender === GenderType[GenderType['Male' as keyof typeof GenderType]]) {
    if (!(HPVSelectStatus && pickDropSelectStatus)) {
      return [];
    }
  } else {
    if (!(gender && papSelectStatus && HPVSelectStatus && pickDropSelectStatus)) {
      return [];
    }
  }

  let idList = [];

  // NGO这一项不一定要有
  idList.push(DISAGREE_FIRST_POSITION - 3);
  // 在哪里了解到Indicate检测,这一项必填
  idList.push(DISAGREE_FIRST_POSITION - 5);
  // 为什么要购买这个产品,这一项必填
  idList.push(DISAGREE_FIRST_POSITION - 6);

  // 添加三个勾选的选项
  for (let j = DISAGREE_FIRST_POSITION; j <= DISAGREE_FIRST_POSITION + 2; j++) {
    idList.push(j);
  }

  // 前面8个问题肯定是有的
  for (let i = 0; i <= 8; i++) {
    idList.push(i);
  }

  // 如果是gender，则不需要选择11-14
  if (gender !== GenderType[GenderType['Male' as keyof typeof GenderType]]) {
    idList.push(11);

    if (papSelectStatus === PapSelectType[PapSelectType.Yes]) {
      idList.push(12);
      idList.push(13);

      if (smearSelectStatus === SmearSelectType[SmearSelectType.Positive]) {
        idList.push(14);
      }
    }
  }

  // 问题15肯定是有的
  idList.push(15);

  if (HPVSelectStatus === HPVSelectType[HPVSelectType.Yes]) {
    idList.push(16);
  }

  // sign选项肯定是有的
  idList.push(18);
  // Sample Bottle Pick Up / Drop Off 肯定有
  idList.push(19);
  if (pickDropSelectStatus === PickDropSelectType[PickDropSelectType.PickUp]) {
    // pick up是填写这两个
    idList.push(20);
    idList.push(21);
    idList.push(23);
  } else if (pickDropSelectStatus === PickDropSelectType[PickDropSelectType.DropOff]) {
    // drop off是填写这一个
    idList.push(22);

    // 添加dropOffDate的选项
    idList.push(DISAGREE_FIRST_POSITION - 1);
  }

  // 这个代表着提交的日期和时间
  idList.push(24);
  idList.push(25);

  idList.push(28);
  idList.push(29);
  // 肯定有提交的日期
  idList.push(COMMIT_TIME_POSITION);

  return idList;
};

/**
 * 获取CollectionBootle初始值
 * @param {any} allValue      需要操作的对象
 * @returns {defaultValue}    ollectionBootle初始值
 */
export const getDefaultCollectionBootleDate = (allValue: any) => {
  const selectPapYesValue =
    allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType];

  const selectHPVYesValue =
    allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType].questionFifteen
      ?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType];

  const selectPickYesValue =
    allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType].questionFifteen
      ?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType].questionNineteen?.select?.[
      PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType
    ];

  // TODO: 这里的question可能需要调整
  let defaultValue = {
    [DISAGREE_FIRST_POSITION - 6]: {
      id: [DISAGREE_FIRST_POSITION - 6],
      question: allValue?.labNewQuestionMinus9?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION - 5]: {
      id: [DISAGREE_FIRST_POSITION - 5],
      question: allValue?.labNewQuestionMinus8?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION - 3]: {
      id: [DISAGREE_FIRST_POSITION - 3],
      question: allValue?.labNewQuestionMinus6?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION - 2]: {
      id: [DISAGREE_FIRST_POSITION - 2],
      question: allValue?.labNewSubmissionScanTimeQuestion?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION - 1]: {
      id: [DISAGREE_FIRST_POSITION - 1],
      question: allValue?.labNewQuestionMinus4?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION]: {
      id: [DISAGREE_FIRST_POSITION],
      question: allValue?.labNewQuestionMinus3?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION + 1]: {
      id: [DISAGREE_FIRST_POSITION + 1],
      question: allValue?.labNewQuestionMinus2?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION + 2]: {
      id: [DISAGREE_FIRST_POSITION + 2],
      question: allValue?.labNewQuestionMinus1?.questionDesc?.EN,
      answer: '',
    },
    0: {
      id: 0,
      question: allValue?.labNewQuestionZero?.questionDesc?.EN,
      answer: '',
    },
    1: {
      id: 1,
      question: allValue?.questionOne?.questionDesc?.EN,
      answer: '',
    },
    2: {
      id: 2,
      question: allValue?.questionTwo?.questionDesc?.EN,
      answer: '',
    },
    3: {
      id: 3,
      question: allValue?.questionThree?.questionDesc?.EN,
      answer: '',
    },
    4: {
      id: 4,
      question: allValue?.questionFour?.questionDesc?.EN,
      answer: '',
    },
    5: {
      id: 5,
      question: allValue?.questionFive?.questionDesc?.EN,
      answer: '',
    },
    6: {
      id: 6,
      question: allValue?.questionSix?.questionDesc?.EN,
      answer: '',
    },
    7: {
      id: 7,
      question: allValue?.questionSeven?.questionDesc?.EN,
      answer: '',
    },
    8: {
      id: 8,
      question: allValue?.questionEight?.questionDesc?.EN,
      answer: '',
    },
    // TODO 有可能需要隐藏
    9: {
      id: 9,
      question: allValue?.questionNine?.questionDesc?.EN,
      answer: '',
    },
    10: {
      id: 10,
      question: allValue?.questionTen?.questionDesc?.EN,
      answer: '',
    },
    11: {
      id: 11,
      question: allValue?.questionEleven?.questionDesc?.EN,
      answer: '',
    },
    12: {
      id: 12,
      question: selectPapYesValue?.questionTwelve?.questionDesc?.EN,
      answer: '',
    },
    13: {
      id: 13,
      question: allValue?.labNewQuestionThirteen?.questionDesc?.EN,
      answer: '',
    },
    14: {
      id: 14,
      question: selectPapYesValue?.questionThirteen?.questionDesc?.EN,
      answer: '',
    },
    15: {
      id: 15,
      question: selectPapYesValue?.questionFifteen?.questionDesc?.EN,
      answer: '',
    },
    16: {
      id: 16,
      question: selectHPVYesValue?.questionSixteen?.questionDesc?.EN,
      answer: '',
    },
    17: {
      id: 17,
      question: selectHPVYesValue?.questionSeventeen?.questionDesc?.EN,
      answer: '',
    },
    18: {
      id: 18,
      question: selectHPVYesValue?.questionEighteen?.questionDesc?.EN,
      answer: '',
    },
    19: {
      id: 19,
      question: selectHPVYesValue?.questionNineteen?.questionDesc?.EN,
      answer: '',
    },
    20: {
      id: 20,
      question: allValue?.labNewQuestionTwenty?.questionDesc?.EN,
      answer: '',
    },
    21: {
      id: 21,
      question: selectPickYesValue?.questionTwentyOne?.questionDesc?.EN,
      answer: '',
    },
    22: {
      id: 22,
      question: selectPickYesValue?.questionTwentyTwo?.questionDesc?.EN,
      answer: '',
    },
    // TODO 需要调整顺序
    23: {
      id: 23,
      question: selectPickYesValue?.questionTwenty?.questionDesc?.EN,
      answer: '',
    },
    24: {
      id: 24,
      question: allValue?.questionNine?.questionDesc?.EN,
      answer: '',
    },
    25: {
      id: 25,
      question: allValue?.questionTen?.questionDesc?.EN,
      answer: '',
    },
    28: {
      id: 28,
      question: 'Have you received HPV vaccination?',
      answer: '',
    },
    29: {
      id: 29,
      question: 'If yes, is it 2-valent, 4-valent or 9-valent?',
      answer: '',
    },

    [COMMIT_TIME_POSITION]: {
      id: COMMIT_TIME_POSITION,
      question: allValue?.labNewQuestionTwentyFive?.questionDesc?.EN,
      answer: '',
    },
    [COMMIT_TIME_POSITION + 1]: {
      id: COMMIT_TIME_POSITION + 1,
      question: allValue?.labNewSubmissionAddressQuestion?.questionDesc?.EN,
      answer: '',
    },
  };

  return defaultValue;
};

/**
 * 格式化CollectionBootle数据
 * @param {string} str  需要操作的字符串
 * @returns {string}    转换之后的字符串
 */

interface FormatCb {
  [key: number | string]: any;
}

// TODO 需要格式化数据=>这个需要再看一下=>返回符合后端的数据格式
export const formatCollectionBootleDate = (
  papSelectStatus: keyof typeof PapSelectType | null,
  HPVSelectStatus: keyof typeof HPVSelectType | null,
  pickDropSelectStatus: keyof typeof PickDropSelectType | null,
  smearSelectStatus: keyof typeof SmearSelectType | null,
  gender: keyof typeof GenderType | null,
  allValue: any,
  formValue: any,
) => {
  let defaultValue = getDefaultCollectionBootleDate(allValue);

  const idList = getAnswerArr(papSelectStatus, HPVSelectStatus, pickDropSelectStatus, smearSelectStatus, gender);

  let formatCbData: FormatCb = {};

  idList.map((id) => {
    // formatCbData;
    // 只选取属于id列表的值
    defaultValue[id as keyof typeof defaultValue].answer = formValue[id as keyof typeof defaultValue];

    formatCbData[id as keyof typeof defaultValue] = defaultValue[id as keyof typeof defaultValue];
  });

  return formatCbData;
};

/**
 * 格式化excel数据
 * @param {any} beValue  需要操作的对象
 * @param {TFunction<"translation", undefined, "translation">} t   translation函数
 * @returns {string}     转换之后的对象
 */
export const formatExcelDate = (
  beValue: any,
  t: TFunction<'translation', undefined, 'translation'>,
  newDoctorMessage: any,
  doctorTitle1: string,
  doctorTitle2: string,
  doctorTitle3: string,
  hpv12p2Title: string,
  hpv12UpgradedInfo: {
    [key: string]: string;
  },
) => {
  if (!Array.isArray(beValue)) {
    return null;
  }
  const defaultValue = getDefaultCollectionBootleDate(getAllValue(t));

  const limitIdArr = [9, 10];

  let csvArr = [];
  let title = [];
  for (let i = 0; i <= COMMIT_TIME_POSITION + 1; i++) {
    if (limitIdArr.includes(i)) {
      continue;
    }

    const question = defaultValue[i as keyof typeof defaultValue].question;
    title.push(question);

    if (i === 0) {
      // 在第一个选项后面,添加三个勾选的选项
      for (let j = DISAGREE_FIRST_POSITION; j <= DISAGREE_FIRST_POSITION + 2; j++) {
        // 移除d6选项
        if (j === DISAGREE_FIRST_POSITION + 2) {
          continue;
        }

        const tickQuestion = defaultValue[j as keyof typeof defaultValue].question;
        title.push(tickQuestion);
      }
    }

    // 在23位置之后，添加这个值
    if (i === DROP_OFF_DATE_POSITION) {
      const dodPosition = DISAGREE_FIRST_POSITION - 1;
      const dropOffDateQuestion = defaultValue[dodPosition as keyof typeof defaultValue].question;
      title.push(dropOffDateQuestion);
    }

    // 添加scanTime的值
    if (i === COMMIT_TIME_POSITION + 1) {
      // DISAGREE_FIRST_POSITION - 2
      const scanTimeTitle = defaultValue[(DISAGREE_FIRST_POSITION - 2) as keyof typeof defaultValue].question;
      title.push(scanTimeTitle);
    }

    // 在scanTime的后面，添加NGO
    if (i === COMMIT_TIME_POSITION + 1) {
      const NGOTitle = defaultValue[(DISAGREE_FIRST_POSITION - 3) as keyof typeof defaultValue].question;
      title.push(NGOTitle);
    }

    // 在Price的后面，添加
    // ①在哪里了解到Indicate检测
    // ②为什么要购买这个产品
    if (i === COMMIT_TIME_POSITION + 1) {
      const whereTitle = defaultValue[(DISAGREE_FIRST_POSITION - 5) as keyof typeof defaultValue].question;
      const whyTitle = defaultValue[(DISAGREE_FIRST_POSITION - 6) as keyof typeof defaultValue].question;
      title.push(whereTitle);
      title.push(whyTitle);
    }
  }
  title.push(doctorTitle1);
  title.push(doctorTitle2);
  title.push(doctorTitle3);
  title.push(hpv12p2Title);
  csvArr.push(title);

  const filterBeValue = beValue.filter((fValue) => fValue.type !== 'china');

  // 把数组倒序
  filterBeValue.reverse();

  const reverseNewDoctorMessage = newDoctorMessage.reverse();

  filterBeValue.map((obj: any, index) => {
    let newArr = [];
    for (let i = 0; i <= COMMIT_TIME_POSITION + 1; i++) {
      if (limitIdArr.includes(i)) {
        continue;
      }
      // 代表存在这个值
      if (obj[i as keyof typeof defaultValue]) {
        // 需要把数组转为字符串
        if (Array.isArray(obj[i as keyof typeof defaultValue].answer)) {
          const newStr =
            Array.isArray(obj[i as keyof typeof defaultValue].answer) &&
            obj[i as keyof typeof defaultValue].answer.join(',');
          newArr.push(newStr);
        } else {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[i as keyof typeof defaultValue].answer);
        }
      } else {
        // 没值则传入空
        newArr.push('');
      }

      // 在第一个选项后面,添加三个勾选的选项
      if (i === 0) {
        // 在第一个选项后面,添加三个勾选的选项
        for (let j = DISAGREE_FIRST_POSITION; j <= DISAGREE_FIRST_POSITION + 2; j++) {
          // 移除d6选项
          if (j === DISAGREE_FIRST_POSITION + 2) {
            continue;
          }

          // 代表存在这个值
          if (obj[j as keyof typeof defaultValue]) {
            // TODO 可能是字符串的问题，需要再看一下
            newArr.push(obj[j as keyof typeof defaultValue].answer);
          } else {
            // 没值则传入空
            newArr.push('');
          }
        }
      }

      // 在23位置之后，添加这个值
      if (i === DROP_OFF_DATE_POSITION) {
        const dodPosition = DISAGREE_FIRST_POSITION - 1;
        // 代表存在这个值
        if (obj[dodPosition as keyof typeof defaultValue]) {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[dodPosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }

      // 添加scanTime的值
      if (i === COMMIT_TIME_POSITION + 1) {
        const scanTimePosition = DISAGREE_FIRST_POSITION - 2;
        // 代表存在这个值
        if (obj[scanTimePosition as keyof typeof defaultValue]) {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[scanTimePosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }

      // 在scanTime的后面，添加NGO
      if (i === COMMIT_TIME_POSITION + 1) {
        const NGOPosition = DISAGREE_FIRST_POSITION - 3;
        // 代表存在这个值
        if (obj[NGOPosition as keyof typeof defaultValue]) {
          newArr.push(obj[NGOPosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }

      // 在Price的后面，添加
      // ①在哪里了解到Indicate检测
      // ②为什么要购买这个产品
      if (i === COMMIT_TIME_POSITION + 1) {
        const wherePosition = DISAGREE_FIRST_POSITION - 5;
        // 代表存在这个值
        if (obj[wherePosition as keyof typeof defaultValue]) {
          newArr.push(obj[wherePosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
        const whyPosition = DISAGREE_FIRST_POSITION - 6;
        // 代表存在这个值
        if (obj[whyPosition as keyof typeof defaultValue]) {
          if (Array.isArray(obj[whyPosition as keyof typeof defaultValue].answer)) {
            const newStr =
              Array.isArray(obj[whyPosition as keyof typeof defaultValue].answer) &&
              obj[whyPosition as keyof typeof defaultValue].answer.join(',');
            newArr.push(newStr);
          } else {
            newArr.push(obj[whyPosition as keyof typeof defaultValue].answer);
          }
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }
    }

    newArr.push(reverseNewDoctorMessage[index]?.doctorName);
    newArr.push([0, '0'].includes(reverseNewDoctorMessage[index]?.doctorAgree) ? 'Unconfirmed' : 'confirm');
    newArr.push(
      reverseNewDoctorMessage[index]?.doctorAgreeTime
        ? dayjs(reverseNewDoctorMessage[index]?.doctorAgreeTime).format('YYYY/MM/DD hh:mma')
        : '',
    );
    const sampleId = obj[0]?.answer.startsWith("'") ? obj[0]?.answer.slice(1) : obj[0]?.answer;
    const isHpv12p2 = hpv12UpgradedInfo[sampleId];
    newArr.push(isHpv12p2);
    csvArr.push(newArr);
  });
  return csvArr;
};

// TODO: 这里需要进行调整

/**
 * 获取CollectionBootle初始值
 * @param {any} allValue      需要操作的对象
 * @returns {defaultValue}    ollectionBootle初始值
 */
export const getLogisticDefaultCollectionBootleDate = (allValue: any) => {
  const selectPapYesValue =
    allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType];

  const selectHPVYesValue =
    allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType].questionFifteen
      ?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType];

  const selectPickYesValue =
    allValue?.questionEleven?.select?.[PapSelectType[PapSelectType.Yes] as keyof typeof PapSelectType].questionFifteen
      ?.select?.[HPVSelectType[HPVSelectType.Yes] as keyof typeof HPVSelectType].questionNineteen?.select?.[
      PickDropSelectType[PickDropSelectType.PickUp] as keyof typeof PickDropSelectType
    ];

  let defaultValue = {
    [DISAGREE_FIRST_POSITION - 3]: {
      id: [DISAGREE_FIRST_POSITION - 3],
      question: allValue?.labNewQuestionMinus6?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION - 2]: {
      id: [DISAGREE_FIRST_POSITION - 2],
      question: allValue?.labNewSubmissionScanTimeQuestion?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION - 1]: {
      id: [DISAGREE_FIRST_POSITION - 1],
      question: allValue?.labNewQuestionMinus4?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION]: {
      id: [DISAGREE_FIRST_POSITION],
      question: allValue?.labNewQuestionMinus3?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION + 1]: {
      id: [DISAGREE_FIRST_POSITION + 1],
      question: allValue?.labNewQuestionMinus2?.questionDesc?.EN,
      answer: '',
    },
    [DISAGREE_FIRST_POSITION + 2]: {
      id: [DISAGREE_FIRST_POSITION + 2],
      question: allValue?.labNewQuestionMinus1?.questionDesc?.EN,
      answer: '',
    },
    0: {
      id: 0,
      question: allValue?.labNewQuestionZero?.questionDesc?.EN,
      answer: '',
    },
    1: {
      id: 1,
      question: allValue?.questionOne?.questionDesc?.EN,
      answer: '',
    },
    2: {
      id: 2,
      question: allValue?.questionTwo?.questionDesc?.EN,
      answer: '',
    },
    3: {
      id: 3,
      question: allValue?.questionThree?.questionDesc?.EN,
      answer: '',
    },
    4: {
      id: 4,
      question: allValue?.questionFour?.questionDesc?.EN,
      answer: '',
    },
    5: {
      id: 5,
      question: allValue?.questionFive?.questionDesc?.EN,
      answer: '',
    },
    6: {
      id: 6,
      question: allValue?.questionSix?.questionDesc?.EN,
      answer: '',
    },
    7: {
      id: 7,
      question: allValue?.questionSeven?.questionDesc?.EN,
      answer: '',
    },
    8: {
      id: 8,
      question: allValue?.questionEight?.questionDesc?.EN,
      answer: '',
    },
    // TODO 有可能需要隐藏
    9: {
      id: 9,
      question: allValue?.questionNine?.questionDesc?.EN,
      answer: '',
    },
    10: {
      id: 10,
      question: allValue?.questionTen?.questionDesc?.EN,
      answer: '',
    },
    11: {
      id: 11,
      question: allValue?.questionEleven?.questionDesc?.EN,
      answer: '',
    },
    12: {
      id: 12,
      question: selectPapYesValue?.questionTwelve?.questionDesc?.EN,
      answer: '',
    },
    13: {
      id: 13,
      question: allValue?.labNewQuestionThirteen?.questionDesc?.EN,
      answer: '',
    },
    14: {
      id: 14,
      question: selectPapYesValue?.questionThirteen?.questionDesc?.EN,
      answer: '',
    },
    15: {
      id: 15,
      question: selectPapYesValue?.questionFifteen?.questionDesc?.EN,
      answer: '',
    },
    16: {
      id: 16,
      question: selectHPVYesValue?.questionSixteen?.questionDesc?.EN,
      answer: '',
    },
    17: {
      id: 17,
      question: selectHPVYesValue?.questionSeventeen?.questionDesc?.EN,
      answer: '',
    },
    18: {
      id: 18,
      question: selectHPVYesValue?.questionEighteen?.questionDesc?.EN,
      answer: '',
    },
    19: {
      id: 19,
      question: selectHPVYesValue?.questionNineteen?.questionDesc?.EN,
      answer: '',
    },
    20: {
      id: 20,
      question: allValue?.labNewQuestionTwenty?.questionDesc?.EN,
      answer: '',
    },
    21: {
      id: 21,
      question: selectPickYesValue?.questionTwentyOne?.questionDesc?.EN,
      answer: '',
    },
    22: {
      id: 22,
      question: selectPickYesValue?.questionTwentyTwo?.questionDesc?.EN,
      answer: '',
    },
    // TODO 需要调整顺序
    23: {
      id: 23,
      question: selectPickYesValue?.questionTwenty?.questionDesc?.EN,
      answer: '',
    },
    24: {
      id: 24,
      question: allValue?.questionNine?.questionDesc?.EN,
      answer: '',
    },
    25: {
      id: 25,
      question: allValue?.questionTen?.questionDesc?.EN,
      answer: '',
    },
    [COMMIT_TIME_POSITION]: {
      id: COMMIT_TIME_POSITION,
      question: allValue?.labNewQuestionTwentyFive?.questionDesc?.EN,
      answer: '',
    },
    [COMMIT_TIME_POSITION + 1]: {
      id: COMMIT_TIME_POSITION + 1,
      question: allValue?.labNewSubmissionAddressQuestion?.questionDesc?.EN,
      answer: '',
    },
  };

  return defaultValue;
};

/**
 * 格式化excel数据
 * @param {any} beValue  需要操作的对象
 * @param {TFunction<"translation", undefined, "translation">} t   translation函数
 * @returns {string}     转换之后的对象
 */
export const formatLogisticExcelDate = (beValue: any, t: TFunction<'translation', undefined, 'translation'>) => {
  // TODO: 后面需要添加27
  const passIdArr = [0, 1, 2, 3, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];

  if (!Array.isArray(beValue)) {
    return null;
  }
  const defaultValue = getLogisticDefaultCollectionBootleDate(getAllValue(t));
  let csvArr = [];
  let title = [];
  // TODO: 后续这里需要调整，需要加1
  for (let i = 0; i <= COMMIT_TIME_POSITION + 1; i++) {
    // 不属于id列表里面，则不需要加入其中
    if (!passIdArr.includes(i)) {
      continue;
    }

    const question = defaultValue[i as keyof typeof defaultValue].question;
    title.push(question);

    // 在23位置之后，添加这个值
    if (i === DROP_OFF_DATE_POSITION) {
      const dodPosition = DISAGREE_FIRST_POSITION - 1;
      const dropOffDateQuestion = defaultValue[dodPosition as keyof typeof defaultValue].question;
      title.push(dropOffDateQuestion);
    }

    // 添加scanTime的值
    if (i === COMMIT_TIME_POSITION + 1) {
      // DISAGREE_FIRST_POSITION - 2
      const scanTimeTitle = defaultValue[(DISAGREE_FIRST_POSITION - 2) as keyof typeof defaultValue].question;
      title.push(scanTimeTitle);
    }

    // 在scanTime的后面，添加NGO
    if (i === COMMIT_TIME_POSITION + 1) {
      const NGOTitle = defaultValue[(DISAGREE_FIRST_POSITION - 3) as keyof typeof defaultValue].question;
      title.push(NGOTitle);
    }
  }
  csvArr.push(title);

  const filterBeValue = beValue.filter((fValue) => fValue.type !== 'china');

  // 把数组倒序
  filterBeValue.reverse();

  filterBeValue.map((obj: any) => {
    let newArr = [];
    for (let i = 0; i <= COMMIT_TIME_POSITION + 1; i++) {
      // 不属于id列表里面，则不需要加入其中
      if (!passIdArr.includes(i)) {
        continue;
      }

      // 代表存在这个值
      if (obj[i as keyof typeof defaultValue]) {
        // 需要把数组转为字符串
        if (Array.isArray(obj[i as keyof typeof defaultValue].answer)) {
          const newStr =
            Array.isArray(obj[i as keyof typeof defaultValue].answer) &&
            obj[i as keyof typeof defaultValue].answer.join(',');
          newArr.push(newStr);
        } else {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[i as keyof typeof defaultValue].answer);
        }
      } else {
        // 没值则传入空
        newArr.push('');
      }

      // 在23位置之后，添加这个值
      if (i === DROP_OFF_DATE_POSITION) {
        const dodPosition = DISAGREE_FIRST_POSITION - 1;
        // 代表存在这个值
        if (obj[dodPosition as keyof typeof defaultValue]) {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[dodPosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }

      // 添加scanTime的值
      if (i === COMMIT_TIME_POSITION + 1) {
        const scanTimePosition = DISAGREE_FIRST_POSITION - 2;
        // 代表存在这个值
        if (obj[scanTimePosition as keyof typeof defaultValue]) {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[scanTimePosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }

      // 在scanTime的后面，添加NGO
      if (i === COMMIT_TIME_POSITION + 1) {
        const NGOPosition = DISAGREE_FIRST_POSITION - 3;
        // 代表存在这个值
        if (obj[NGOPosition as keyof typeof defaultValue]) {
          newArr.push(obj[NGOPosition as keyof typeof defaultValue].answer);
        } else {
          // 没值则传入空
          newArr.push('');
        }
      }
    }
    csvArr.push(newArr);
  });
  return csvArr;
};

/**
 * 格式化excel数据
 * @param {any} beValue  需要操作的对象
 * @param {TFunction<"translation", undefined, "translation">} t   translation函数
 * @returns {string}     转换之后的对象
 */
export const formatDoctorExcelDate = (beValue: any, t: TFunction<'translation', undefined, 'translation'>) => {
  // TODO: 后面需要添加27
  const passIdArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16, 17, 18, 24, 25, 26];

  if (!Array.isArray(beValue)) {
    return null;
  }
  const defaultValue = getDefaultCollectionBootleDate(getAllValue(t));
  let csvArr = [];
  let title = [];
  // TODO: 后续这里需要调整，需要加1
  for (let i = 0; i <= COMMIT_TIME_POSITION + 1; i++) {
    // 不属于id列表里面，则不需要加入其中
    if (!passIdArr.includes(i)) {
      continue;
    }

    const question = defaultValue[i as keyof typeof defaultValue].question;
    title.push(question);
  }
  csvArr.push(title);

  const filterBeValue = beValue.filter((fValue) => fValue.type !== 'china');

  // 把数组倒序
  filterBeValue.reverse();

  filterBeValue.map((obj: any) => {
    let newArr = [];
    for (let i = 0; i <= COMMIT_TIME_POSITION + 1; i++) {
      // 不属于id列表里面，则不需要加入其中
      if (!passIdArr.includes(i)) {
        continue;
      }

      // 代表存在这个值
      if (obj[i as keyof typeof defaultValue]) {
        // 需要把数组转为字符串
        if (Array.isArray(obj[i as keyof typeof defaultValue].answer)) {
          const newStr =
            Array.isArray(obj[i as keyof typeof defaultValue].answer) &&
            obj[i as keyof typeof defaultValue].answer.join(',');
          newArr.push(newStr);
        } else {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[i as keyof typeof defaultValue].answer);
        }
      } else {
        // 没值则传入空
        newArr.push('');
      }
    }
    csvArr.push(newArr);
  });
  return csvArr;
};

/**
 * 获取星期几，其中星期日为0、星期六为6
 * @returns {string}    代表着星期几
 */
export const getCurrentWeekDay = () => {
  const weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return weeks[dayjs().day()];
};

/**
 * 过滤不符合需求的时间
 * @param {any} csvArr  过滤前的csv数据
 * @returns {any}  filterCsvArr   过滤后的csv数据
 */
// 具体的需求：每日( monday - friday) 下午兩點cut off , 以提交時間為準，需要下一个工作日才能看到，周五下午两点後-禮拜天提交的，需要在周一才能看到
// ①展示当天两点之前的所有数据【周一到周五】
// ②如果是周六或者周日,则展示周五两点之前的所有数据
export const filterLogistic = (csvArr: any) => {
  const curDay = getCurrentWeekDay();
  // 设置时间为下午两点
  const twoPM = dayjs().hour(14).minute(0).second(0).unix();
  if (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(curDay)) {
    // 代表当前为工作日
    const filterArr = csvArr.filter((arr: any, index: number) => {
      return index === 0 || dayjs(arr[Logistic_COMMIT_TIME_POSITION - 1], 'YYYY/MM/DD hh:mma').unix() < twoPM;
    });
    return filterArr;
  } else {
    // 代表当前为周六或者周日
    let limitFridayTwoPM: any;

    if (curDay === 'Saturday') {
      limitFridayTwoPM = dayjs().subtract(1, 'day').hour(14).minute(0).second(0).unix();
    } else if (curDay === 'Sunday') {
      limitFridayTwoPM = dayjs().subtract(2, 'day').hour(14).minute(0).second(0).unix();
    } else {
      // 兼容特殊情况
      limitFridayTwoPM = dayjs().hour(14).minute(0).second(0).unix();
    }

    const weekendFilterArr = csvArr.filter((arr: any, index: number) => {
      return (
        index === 0 || dayjs(arr[Logistic_COMMIT_TIME_POSITION - 1], 'YYYY/MM/DD hh:mma').unix() < limitFridayTwoPM
      );
    });
    return weekendFilterArr;
  }

  // 如果出现错误，则展示原来的csvArr
  return csvArr;
};

// 是否为简体中文
// export const isSimplifiedChinese = (val: string | null) => {
//   if (!val) {
//     return false;
//   }

//   const convertedStr = converter.convertSync(val);
//   return convertedStr !== val; // 如果转换后的字符串和原字符串不同，则说明是简体中文
// };

// // 是否为繁体中文
// export const isTraditionalChinese = (val: string | null) => {
//   if (!val) {
//     return false;
//   }

//   const convertedStr = converter.convertSync(val);
//   return convertedStr === str; // 如果转换后的字符串和原字符串相同，则说明是繁体中文
// };

/**
 * 校验val是否为LastName
 * @param {string | null} val 需要校验的邮箱
 * @returns {boolean} 是否为邮箱
 */
export const isLastName = (val: string | null) => {
  if (!val) {
    return false;
  }
  // 只允许是英文
  const reg = /^[a-zA-Z\s]+$/g;
  const isEnName = reg.test(val);

  return isEnName;
};

export const isValidHKID = (hkid: string | null) => {
  if (!hkid) {
    return false;
  }
  // 去除左右括号
  let newHKID = hkid.replace('(', '');
  newHKID = newHKID.replace(')', '');
  // Regular expression to match the format of a Hong Kong identity card
  const hkidRegex = /^[A-Z]{1,2}[0-9]{6,7}[A0-9]$/;

  // Check if the input matches the regular expression and has a valid length
  if (hkidRegex.test(newHKID) && [8, 9, 10].includes(newHKID.length)) {
    return true;

    // Check the check digit of the HKID
    // const checkDigit = hkid.charAt(7);
    // let sum = 0;

    // // Calculate the sum of the first 7 digits of the HKID
    // for (let i = 0; i < 7; i++) {
    //   const digit = parseInt(hkid.charAt(i));
    //   console.log(digit, 'digit');
    //   if (i === 0) {
    //     sum += (digit - 10) * 9 + 36;
    //   } else if (i === 1) {
    //     sum += (digit - 10) * 8 + 36;
    //   } else {
    //     sum += digit * (7 - i);
    //   }
    // }

    // console.log(sum, 'sum');
    // // Calculate the expected check digit based on the sum
    // const expectedCheckDigit = (11 - (sum % 11)) % 11;
    // const actualCheckDigit = checkDigit === 'A' ? 10 : (Object.prototype.toString.call(checkDigit) === '[object String]' ? parseInt(checkDigit) : checkDigit);

    // console.log(checkDigit, 'checkDigit');
    // console.log(expectedCheckDigit, 'expectedCheckDigit');
    // console.log(actualCheckDigit, 'actualCheckDigit');

    // Return true if the check digit is valid
    // return expectedCheckDigit === actualCheckDigit;
  }

  // Return false if the input does not match the format of a Hong Kong identity card
  return false;
};

export const isValidCNID = (idCard: string | null) => {
  if (!idCard) {
    return false;
  }

  // 18位身份证号码的正则表达式
  const reg = /^[1-9]\d{5}(19|20)\d{2}(0\d|1[0-2])([0-2]\d|3[0-1])\d{3}([0-9]|X)$/;

  if (!reg.test(idCard)) {
    return false;
  }

  // 验证校验位
  const checkCode = idCard.charAt(17);
  let checkNum = 0;
  const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const verifyCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

  for (let i = 0; i < 17; i++) {
    checkNum += parseInt(idCard.charAt(i), 10) * factor[i];
  }

  return verifyCode[checkNum % 11] === checkCode;
};

// export const IsHKID = (str: string | null) => {
//   if (!str) {
//     return;
//   }
//   let strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//   if (str.length < 8) {
//     return false;
//   }
//   str = str.toUpperCase();
//   let hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
//   let matchArray = str.match(hkidPat);
//   if (matchArray == null) {
//     return false;
//   }
//   let charPart = matchArray[1];
//   let numPart = matchArray[2];
//   let checkDigit = matchArray[3];
//   let checkSum = 0;
//   if (charPart.length == 2) {
//     checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
//     checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
//   } else {
//     checkSum += 9 * 36;
//     checkSum += 8 * (10 + strValidChars.indexOf(charPart));
//   }

//   for (let i = 0, j = 7; i < numPart.length; i++, j--) {
//     checkSum += j * numPart.charAt(i);
//   }
//   let remaining = checkSum % 11;
//   let verify = remaining == 0 ? 0 : 11 - remaining;
//   return verify == checkDigit || (verify == 10 && checkDigit == 'A');
// };

/**
 * 修改当前的语言
 * @param {string} origin   起始地址
 * @param {string} target   想要转换的地址
 * @param {string} targetUrl   想要转换的地址
 * @returns
 */
export const switchLanguage = (origin: string, target: string, targetUrl?: string) => {
  if (target == origin) {
    return;
  }
  let newPath = (targetUrl || location.href).replace(location.origin, '');
  if (newPath.startsWith('/' + origin)) {
    newPath = newPath.replace(origin, target);
  } else {
    newPath = '/' + target + newPath;
  }
  location.href = newPath;
};

/**
 * 是否为CN端
 * @returns
 */
export function isCNPortal2() {
  return window.location.href.includes(`${location.origin}/cn/`);
}
// 强制英文的页面
export function isForceEnglishPage() {
  return window.location.href.includes(`${location.origin}/exportDoctorExcel`);
}

/**
 * 校验val是否为LastName
 * @param {string | null} val 需要校验的邮箱
 * @returns {boolean} 是否为邮箱
 */
export const isChinaName = (val: string | null) => {
  if (!val) {
    return false;
  }
  // 匹配2-4个中文字符
  const reg = /^[\u4e00-\u9fa5]{2,4}$/;
  return reg.test(val);
};

/**
 * 获取CollectionBootle初始值
 * @param {any} allValue      需要操作的对象
 * @returns {defaultValue}    ollectionBootle初始值
 */
export const getCNDefaultCollectionBootleDate = (allValue: any) => {
  let defaultValue = {
    0: {
      id: 0,
      question: allValue[1],
      answer: '',
    },
    1: {
      id: 1,
      question: allValue[3],
      answer: '',
    },
    2: {
      id: 2,
      question: allValue[4],
      answer: '',
    },
    3: {
      id: 3,
      question: allValue[5],
      answer: '',
    },
    4: {
      id: 4,
      question: allValue[2],
      answer: '',
    },
  };

  return defaultValue;
};

export const formatCNCollectionBootleDate = (allValue: any, formValue: any) => {
  let defaultValue = getCNDefaultCollectionBootleDate(allValue);

  const idList = [0, 1, 2, 3, 4];

  let formatCbData: FormatCb = {};

  idList.map((id) => {
    // formatCbData;
    // 只选取属于id列表的值
    defaultValue[id as keyof typeof defaultValue].answer = formValue[id as keyof typeof defaultValue];

    formatCbData[id as keyof typeof defaultValue] = defaultValue[id as keyof typeof defaultValue];
  });

  return formatCbData;
};

/**
 * 格式化excel数据
 * @param {any} beValue  需要操作的对象
 * @returns {string}     转换之后的对象
 */
export const formatCNExcelDate = (beValue: any) => {
  if (!Array.isArray(beValue)) {
    return null;
  }
  const defaultValue = getCNDefaultCollectionBootleDate(getCNAllValue());
  let csvArr = [];
  let title = [];
  for (let i = 0; i <= 4; i++) {
    const question = defaultValue[i as keyof typeof defaultValue].question;
    title.push(question);
  }
  csvArr.push(title);

  const filterBeValue = beValue.filter((fValue) => fValue.type === 'china');

  filterBeValue.map((obj: any) => {
    let newArr = [];
    for (let i = 0; i <= 4; i++) {
      // 代表存在这个值
      if (obj[i as keyof typeof defaultValue]) {
        // 需要把数组转为字符串
        if (Array.isArray(obj[i as keyof typeof defaultValue].answer)) {
          const newStr =
            Array.isArray(obj[i as keyof typeof defaultValue].answer) &&
            obj[i as keyof typeof defaultValue].answer.join(',');
          newArr.push(newStr);
        } else {
          // TODO 可能是字符串的问题，需要再看一下
          newArr.push(obj[i as keyof typeof defaultValue].answer);
        }
      } else {
        // 没值则传入空
        newArr.push('');
      }
    }
    csvArr.push(newArr);
  });
  return csvArr;
};

// 如果低版本安卓，需要替换为datepicker【小于9版本的，默认为低版本】
export const isLowVersionChangeDatePicker = () => {
  // 调试阶段，默认返回true

  if (isAndroid) {
    console.log('当前设备为安卓手机，系统版本号为：', osVersion);
    console.log('parseInt(osVersion)：', parseInt(osVersion));
    if (osVersion && parseInt(osVersion) < 9) {
      return true;
    }
  } else {
    return false;
    // console.log('当前设备不是安卓手机');
  }
};

/**
 * 获取指定时间为星期几，其中星期日为第一个、星期六为最后一个
 * @param {Dayjs} specifyTime  需要操作的对象
 * @returns {number}           代表着星期几
 */
export const getSpecifyTimeWeekDay = (specifyTime: Dayjs) => {
  const weeks = [
    HiddenDayType.Sunday,
    HiddenDayType.Monday,
    HiddenDayType.Tuesday,
    HiddenDayType.Wednesday,
    HiddenDayType.Thursday,
    HiddenDayType.Friday,
    HiddenDayType.Saturday,
  ];
  return weeks[dayjs(specifyTime).day()];
};

/**
 * 把日期字符串转为对应日期,'20250101'
 * @param {Dayjs} specifyTime  需要操作的对象
 * @returns {number}           代表着星期几
 */
export const dateStr2Number = (strdate: string | null) => {
  const formattedDate = dayjs(strdate).format('YYMMDD');
  const dateNumber = parseInt(formattedDate, 10);
  return dateNumber;
};
/**
 * 获得指定时间是否为节假日
 * @param {Dayjs} specifyTime  需要操作的对象
 * @returns {boolean}          是否为节假日
 */

// 中秋節翌日	9月30日	星期六
// 國慶日翌日	10月2日	星期一
// 重陽節	10月23日	星期一
// 聖誕節	12月25日	星期一
// 聖誕節後第一個周日	12月26日	星期二
export const isPublicHoliday = (specifyTime: Dayjs) => {
  const dayjsSpecial = dayjs(specifyTime).format('YYYY-MM-DD');

  const publicHolidays = [
    '2024-02-09', // 春节
    '2024-02-10', // 春节
    '2024-02-12', // 春节
    '2024-02-13', // 春节
    '2024-03-29', // good friday
    '2024-03-30', // The day following Good Friday
    '2024-04-01', // Easter Monday
    '2024-04-04', // Ching Ming Festival
    '2024-05-01', // Labour Day
    '2024-05-15', // The Buddha's Birthday
    '2024-06-10', // Tuen Ng Festival
    '2024-07-01', // Hong Kong Special Administrative Region Establishment Day
    '2024-09-18', // The day following the Chinese Mid-Autumn Festival
    '2024-10-01', // National Day
    '2024-10-11', // Chung Yeung Festival
    '2024-12-25', // Christmas Day
    '2024-12-26', // The first weekday after Christmas Day
    '2025-01-29', // 春节
    '2025-01-30', // 春节
    // '2025-01-31', // 春节
    '2025-04-04', // 清明节
    '2025-04-18', // 耶稣受難節
    '2025-04-19', // 耶稣受難節
    '2025-04-21', // 復活節星期一(復活節翌日)
    '2025-05-01', // Labour Day 劳動節
    '2025-05-05', // 佛誕
    '2025-05-31', // Tuen Ng Festival 端午節
    '2025-07-01', // Hong Kong Special Administrative Region Establishment Day 香港特别行政區成立纪念日
    '2025-10-01', // National Day 國慶日
    '2025-10-07', // 中秋節翌日
    '2025-10-29', // 重陽節
    '2025-12-25', // 聖誕節
    '2025-12-26', // 聖誕節後第一個平日
  ];

  return publicHolidays.includes(dayjsSpecial);
};
